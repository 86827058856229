@import '../../../styles/_modules/variables';

.delete-product {
    & .product-info {
        ul.title {
            list-style: none;
            margin: 0;
            padding: 0;

            &>li {
                display: inline-block;
                margin: 0;
                padding: 0;
                vertical-align: top;

                &.quantity {
                    & h4 {
                        margin-top: 1.2rem;
                        color: $color-primary;

                        & small {
                            display: inline;
                            color: $gray-700;
                            padding: 0 $p-1;
                        }
                    }
                }

                &.detail {
                    & .text-truncate {
                        max-width: 300px;
                    }

                    & .brand {
                        color: $gray-600;
                        font-size: $font-size-sm;
                        margin-bottom: $p-1;
                    }

                    & h4 {
                        color: $gray-800;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.has-quantity {
                        max-width: 300px;
                    }
                }
            }
        }
    }

    & .product-price {
        & h4 {
            & small {
                display: inline;
                font-size: $font-size-sm;
                color: $gray-700;
            }
        }
    }

    & .product-data {
        & ul.leaders {
            max-width: 40em;
            padding: 0;
            overflow-x: hidden;
            list-style: none;
            margin: 0;

            & li {
                padding: 0;
                padding-bottom: $p-2;

                &:after {
                    float: left;
                    width: 0;
                    white-space: nowrap;
                    content:
                        ". . . . . . . . . . . . . . . . . . . . "
                        ". . . . . . . . . . . . . . . . . . . . "
                        ". . . . . . . . . . . . . . . . . . . . "
                        ". . . . . . . . . . . . . . . . . . . . ";
                }

                & span {
                    font-size: $font-size-sm;

                    &:first-child {
                        font-weight: 500;
                        padding-right: $p-2;
                        background: $white;
                    }

                    &+span {
                        float: right;
                        padding-left: $p-2;
                        background: $white;
                        position: relative;
                        z-index: 1
                    }
                }
            }
        }

        & table {
            border-spacing: 0;

            &>tbody {
                &>tr {
                    & td {
                        padding: 0;
                        padding-bottom: $p-2;
                        padding-right: $p-4;
                        font-size: $font-size-sm;

                        &.name {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    & .services {
        & .rw-widget {
            &.rw-multiselect {
                & .rw-widget-container {
                    &.rw-widget-input {
                        & div {
                            & .rw-multiselect-taglist {
                                & .rw-multiselect-tag {
                                    position: relative;
                                    padding: 0;
                                    border: none;

                                    &>* {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        padding: 6px;
                                        color: $white;
                                        height: 40px;
                                    }

                                    &>.tag {
                                        padding-right: 25px;
                                        border: 1px solid transparent;
                                    }

                                    &>div {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        z-index: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .serial-numbers {
        & .serial-number-input {
            position: relative;

            & .form-input {
                padding-right: $p-4;
            }

            & .input-group-btn {
                position: absolute;
                top: 0;
                border-color: transparent;
                background-color: transparent;
                padding: $p-4 $p-1;
                outline: none;
                box-shadow: none;

                &>i {
                    font-size: 18px;
                    font-weight: bold;
                    color: $gray-700;
                }

                &:nth-of-type(1) {
                    right: 30px;
                }

                &:nth-of-type(2) {
                    right: 0;
                    padding-right: $p-2;
                }

                &:hover,
                &:active {
                    &>i {
                        color: $gray-800;
                    }
                }
            }

            &.last-input {
                & .form-input {
                    padding-right: $p-4;
                }

                & .input-group-btn {
                    display: none;
                }
            }
        }
    }
}