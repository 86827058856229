@import '../../../styles/_modules/variables';

.purchase-update-cost-container {
    & .body-content {
        & .purchase-title {
            font-weight: 500;
            letter-spacing: $p-o * -.5;
            text-transform: uppercase;
            margin-bottom: $p-2;
        }

        & .purchase-items {
            margin: $p-4 0;

            &>section {
                padding: 0 !important;
                margin-top: $p-3 !important;
            }
        }
    }
}