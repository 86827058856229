.react-mentions-wrapper {
    & .react-mentions-wrapper__control {
        & .react-mentions-wrapper__highlighter {
            height: calc(1.47em + 1rem + 2px);
            padding: $input-padding-y $input-padding-x;

            & strong {
                background-color: rgba($info-500, .2);
            }
        }

        & .react-mentions-wrapper__input {
            display: block;
            width: 100%;
            padding: $input-padding-y $input-padding-x;
            height: calc(1.47em + 1rem + 2px);
            border-radius: $border-radius;
            border: 1px solid $input-border-color;
            background-color: $white;
            font-weight: 400;

            &:focus {
                border: 1px solid $input-focus-border-color;
            }
        }
    }

    & .react-mentions-wrapper__suggestions {
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid $border-color;

        @include thin-scroll();

        & .react-mentions-wrapper__suggestions__list {

            & .react-mentions-wrapper__suggestions__item {
                padding: $p-1 $p-2;
                cursor: pointer;

                &:first-child {
                    margin-top: $p-1;
                }

                &:last-child {
                    margin-bottom: $p-1;
                }

                &.react-mentions-wrapper__suggestions__item--focused {
                    background-color: $gray-200;
                }
            }
        }
    }

    &.react-mentions-wrapper-sm {
        & .react-mentions-wrapper__control {
            & .react-mentions-wrapper__highlighter {
                height: calc(1.47em + 1rem + 2px);
                padding: $input-padding-y-sm $input-padding-x-sm;

                & strong {
                    background-color: rgba($info-500, .2);
                }
            }

            & .react-mentions-wrapper__input {
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.844rem;
                font-size: 0.75rem;
                line-height: 1.5;
                border-radius: 2px;
            }
        }
    }
}

.rc-mentions {
    &>textarea {
        resize: none;
        display: block;
        width: 100%;
        padding: $input-padding-y $input-padding-x;
        height: calc(1.47em + 1rem + 2px);
        border-radius: $border-radius;
        border: 1px solid $input-border-color;
        background-color: $white;
        font-weight: 400;

        &:focus {
            border: 1px solid $input-focus-border-color;
        }
    }

    & .rc-mentions-measure {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        color: transparent;
        z-index: -1;
        font-size: inherit;
        font-size-adjust: inherit;
        font-style: inherit;
        font-variant: inherit;
        font-stretch: inherit;
        font-weight: inherit;
        font-family: inherit;
        padding: 0;
        margin: 0;
        line-height: inherit;
        vertical-align: top;
        overflow: inherit;
        word-break: inherit;
        word-wrap: break-word;
        overflow-x: initial;
        overflow-y: auto;
        text-align: inherit;
        letter-spacing: inherit;
        white-space: inherit;
        tab-size: inherit;
        direction: inherit;
    }
}

.rc-mentions-dropdown {
    border: 1px solid #999;
    border-radius: 3px;
    background: #FFF;
    height: 500px;

    & .rc-mentions-dropdown-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        & .rc-mentions-dropdown-menu-item {
            padding: 4px 8px;

            &.rc-mentions-dropdown-menu-item-active {
                background: $primary-100;
            }
        }
    }
}