@import '../../../styles/_modules/variables';

.new-order-container {
    & .action-buttons {
        & .btn-icon {
            width: 25px;
        }
    }

    & .dx-list {
        & .dx-list-item {
            & .dx-item-content {
                padding: 0;
                float: right;
                width: 323px;
                height: 125px;

                & .product-info {
                    & ul.title {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        &>li {
                            display: inline-block;
                            margin: 0;
                            padding: 0;
                            vertical-align: top;

                            &.quantity {
                                & h4 {
                                    margin-top: 1.2rem;
                                    color: $color-primary;

                                    & small {
                                        display: inline;
                                        color: $gray-700;
                                        padding: 0 $p-1;
                                    }
                                }
                            }

                            &.detail {
                                & .brand {
                                    color: $gray-600;
                                    font-size: $font-size-sm;
                                    margin-bottom: $p-1;
                                }

                                & h4 {
                                    color: $gray-800;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                &.has-quantity {
                                    max-width: 300px;
                                }
                            }
                        }
                    }
                }
            }

            & .dx-list-item-after-bag {
                &.dx-list-reorder-handle-container {
                    display: flex;
                    float: left;
                    width: 25px;
                    height: 125px;
                    align-items: center;
                }
            }

            &:hover {
                background-color: transparent !important;

                & .dx-list-reorder-handle {
                    color: $gray-700;
                }
            }
        }
    }
}