.react-calendar {
    &.calendar-picker {
        font-family: $page-font;
        font-size: $font-size-sm;
        border: 1px solid #ccc;
        box-shadow: $dropdown-shadow;
        border-radius: 0;
        z-index: 1;

        & .react-calendar__navigation {
            height: 35px;

            & .react-calendar__navigation__label {
                font-size: $font-size-sm;
                font-weight: 500;
            }

            & .react-calendar__navigation__prev2-button,
            & .react-calendar__navigation__next2-button {
                display: none;
            }
        }

        & .react-calendar__viewContainer {
            & .react-calendar__month-view__weekdays__weekday {
                padding-top: $p-2;
                padding-bottom: $p-2;
                border-bottom: 1px solid #ccc;

                & abbr[title] {
                    border: none;
                    cursor: auto;
                    font-size: $font-size-sm;
                    font-weight: 500;
                    text-decoration: none;
                }
            }
        }

        & button {
            padding: $p-2;
            color: $base-text-color;
            line-height: 1.35;

            &.react-calendar__month-view__days__day {
                &:not(.react-calendar__year-view__months__month) {
                    border-radius: 0;
                }
            }

            &.react-calendar__month-view__days__day--weekend {
                color: $base-text-color;
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
                color: $gray-300;
            }

            &.react-calendar__tile--active,
            &.react-calendar__tile--hasActive {
                background-color: $color-primary;
                color: $white;
                transition: background-color linear .2s, color linear .2s;
            }

            &.react-calendar__tile--now {
                color: $white;
                background-color: $primary-50;
            }

            &.react-calendar__tile--active,
            &.react-calendar__tile--hasActive {
                &.react-calendar__tile--now {
                    background-color: $primary-300;

                    & abbr {
                        color: $white;
                    }
                }
            }

            &.react-calendar__tile--rangeStart {
                &:not(.react-calendar__year-view__months__month) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &~.react-calendar__tile--active {
                    &:not(.react-calendar__year-view__months__month) {
                        border-radius: 0;
                    }
                }

                &.react-calendar__tile--rangeEnd {
                    &:not(.react-calendar__year-view__months__month) {
                        border-radius: 0;
                    }
                }
            }

            &.react-calendar__tile--rangeEnd {
                &:not(.react-calendar__year-view__months__month) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}