@import '../../../styles/_modules/variables.scss';
@import '../../../styles/_mixins/mixins.scss';

.dx-popup-todo {
    & .dx-overlay-content {
        background-color: $white;
        box-shadow: 0px 0px 13px 0px rgba(darken($primary-800, 15%), calc(8/100));
        border-radius: $border-radius;
        border: 1px solid rgba(0, 0, 0, 0.09);
        border-bottom: 1px solid #e0e0e0;
        border-radius: $panel-edge-radius;

        & .dx-popup-title {
            height: 60px;
            cursor: move;
            padding: 0;
            border-bottom: none;
        }

        & .dx-popup-content {
            padding: 0;

            & .popup-title {
                border-bottom: 1px solid $gray-400;

                &>.left-actions,
                &>.right-actions {
                    display: flex;
                    align-items: center;
                    height: 60px;
                    margin-top: -60px;

                    & .btn {
                        position: relative;
                    }
                }

                &>.left-actions {
                    float: left;
                    padding-top: $p-1;
                    padding-left: $p-3;
                }

                &>.right-actions {
                    float: right;
                }

                &>.actions {
                    &>ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        &>li {
                            display: inline-block;
                            margin: 0;
                            margin-right: $p-2;
                            padding: 0;

                            & .dropdown-menu {
                                z-index: 5001;
                            }
                        }
                    }
                }
            }

            & .popup-body {
                height: 100%;

                & .todo-detail {
                    height: 100%;

                    & .todo-body {
                        border-right: 1px solid $gray-300;

                        & .todo-status {
                            position: relative;

                            &.high-priority { 
                                padding-left: 15px;
                            }

                            & > i {
                                position: absolute;
                                left: 0;
                                top: 2px;
                            }
                        }

                        & .description {
                            border: 1px solid $input-border-color;
                            border-radius: $border-radius;
                            height: 150px;
                            padding: $p-2;
                            overflow-y: auto;
                            @include thin-scroll();

                            & p {
                                font-size: $font-size-lg;
                            }
                        }
                    }

                    & .todo-notes {
                        width: 300px;
                        height: 100%;

                        & .notes-container {
                            & .note-filters-wrapper {
                                padding-left: $p-3;

                                & .btn-expand {
                                    right: $p-3 - .05;
                                }
                            }

                            & .notes-body {
                                width: 100%;
                                height: 290px;

                                &.notes-disabled { 
                                    height: 438px;
                                }

                                &>.col-12 {
                                    & .profile-image {
                                        position: relative;

                                        & .badge {
                                            position: absolute;
                                            width: $p-2;
                                            height: $p-2;
                                            min-width: $p-2;
                                            min-height: $p-2;
                                            line-height: 1;
                                            right: 0;
                                            bottom: $p-o * -1;
                                        }
                                    }

                                    & .pinned {
                                        position: absolute;
                                        top: -3px;
                                    }

                                    & .dropdown {
                                        &.note-action-dropdown {
                                            display: inline-block;


                                            & .dropdown-menu {
                                                display: none;
                                                min-width: initial;
                                                padding: $p-1;

                                                &.dropdown-menu-right {
                                                    top: 0 !important;
                                                    left: auto !important;
                                                    right: ($p-2 + $p-h) !important;
                                                    margin-top: -60%;
                                                }

                                                & .dropdown-item {
                                                    padding: $p-h;
                                                }
                                            }

                                            &.active {
                                                & .dropdown-menu {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    & hr {
                                        border-bottom-style: solid;

                                        &.new {
                                            position: relative;
                                            border-bottom: 1px solid $info-50;

                                            &:after {
                                                position: absolute;
                                                content: 'Recent';
                                                font-size: $font-size-sm;
                                                width: $p-2 * 8;
                                                top: calc($font-size-sm / -1.5);
                                                left: 50%;
                                                margin-left: calc(($p-2 * 8) / -2);
                                                background: $white;
                                                text-align: center;
                                                color: $info-200;
                                            }
                                        }
                                    }

                                    & .deleted {
                                        & .profile-image {
                                            filter: grayscale(100%);
                                            opacity: .5;
                                            transition: filter linear .2s, opacity linear .2s;
                                        }

                                        & .note-by {
                                            color: $gray-500;
                                        }

                                        & .html {
                                            color: $gray-500;
                                            text-decoration: line-through;
                                        }
                                    }
                                }
                            }

                            & .notes-footer {
                                padding: 0;

                                & .dx-htmleditor {
                                    border: none;

                                    & .ql-toolbar {
                                        border-top: none;
                                        border-left: none;
                                        border-right: none;
                                    }

                                    & .ql-container {
                                        border: none !important;
                                        padding: $p-h $p-1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}