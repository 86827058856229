@import '../../../../../styles/_modules/variables';

.new-referral-exam-dataset {
    & .datasets {
        & ul {
            & li {
                font-size: $font-size-sm;
                &.number {
                    width: 20px;
                }
                &.expression {
                    width: 277px;
                }
            }
        }
    }
}

// .new-patient {
//     & .resource-dropdown {
//         width: 100%;

//         & .btn {
//             display: flex;
//             align-items: center;
//             position: relative;
//             width: 100%;
//             height: auto;
//             text-align: left;
//             padding-left: 0;

//             & .description {
//                 margin-left: $unit-4;
//                 color: $gray-color-dark;
//                 font-size: $font-size-xl;
//             }

//             & i {
//                 position: absolute;
//                 right: $unit-4;
//             }
//         }

//         & .menu {
//             width: 100%;

//             & .menu-item {
//                 & a {
//                     display: flex;
//                     align-items: center;

//                     & .description {
//                         margin-left: $unit-4;
//                         color: $gray-color;
//                     }

//                     &:hover,
//                     &:focus,
//                     &:active {
//                         background-color: transparent;

//                         & .description {
//                             color: $primary-color;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }