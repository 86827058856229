@import '../../../styles/_modules/variables';

.add-exam-data-container {
    & .dx-datagrid {
        & .dx-datagrid-header-panel {
            display: none;
        }
        & .dx-row {
            &.dx-group-row {
                & td {
                    padding: $p-3 0;
                    color: $gray-600;

                    &.dx-command-select {
                        display: none;
                    }

                    &.dx-command-expand {
                        padding: 0;
                        vertical-align: middle;
                    }
                }
            }
            &.dx-data-row {
                & td {
                    &.dx-command-expand {
                        display: none;
                    }
                }
            }
        }
    }


    & .dx-list {
        & .dx-list-item {
            & .dx-item-content {
                padding: 0;
                float: right;

                & .product-info {
                    & ul.title {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        &>li {
                            display: inline-block;
                            margin: 0;
                            padding: 0;
                            vertical-align: top;

                            &.quantity {
                                & h4 {
                                    margin-top: 1.2rem;
                                    color: $color-primary;

                                    & small {
                                        display: inline;
                                        color: $gray-700;
                                        padding: 0 $p-1;
                                    }
                                }
                            }

                            &.detail {
                                & .brand {
                                    color: $gray-600;
                                    font-size: $font-size-sm;
                                    margin-bottom: $p-1;
                                }

                                & h4 {
                                    color: $gray-800;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                &.has-quantity {
                                    max-width: 300px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}