@import '../../../styles/_modules/variables';

.view-group-appointment {
    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                    }

                    & .date,
                    & .time {
                        display: block;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }
                }
            }
        }
    }

    & .resource,
    & .customer {
        & .rw-dropdown-list {
            & .rw-widget-container {
                padding-top: $p-1;
                padding-bottom: $p-1;

                & .rw-dropdown-list-input {
                    padding-left: $p-1;
                }
            }

            & .profile-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-right: $p-2;
                transition: background-color linear .2s;

                & .profile {
                    margin-right: $p-2;
                }

                & .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    & .name {
                        font-size: $font-size-lg;
                        font-weight: normal;
                    }

                    & .info {
                        color: $gray-600;

                        & address {
                            margin-bottom: 0;

                            & span {
                                white-space: nowrap;

                                &:after {
                                    content: ' ';
                                }

                                &.locality,
                                &.region {
                                    &:after {
                                        content: ', ';
                                    }
                                }

                                &.line1,
                                &.line2 {
                                    display: block;
                                }

                                &.country {
                                    display: inline-block;
                                }
                            }
                        }
                    }

                    & .services {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        margin-top: $p-o;

                        &>li {
                            display: inline-block;
                            padding: 0;
                            margin: 0;
                            margin-right: $p-1;
                        }
                    }
                }
            }
        }
    }

    & .timeline-status {
        & .timeline {
            margin-left: $p-2;

            & .timeline-item {
                &::before {
                    background: $gray-200;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }

                & .timeline-icon {
                    border-width: $p-o;
                    border-style: solid;
                    background: $white;
                    cursor: default;
                }

                & .timeline-content {
                    position: relative;
                    padding-left: 1.3rem;

                    & .btn.btn-link.text-truncate {
                        max-width: 250px;
                    }

                    & .btn-icon.btn-close {
                        position: absolute;
                        top: $p-2 *-1;
                        right: 0;

                        & i {
                            color: $color-danger;
                        }

                        &:active,
                        &:hover,
                        &:focus {
                            background-color: transparent !important;
                            border-color: $white !important;
                            box-shadow: none !important;
                        }
                    }
                }

                &.incomplete {
                    & .timeline-icon {
                        border: 2px solid $gray-500;
                        background: $white;

                        & i {
                            color: $gray-500;
                        }
                    }
                }

                &.started {
                    &::before {
                        background: $gray-600;
                    }
                }
            }

            &.completed {
                & .timeline-item {
                    &.started {
                        &::before {
                            background: $success-600;
                        }
                    }
                }
            }

            &.no-show {
                & .timeline-item {
                    &.started {
                        &::before {
                            background: $danger-600;
                        }
                    }
                }
            }
        }
    }
}