@import '../../styles/_modules/variables';
@import '../../styles/_mixins/mixins.scss';

.page-header {
    & .btn {
        &.header-icon[data-toggle="dropdown"] {
            &+.dropdown-menu {
                position: absolute;
                border: 0px solid $table-border-color;
                right: $p-3 * 2;
                top: $p-3 * 4 !important;
                left: auto !important;
                padding: 0;
                margin: 0;
                border-radius: $border-radius;

                & .dropdown-header {
                    border-radius: $border-radius;
                }
            }
        }
    }

    & .notification-dropdown-menu {
        & .app-list {
            margin-bottom: $p-2;
            height: 100% !important;
            max-height: 360px;
            overflow-x: hidden;
            @include thin-scroll();
        }

        & .tab-notification {
            & .tab-pane {
                max-height: 100%;
                overflow-x: hidden;
                @include thin-scroll();

                & .notification {
                    & .notification-item {
                        &.unread {
                            background-color: lighten($color-success, 53%);
                        }

                        & .name {
                            position: relative;

                            &.high-priority {
                                padding-left: 10px;

                                &>i {
                                    position: absolute;
                                    left: 0;
                                    top: -5px;
                                }
                            }
                        }

                        & .msg-a {
                            &.html {
                                overflow: hidden;
                                max-width: 250px;

                                &.msg-truncate {
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }

                        & .btn-complete {
                            font-weight: 500;
                            opacity: 0;
                            transition: opacity linear .2s;
                        }

                        &:hover {
                            & .btn-complete {
                                opacity: 1;
                                transition: opacity linear .2s;
                            }
                        }
                    }
                }
            }
        }

        & .notification-footer {
            min-height: 42px;
            height: 42px;
        }
    }

    & .online-booking-dropdown-menu {
        max-height: 360px;
        overflow-x: hidden;
        @include thin-scroll();

        & .notification {

            & .online-booking-item {

                & .msg-a {
                    &.html {
                        overflow: hidden;
                        max-width: 250px;

                        &.msg-truncate {
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
    }

    & #__admin_menu__ {
        & .app-list {
            & .app-list-item {
                & .profile-wrapper {
                    justify-content: center;
                    padding: 0;

                    & .profile {
                        margin: 0;
                        margin-top: 4px;

                        & .profile-image {
                            width: 3.125rem;
                            height: 3.125rem;
                        }
                    }
                }

                &:hover {
                    & .profile-wrapper {
                        & .profile {
                            & .profile-image {
                                width: 3.1875rem;
                                height: 3.1875rem;
                            }
                        }
                    }
                }
            }

            & .app-list-name {
                margin-top: $p-1;
            }
        }
    }
}

// .navbar {
//     position: sticky;
//     background-color: $light-color;
//     border-bottom: $border-width solid $border-color;
//     top: 0;
//     z-index: $zindex-4;

//     & .navbar-section {
//         &.search {
//             flex: 3 0;
//         }
//         & .btn-link {
//             & .material-icons {
//                 color: $gray-color-light;
//                 transition: color linear .2s;
//             }
//             &:hover {
//                 & .material-icons {
//                     color: $gray-color-lighter;
//                     transition: color linear .2s;
//                 }
//             }
//         }
//     }
// }