@import '../../styles/_modules/variables.scss';

.image-annotation {
    position: relative;
    height: 100%;

    & .drawarea,
    & .image-background,
    & .path-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        & svg {
            width: 100%;
        }
    }

    & .drawarea {
        z-index: 1;
    }

    & .image-background {
        z-index: -11;
    }

    & .buttons {
        position: absolute !important;
        top: 0 !important;
        right: $p-1 !important;
        z-index: 2 !important;
        text-align: right;

        & .btn.btn-icon {
            top: 0 !important;
            padding: 0 !important;

            &:hover {
                top: ($p-o * -1) !important;
            }

            &.btn-close {
                color: $color-danger !important;
            }

            &.btn-undo {
                color: $color-info !important;
            }

            &>i {
                font-size: $font-size-lg;
            }
        }
    }

    & .path-overlay {
        position: relative;
        z-index: -10;

        & svg {
            position: absolute;
            top: 0;
            left: 0;

            & path {
                fill: $danger-800;
                width: 2px;
            }

            & .path-line {
                fill: none;
                stroke-width: 1px;
                stroke: $danger-800;
                stroke-linejoin: round;
                stroke-linecap: round;
            }
        }
    }

    & .drawarea {
        cursor: crosshair;

        &.draw-disabled {
            cursor: default;
        }

        & .path-line {
            fill: none;
            stroke-width: 1px;
            stroke: $danger-800;
            stroke-linejoin: round;
            stroke-linecap: round;
        }
    }

    // & .drawing {
    //     width: 100%;
    //     height: 100%;
    // }
}