@import '../../../styles/_modules/variables';

.new-timeoff {

    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .control {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 10;

                    &.transparent {
                        &>.rw-datetime-picker {
                            &>.rw-widget-container {
                                opacity: 0;
                                border: none;
                            }
                        }
                    }

                    &.right {
                        left: -80px;
                    }

                    & .control-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    & .rw-widget.rw-state-focus {
                        & .rw-widget-picker {
                            border: none;
                            box-shadow: none;
                        }
                    }
                }

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                        transition: color linear .15s;
                        cursor: pointer;
                    }

                    & .date,
                    & .time {
                        display: block;
                        transition: color linear .15s;
                        cursor: pointer;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }

                    &:hover,
                    &:active,
                    &.active {
                        & .weekday {
                            color: $color-primary;
                            transition: color linear .15s;
                        }

                        & .date,
                        & .time {
                            color: $color-primary;
                            transition: color linear .15s;
                        }
                    }
                }
            }
        }
    }

    & .user {
        & .rw-dropdown-list {
            & .rw-widget-container {
                padding-top: $p-1;
                padding-bottom: $p-1;

                & .rw-dropdown-list-input {
                    padding-left: $p-1;
                }
            }

            & .profile-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-right: $p-2;
                cursor: pointer;
                transition: background-color linear .15s;

                & .profile {
                    margin-right: $p-2;

                    & .profile-image {
                        width: $p-3 * 2;
                        height: $p-3 * 2;
                        margin-right: $p-2;
                    }
                }

                & .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: $font-size-lg;
                }
            }
        }
    }

    & .days-of-week {
        padding-right: $p-4;

        & .custom-control {
            &.custom-checkbox {
                & .custom-control-label {
                    position: absolute;
                    left: 0;
                    width: 45px;
                    text-align: center;
                    z-index: 1;

                    &::before,
                    &::after {
                        position: absolute;
                        left: 50%;
                        margin-left: -9px;
                    }
                }
            }
        }
    }

    & .breaks {
        & ul {
            & li {
                font-size: $font-size-sm;
                &.number {
                    width: 20px;
                }
                &.title {
                    width: 277px;
                }
                &.time {
                    width: 125px;

                    & .rw-datetime-picker {
                        & .rw-widget-container {
                            & .rw-widget-input.rw-input {
                                text-transform: lowercase;
                                font-size: $font-size-sm;
                            }
                        }
                    }
                }
            }
        }
    }
}