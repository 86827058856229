@import '~react-widgets/dist/css/react-widgets.css';
@import '../_mixins/mixins.scss';

.rw-widget {
    color: $gray-700;
    font-size: $font-size-base;

    &.placeholder {
        & .rw-input {
            &::placeholder {
                font-size: $font-size-base;
                color: $gray-600;
            }
        }

        &.placeholder-xs {
            & .rw-input {
                &::placeholder {
                    font-size: $font-size-xs;
                }
            }
        }

        &.placeholder-sm {
            & .rw-input {
                &::placeholder {
                    font-size: $font-size-sm;
                }
            }
        }

        &.placeholder-lg {
            & .rw-input {
                &::placeholder {
                    font-size: $font-size-lg;
                }
            }
        }

        &.placeholder-xl {
            & .rw-input {
                &::placeholder {
                    font-size: $font-size-lg;
                }
            }
        }
    }

    &.rw-combobox,
    &.rw-dropdown-list {
        & .rw-widget-container {
            box-shadow: none;
            border: 1px solid $table-border-color;
            padding: $p-2 $dropdown-padding-y;

            & .rw-widget-input {
                box-shadow: none;
            }

            & .rw-select-bordered {
                border: none;

                &:active,
                &:hover {
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }

        &.rw-state-focus,
        &.rw-open {
            & .rw-widget-container {
                border-radius: $border-radius;
                box-shadow: none;
                border-color: $color-primary;
            }
        }

        &.rw-state-disabled {
            &:not(.rw-state-disabled-override) {
                &>.rw-widget-picker {
                    background-color: #f3f3f3;
                    color: #868e96;
                }
            }
        }

        & .rw-popup-container {
            & .rw-list {
                & .rw-list-option {
                    padding: $input-padding-y $input-padding-x;
                    margin: 0;
                    color: $gray-700;
                    line-height: 1.5;

                    &.rw-state-focus {
                        border-color: transparent;
                        background-color: rgba($color-primary, 0.2);
                    }

                    &.rw-state-selected {
                        border-color: transparent;
                        background-color: $color-primary;
                        color: $white;
                    }
                }

                & .rw-list-empty {
                    padding: $p-1;
                    margin: 0;
                    color: $color-danger;
                    font-size: $font-size-xs;
                }
            }
        }
    }

    &.rw-multiselect {
        & .rw-widget-container {
            box-shadow: none;
            border: 1px solid $input-border-color;
            border-radius: $border-radius;
            padding: $input-padding-x-sm / 2 $dropdown-padding-y;
            padding-bottom: 0;

            &.rw-widget-input {
                & div {
                    & .rw-multiselect-taglist {
                        vertical-align: top;
                        margin-top: 0;
                        margin-left: $p-o;

                        & .rw-multiselect-tag {
                            position: relative;
                            padding: 0;
                            border: none;
                            margin: 0 6px 0 0;
                            margin-bottom: $dropdown-padding-y / 2;
                            color: $gray-700;
                            background: $gray-300;

                            &>* {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                padding: $p-1 $p-2;
                                color: $white;
                            }

                            &>.tag {
                                padding-right: 25px;
                                border: 1px solid transparent;
                            }

                            &>div {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 1;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        & .rw-input-reset {
            height: calc(1.47em + $dropdown-padding-y + 2px);
            margin-bottom: $dropdown-padding-y;
        }

        &.rw-state-focus,
        &.rw-open {
            & .rw-widget-container {
                box-shadow: none;
                border-color: $color-primary;
                border-radius: $border-radius;
            }
        }

        & .rw-popup-container {
            & .rw-list {
                & .rw-list-option {
                    padding: $p-1;
                    margin: 0;
                    color: $gray-700;
                    font-size: $font-size-xs;
                    line-height: 1.5;

                    &.rw-state-focus {
                        border-color: transparent;
                        background-color: rgba($color-primary, 0.2);
                    }

                    &.rw-state-selected {
                        border-color: transparent;
                        background-color: $color-primary;
                        color: $white;
                    }
                }

                & .rw-list-empty {
                    padding: $p-1;
                    margin: 0;
                    color: $color-danger;
                    font-size: $font-size-xs;
                }
            }
        }
    }

    &.rw-select-list {
        &.rw-widget-container {
            min-height: 48px;
            box-shadow: none;
            border: 1px solid $table-border-color;
            border-radius: $border-radius;

            &.rw-widget-input {
                & .rw-list {
                    & .rw-list-option {
                        padding: $p-1;
                        margin: 0;
                        margin-left: $p-4 * -1;
                        color: $gray-700;
                        font-size: $font-size-xs;
                        line-height: 1.5;

                        &.rw-state-focus {
                            border-color: transparent;
                            background-color: rgba($color-primary, 0.2);
                        }

                        &.rw-state-selected {
                            border-color: transparent;
                            background-color: $color-primary;
                            color: $white;
                        }
                    }

                    & .rw-list-empty {
                        padding: $p-1;
                        margin: 0;
                        color: $color-danger;
                        font-size: $font-size-xs;
                    }
                }
            }
        }
    }

    &.rw-datetime-picker {
        & .rw-widget-container {
            box-shadow: none;
            border: 1px solid $input-border-color;
            border-radius: $border-radius;
            transition: border-color linear .15s;

            & .rw-widget-input {
                padding: $input-padding-y $input-padding-x;
                height: calc(1.47em + 1rem);
                box-shadow: none;
            }

            & .rw-select {
                padding-left: $p-1;
                padding-right: $p-1;
                border-left: 1px solid $input-border-color;
            }
        }

        &.rw-state-focus {
            &>.rw-widget-container {
                border: 1px solid $color-primary;
                border-radius: $border-radius;
                transition: border-color linear .15s;

                &:hover,
                &:active,
                &:focus {
                    box-shadow: none;
                }
            }
        }

        & .rw-popup-container {
            &.rw-calendar-popup {
                width: 250px;

                & .rw-calendar {
                    font-size: 1rem;

                    & .rw-calendar-header {

                        & .rw-calendar-btn-left,
                        & .rw-calendar-btn-right,
                        & .rw-calendar-btn-view {
                            padding: $input-padding-y-sm $input-padding-x-sm;
                            font-size: $font-size-base;
                            font-weight: 500;
                            color: $gray-700;
                        }
                    }

                    & .rw-calendar-transition-group {
                        & .rw-calendar-grid {
                            margin-top: $p-2;
                            height: 11.8em;

                            & .rw-calendar-head {
                                & .rw-calendar-row {
                                    & .rw-head-cell {
                                        font-size: $font-size-base;
                                        color: $gray-700;
                                        padding: $p-1;
                                    }
                                }
                            }

                            & .rw-calendar-body {
                                & .rw-calendar-row {
                                    & .rw-cell {
                                        font-size: $font-size-base;
                                        color: $gray-700;
                                        padding: $p-o;
                                        line-height: 1;
                                        border-radius: 0;
                                        border: none;

                                        &.rw-cell-off-range {
                                            color: $gray-500;
                                        }

                                        &.rw-now {
                                            color: $white;
                                            background-color: lighten($color-primary, 15%);
                                        }

                                        &.rw-state-selected {
                                            color: $white;
                                            background-color: $color-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:not(.rw-calendar-popup) {
                left: -15px;
                right: -25px;

                & .rw-list {
                    font-size: $font-size-sm;
                    @include thin-scroll();

                    & .rw-list-option {
                        margin: 0;
                        padding: $p-h $p-1;
                        text-transform: lowercase;
                        color: $base-text-color;
                        text-align: right;
                        white-space: nowrap;

                        &.rw-state-focus {
                            border-color: transparent;
                            background-color: rgba($color-primary, 0.2);
                        }

                        &.rw-state-selected {
                            border-color: transparent;
                            background-color: $color-primary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    & .rw-widget-container {
        border-radius: $border-radius;
    }
}

fieldset[disabled] {
    & .rw-widget-picker {
        background-color: #f7f7f7;
        opacity: 0.5;
    }
}