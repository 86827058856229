@import '../../../styles/_modules/variables';

.previous-exam {
    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                    }

                    & .date,
                    & .time {
                        display: block;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }
                }
            }
        }
    }

    & .customer {
        & .profile-wrapper {
            display: flex;
            flex-direction: row;
            padding: $p-2;
            padding-left: 0;
            padding-top: 0;
            transition: background-color linear .2s;

            & .profile {
                margin-right: $p-2;
            }

            & .description {
                display: flex;
                flex-direction: column;
                justify-content: center;

                & .name {
                    font-size: $font-size-lg;
                    font-weight: normal;
                }

                & .info {
                    color: $gray-600;

                    & address {
                        margin-bottom: 0;

                        & span {
                            white-space: nowrap;

                            &:after {
                                content: ' ';
                            }

                            &.locality,
                            &.region {
                                &:after {
                                    content: ', ';
                                }
                            }

                            &.line1,
                            &.line2 {
                                display: block;
                            }

                            &.country {
                                display: inline-block;
                            }
                        }
                    }
                }

                & .services {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    margin-top: $p-o;

                    &>li {
                        display: inline-block;
                        padding: 0;
                        margin: 0;
                        margin-right: $p-o;
                    }
                }
            }
        }
    }
}

// @import '../../../styles/variables';

// .new-patient {
//     & .resource-dropdown {
//         width: 100%;

//         & .btn {
//             display: flex;
//             align-items: center;
//             position: relative;
//             width: 100%;
//             height: auto;
//             text-align: left;
//             padding-left: 0;

//             & .description {
//                 margin-left: $unit-4;
//                 color: $gray-color-dark;
//                 font-size: $font-size-xl;
//             }

//             & i {
//                 position: absolute;
//                 right: $unit-4;
//             }
//         }

//         & .menu {
//             width: 100%;

//             & .menu-item {
//                 & a {
//                     display: flex;
//                     align-items: center;

//                     & .description {
//                         margin-left: $unit-4;
//                         color: $gray-color;
//                     }

//                     &:hover,
//                     &:focus,
//                     &:active {
//                         background-color: transparent;

//                         & .description {
//                             color: $primary-color;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }