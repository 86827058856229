@import '../../styles/_modules/variables.scss';

.page-logo,
.page-sidebar,
.nav-footer {
    background-image: none;

    & .info-card {
        background-color: darken($nav-background, 5%);
    }
}

.page-sidebar {
    & .primary-nav {
        & .nav-menu {
            & li {
                &.active {
                    &>a {
                        box-shadow: inset 3px 0 0 $color-success !important;
                    }

                    &:not(.open) {
                        &>a {
                            &:before {
                                color: $success-50;
                            }
                        }
                    }
                }

                &>a {
                    &>i {
                        text-align: center;
                        margin-left: -10% !important;
                    }
                }
            }
        }
    }
}

.nav-function-fixed {
    &:not(.nav-function-top) {
        & .page-sidebar {
            transition: width linear .3s;

            & .primary-nav {
                height: calc(100% - 2.8125rem);

                & .nav-menu {
                    & .nav-title {
                        color: $primary-50;
                    }

                    & li {
                        & a {
                            color: $gray-500;
                        }

                        &.active {
                            & a {
                                color: $white;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

.nav-function-minify {
    & .page-sidebar {
        transition: width linear .3s;

        & .primary-nav {
            & .nav-menu {
                & .nav-title {
                    color: $primary-50;
                }

                & li {
                    & a {
                        color: $gray-500;
                    }

                    &.active {
                        & a {
                            color: $white;
                            font-weight: 500;
                        }
                    }
                }
            }

            & .info-card {
                & .profile-image {
                    margin-left: -0.3rem;
                }
            }
        }
    }
}

.nav-footer {
    background-color: darken($nav-background, 5%) !important;

    &>.btn-link {
        color: $white;
    }

    & .nav-function-minify-expand {
        font-size: $font-size-lg;
    }

    & .nav-footer-buttons {
        &>li {

            &>button,
            &>a {
                display: block;
                color: $white;
                height: $footer-height - 1;
                line-height: $footer-height - 1;
                margin-top: 1px;
                padding: 0 13px;
                overflow: visible;
                font-size: rem($fs-xl);
                background: transparent;
                border: none;
            }
        }
    }

    &>button,
    &>a {
        color: $gray-500;
    }
}