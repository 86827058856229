body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $gray-700;
}

textarea {
    resize: none;
}

address {
    margin-bottom: 0;

    & span {
        white-space: nowrap;

        &:after {
            content: ' ';
        }

        &.locality,
        &.region {
            &:after {
                content: ', ';
            }
        }

        &.line1,
        &.line2 {
            display: block;
        }

        &.country {
            display: inline-block;
        }
    }
}

.address-sameline {
    & address {
        & span {

            &.line1,
            &.line2,
            &.line3,
            &.line4 {
                display: inline-block !important;
                margin-right: $p-h !important;
            }

            &.line1,
            &.line2 {
                &:after {
                    content: ', ' !important;
                }
            }
        }
    }
}

.time-range {
    & time {
        display: inline-block;
        white-space: nowrap;

        & .ampm {
            margin-left: $p-o;
        }
    }

    & .time-range-dash {
        margin: 0 $p-o;
    }
}

.genki {
    main {

        & .page-breadcrumb,
        & .subheader {
            margin-bottom: $p-3;
        }
    }
}

.profile-wrapper {
    display: flex;
    flex-direction: row;
    padding: $p-2;
    padding-left: 0;
    padding-top: 0;
    transition: background-color linear .2s;

    & .profile {
        margin-right: $p-2;
    }

    & .description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .name {
            font-size: $font-size-lg;
            font-weight: normal;
        }

        & .info {
            color: $gray-600;

            & address {
                margin-bottom: 0;

                & span {
                    white-space: nowrap;

                    &:after {
                        content: ' ';
                    }

                    &.locality,
                    &.region {
                        &:after {
                            content: ', ';
                        }
                    }

                    &.line1,
                    &.line2 {
                        display: block;
                    }

                    &.country {
                        display: inline-block;
                    }
                }
            }

            & .label-info {
                display: inline-block;
                width: $p-4;
            }
        }
    }
}

.control-focus-stretch {
    width: 200px;
    transition: width linear .15s;

    &:focus,
    &:active,
    &.active {
        width: 400px;
        transition: width linear .15s;
    }
}

.profile-image,
.profile-image-lg,
.profile-image-md,
.profile-image-sm {
    &.profile-initials {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: $white;

        & .d-initials {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
        }
    }

    &.not-active {
        filter: grayscale(100%);
        opacity: .5;
        transition: filter linear .2s, opacity linear .2s;
    }
}

.profile-image {
    &.profile-initials {
        font-size: $font-size-lg;
    }

    &.profile-group-default {
        background-color: $color-default;
    }

    &.profile-group-a {
        background-color: $color-blue;
    }

    &.profile-group-b {
        background-color: $color-yellow;
    }

    &.profile-group-c {
        background-color: $color-red;
    }

    &.profile-group-d {
        background-color: $color-purple;
    }

    &.profile-group-e {
        background-color: $color-green;
    }

    &.profile-group-f {
        background-color: $color-orange;
    }

    &.profile-group-g {
        background-color: $color-teal;
    }

    &.profile-group-h {
        background-color: $color-brown;
    }

    &.profile-group-i {
        background-color: $color-pink;
    }

    &.profile-group-j {
        background-color: $color-black;
    }
}

.profile-image-lg {
    &.profile-initials {
        font-size: $font-size-lg * 1.5;
    }
}

.profile-image-lg {
    width: $profile-image-width-lg;
    height: $profile-image-width-lg;
}

.html {
    & p {
        margin-bottom: 0;
        line-height: $line-height-base;
    }
}

.form-control {
    border-radius: $border-radius;
}

.chip {
    display: inline-block;
    line-height: 1;
    border-radius: $border-radius;
    border: 1px solid transparent;
    padding: $p-1;
    padding-top: $p-2;
    margin: 0;
    color: $white;
    margin-right: $p-1;
    min-width: 25px;
    text-align: center;
    text-shadow: none;
    box-sizing: border-box !important;

    &.chip-sm {
        border-radius: $border-radius / 2;
        font-size: $font-size-xs;
        line-height: $font-size-xs;
        padding: $p-h;
        padding-top: $p-1;
    }

    &.chip-first {
        background-color: $white !important;
        border: 1px solid $color-danger !important;
        border-color: $color-danger !important;
        color: $color-danger !important;
    }
}

.badge {
    border-radius: $border-radius;

    &.badge-new {
        background-color: $white !important;
        border: 1px solid $color-danger !important;
        border-color: $color-danger !important;
        color: $color-danger !important;
    }

    &.badge-first-exam {
        background-color: $white !important;
        border: 1px solid $warning-800 !important;
        border-color: $warning-800 !important;
        color: $warning-800 !important;
    }

    &.badge-prebook {
        background-color: $white !important;
        border: 1px solid $info-800 !important;
        border-color: $info-800 !important;
        color: $info-800 !important;
    }

    &.badge-dot {
        border-radius: 100%;
        padding: $p-o;
        color: transparent;
        font-size: 0px;

        &>span {
            display: none;
        }

        &:empty {
            display: block;
        }

        &:after {
            content: ' ';
            font-size: 1px;
        }
    }
}

.text-hidden {
    opacity: 0;
    font-size: .1rem;
}

.text-strike {
    text-decoration: line-through;
}

.text-shadow-0 {
    text-shadow: none !important;
}

.fs-200 {
    font-size: 200% !important;
}

.fs-195 {
    font-size: 195% !important;
}

.fs-190 {
    font-size: 190% !important;
}

.fs-185 {
    font-size: 185% !important;
}

.fs-180 {
    font-size: 180% !important;
}

.fs-175 {
    font-size: 175% !important;
}

.fs-170 {
    font-size: 170% !important;
}

.fs-165 {
    font-size: 165% !important;
}

.fs-160 {
    font-size: 160% !important;
}

.fs-155 {
    font-size: 155% !important;
}

.fs-150 {
    font-size: 150% !important;
}

.fs-145 {
    font-size: 145% !important;
}

.fs-140 {
    font-size: 140% !important;
}

.fs-135 {
    font-size: 135% !important;
}

.fs-130 {
    font-size: 130% !important;
}

.fs-125 {
    font-size: 125% !important;
}

.fs-120 {
    font-size: 120% !important;
}

.fs-115 {
    font-size: 115% !important;
}

.fs-110 {
    font-size: 110% !important;
}

.fs-105 {
    font-size: 105% !important;
}

.fs-95 {
    font-size: 95% !important;
}

.fs-90 {
    font-size: 90% !important;
}

.fs-85 {
    font-size: 85% !important;
}

.fs-80 {
    font-size: 80% !important;
}

.fs-75 {
    font-size: 75% !important;
}

.fs-70 {
    font-size: 70% !important;
}

.fs-65 {
    font-size: 65% !important;
}

.fs-60 {
    font-size: 60% !important;
}

.fs-55 {
    font-size: 55% !important;
}

.fs-50 {
    font-size: 50% !important;
}

.fs-base {
    font-size: $fs-base;
}

@include padding('p-o', $p-o);
@include padding-top('pt-o', $p-o);
@include padding-bottom('pb-o', $p-o);
@include padding-left('pl-o', $p-o);
@include padding-right('pr-o', $p-o);
@include padding-x('px-o', $p-o);
@include padding-y('py-o', $p-o);

@include padding('p-h', $p-h);
@include padding-top('pt-h', $p-h);
@include padding-bottom('pb-h', $p-h);
@include padding-left('pl-h', $p-h);
@include padding-right('pr-h', $p-h);
@include padding-x('px-h', $p-h);
@include padding-y('py-h', $p-h);

@include padding('p-1', $p-1);
@include padding-top('pt-1', $p-1);
@include padding-bottom('pb-1', $p-1);
@include padding-left('pl-1', $p-1);
@include padding-right('pr-1', $p-1);
@include padding-x('px-1', $p-1);
@include padding-y('py-1', $p-1);

@include padding('p-2', $p-2);
@include padding-top('pt-2', $p-2);
@include padding-bottom('pb-2', $p-2);
@include padding-left('pl-2', $p-2);
@include padding-right('pr-2', $p-2);
@include padding-x('px-2', $p-2);
@include padding-y('py-2', $p-2);

@include padding('p-3', $p-3);
@include padding-top('pt-3', $p-3);
@include padding-bottom('pb-3', $p-3);
@include padding-left('pl-3', $p-3);
@include padding-right('pr-3', $p-3);
@include padding-x('px-3', $p-3);
@include padding-y('py-3', $p-3);

@include padding('p-4', $p-4);
@include padding-top('pt-4', $p-4);
@include padding-bottom('pb-4', $p-4);
@include padding-left('pl-4', $p-4);
@include padding-right('pr-4', $p-4);
@include padding-x('px-4', $p-4);
@include padding-y('py-4', $p-4);

@include padding('p-5', $p-5);
@include padding-top('pt-5', $p-5);
@include padding-bottom('pb-5', $p-5);
@include padding-left('pl-5', $p-5);
@include padding-right('pr-5', $p-5);
@include padding-x('px-5', $p-5);
@include padding-y('py-5', $p-5);

@include padding('p-6', ($p-3 * 2));
@include padding-top('pt-6', ($p-3 * 2));
@include padding-bottom('pb-6', ($p-3 * 2));
@include padding-left('pl-6', ($p-3 * 2));
@include padding-right('pr-6', ($p-3 * 2));
@include padding-x('px-6', ($p-3 * 2));
@include padding-y('py-6', ($p-3 * 2));

@include padding('p-7', ($p-3 * 2.5));
@include padding-top('pt-7', ($p-3 * 2.5));
@include padding-bottom('pb-7', ($p-3 * 2.5));
@include padding-left('pl-7', ($p-3 * 2.5));
@include padding-right('pr-7', ($p-3 * 2.5));
@include padding-x('px-7', ($p-3 * 2.5));
@include padding-y('py-7', ($p-3 * 2.5));

@include padding('p-8', ($p-4 * 2));
@include padding-top('pt-8', ($p-4 * 2));
@include padding-bottom('pb-8', ($p-4 * 2));
@include padding-left('pl-8', ($p-4 * 2));
@include padding-right('pr-8', ($p-4 * 2));
@include padding-x('px-8', ($p-4 * 2));
@include padding-y('py-8', ($p-4 * 2));

@include padding('p-9', ($p-4 * 2.5));
@include padding-top('pt-9', ($p-4 * 2.5));
@include padding-bottom('pb-9', ($p-4 * 2.5));
@include padding-left('pl-9', ($p-4 * 2.5));
@include padding-right('pr-9', ($p-4 * 2.5));
@include padding-x('px-9', ($p-4 * 2.5));
@include padding-y('py-9', ($p-4 * 2.5));

@include padding('p-10', ($p-5 * 2));
@include padding-top('pt-10', ($p-5 * 2));
@include padding-bottom('pb-10', ($p-5 * 2));
@include padding-left('pl-10', ($p-5 * 2));
@include padding-right('pr-10', ($p-5 * 2));
@include padding-x('px-10', ($p-5 * 2));
@include padding-y('py-10', ($p-5 * 2));

@include padding('p-11', ($p-5 * 2.5));
@include padding-top('pt-11', ($p-5 * 2.5));
@include padding-bottom('pb-11', ($p-5 * 2.5));
@include padding-left('pl-11', ($p-5 * 2.5));
@include padding-right('pr-11', ($p-5 * 2.5));
@include padding-x('px-11', ($p-5 * 2.5));
@include padding-y('py-11', ($p-5 * 2.5));

@include padding('p-12', (($p-5 + $p-1) * 2));
@include padding-top('pt-12', (($p-5 + $p-1) * 2));
@include padding-bottom('pb-12', (($p-5 + $p-1) * 2));
@include padding-left('pl-12', (($p-5 + $p-1) * 2));
@include padding-right('pr-12', (($p-5 + $p-1) * 2));
@include padding-x('px-12', (($p-5 + $p-1) * 2));
@include padding-y('py-12', (($p-5 + $p-1) * 2));

@include padding('p-13', (($p-5 + $p-1) * 2.5));
@include padding-top('pt-13', (($p-5 + $p-1) * 2.5));
@include padding-bottom('pb-13', (($p-5 + $p-1) * 2.5));
@include padding-left('pl-13', (($p-5 + $p-1) * 2.5));
@include padding-right('pr-13', (($p-5 + $p-1) * 2.5));
@include padding-x('px-13', (($p-5 + $p-1) * 2.5));
@include padding-y('py-13', (($p-5 + $p-1) * 2.5));

@include padding('p-14', (($p-5 + $p-2) * 2));
@include padding-top('pt-14', (($p-5 + $p-2) * 2));
@include padding-bottom('pb-14', (($p-5 + $p-2) * 2));
@include padding-left('pl-14', (($p-5 + $p-2) * 2));
@include padding-right('pr-14', (($p-5 + $p-2) * 2));
@include padding-x('px-14', (($p-5 + $p-2) * 2));
@include padding-y('py-14', (($p-5 + $p-2) * 2));

@include padding('p-15', (($p-5 + $p-2) * 2.5));
@include padding-top('pt-15', (($p-5 + $p-2) * 2.5));
@include padding-bottom('pb-15', (($p-5 + $p-2) * 2.5));
@include padding-left('pl-15', (($p-5 + $p-2) * 2.5));
@include padding-right('pr-15', (($p-5 + $p-2) * 2.5));
@include padding-x('px-15', (($p-5 + $p-2) * 2.5));
@include padding-y('py-15', (($p-5 + $p-2) * 2.5));

@include padding('p-16', (($p-5 + $p-3) * 2));
@include padding-top('pt-16', (($p-5 + $p-3) * 2));
@include padding-bottom('pb-16', (($p-5 + $p-3) * 2));
@include padding-left('pl-16', (($p-5 + $p-3) * 2));
@include padding-right('pr-16', (($p-5 + $p-3) * 2));
@include padding-x('px-16', (($p-5 + $p-3) * 2));
@include padding-y('py-16', (($p-5 + $p-3) * 2));

@include margin('m-o', $p-o);
@include margin-top('mt-o', $p-o);
@include margin-bottom('mb-o', $p-o);
@include margin-left('ml-o', $p-o);
@include margin-right('mr-o', $p-o);
@include margin-x('mx-o', $p-o);
@include margin-y('my-o', $p-o);

@include margin('m-h', $p-h);
@include margin-top('mt-h', $p-h);
@include margin-bottom('mb-h', $p-h);
@include margin-left('ml-h', $p-h);
@include margin-right('mr-h', $p-h);
@include margin-x('mx-h', $p-h);
@include margin-y('my-h', $p-h);

@include margin('m-1', $p-1);
@include margin-top('mt-1', $p-1);
@include margin-bottom('mb-1', $p-1);
@include margin-left('ml-1', $p-1);
@include margin-right('mr-1', $p-1);
@include margin-x('mx-1', $p-1);
@include margin-y('my-1', $p-1);

@include margin('m-2', $p-2);
@include margin-top('mt-2', $p-2);
@include margin-bottom('mb-2', $p-2);
@include margin-left('ml-2', $p-2);
@include margin-right('mr-2', $p-2);
@include margin-x('mx-2', $p-2);
@include margin-y('my-2', $p-2);

@include margin('m-3', $p-3);
@include margin-top('mt-3', $p-3);
@include margin-bottom('mb-3', $p-3);
@include margin-left('ml-3', $p-3);
@include margin-right('mr-3', $p-3);
@include margin-x('mx-3', $p-3);
@include margin-y('my-3', $p-3);

@include margin('m-4', $p-4);
@include margin-top('mt-4', $p-4);
@include margin-bottom('mb-4', $p-4);
@include margin-left('ml-4', $p-4);
@include margin-right('mr-4', $p-4);
@include margin-x('mx-4', $p-4);
@include margin-y('my-4', $p-4);

@include margin('m-5', $p-5);
@include margin-top('mt-5', $p-5);
@include margin-bottom('mb-5', $p-5);
@include margin-left('ml-5', $p-5);
@include margin-right('mr-5', $p-5);
@include margin-x('mx-5', $p-5);
@include margin-y('my-5', $p-5);

@include margin('m-6', ($p-3 * 2));
@include margin-top('mt-6', ($p-3 * 2));
@include margin-bottom('mb-6', ($p-3 * 2));
@include margin-left('ml-6', ($p-3 * 2));
@include margin-right('mr-6', ($p-3 * 2));
@include margin-x('mx-6', ($p-3 * 2));
@include margin-y('my-6', ($p-3 * 2));

@include margin('m-7', ($p-3 * 2.5));
@include margin-top('mt-7', ($p-3 * 2.5));
@include margin-bottom('mb-7', ($p-3 * 2.5));
@include margin-left('ml-7', ($p-3 * 2.5));
@include margin-right('mr-7', ($p-3 * 2.5));
@include margin-x('mx-7', ($p-3 * 2.5));
@include margin-y('my-7', ($p-3 * 2.5));

@include margin('m-8', ($p-4 * 2));
@include margin-top('mt-8', ($p-4 * 2));
@include margin-bottom('mb-8', ($p-4 * 2));
@include margin-left('ml-8', ($p-4 * 2));
@include margin-right('mr-8', ($p-4 * 2));
@include margin-x('mx-8', ($p-4 * 2));
@include margin-y('my-8', ($p-4 * 2));

@include margin('m-9', ($p-4 * 2.5));
@include margin-top('mt-9', ($p-4 * 2.5));
@include margin-bottom('mb-9', ($p-4 * 2.5));
@include margin-left('ml-9', ($p-4 * 2.5));
@include margin-right('mr-9', ($p-4 * 2.5));
@include margin-x('mx-9', ($p-4 * 2.5));
@include margin-y('my-9', ($p-4 * 2.5));

@include margin('m-10', ($p-5 * 2));
@include margin-top('mt-10', ($p-5 * 2));
@include margin-bottom('mb-10', ($p-5 * 2));
@include margin-left('ml-10', ($p-5 * 2));
@include margin-right('mr-10', ($p-5 * 2));
@include margin-x('mx-10', ($p-5 * 2));
@include margin-y('my-10', ($p-5 * 2));

@include margin('m-11', ($p-5 * 2.5));
@include margin-top('mt-11', ($p-5 * 2.5));
@include margin-bottom('mb-11', ($p-5 * 2.5));
@include margin-left('ml-11', ($p-5 * 2.5));
@include margin-right('mr-11', ($p-5 * 2.5));
@include margin-x('mx-11', ($p-5 * 2.5));
@include margin-y('my-11', ($p-5 * 2.5));

@include margin('m-12', (($p-5 + $p-1) * 2));
@include margin-top('mt-12', (($p-5 + $p-1) * 2));
@include margin-bottom('mb-12', (($p-5 + $p-1) * 2));
@include margin-left('ml-12', (($p-5 + $p-1) * 2));
@include margin-right('mr-12', (($p-5 + $p-1) * 2));
@include margin-x('mx-12', (($p-5 + $p-1) * 2));
@include margin-y('my-12', (($p-5 + $p-1) * 2));

@include margin('m-13', (($p-5 + $p-1) * 2.5));
@include margin-top('mt-13', (($p-5 + $p-1) * 2.5));
@include margin-bottom('mb-13', (($p-5 + $p-1) * 2.5));
@include margin-left('ml-13', (($p-5 + $p-1) * 2.5));
@include margin-right('mr-13', (($p-5 + $p-1) * 2.5));
@include margin-x('mx-13', (($p-5 + $p-1) * 2.5));
@include margin-y('my-13', (($p-5 + $p-1) * 2.5));

@include margin('m-14', (($p-5 + $p-2) * 2));
@include margin-top('mt-14', (($p-5 + $p-2) * 2));
@include margin-bottom('mb-14', (($p-5 + $p-2) * 2));
@include margin-left('ml-14', (($p-5 + $p-2) * 2));
@include margin-right('mr-14', (($p-5 + $p-2) * 2));
@include margin-x('mx-14', (($p-5 + $p-2) * 2));
@include margin-y('my-14', (($p-5 + $p-2) * 2));

@include margin('m-15', (($p-5 + $p-2) * 2.5));
@include margin-top('mt-15', (($p-5 + $p-2) * 2.5));
@include margin-bottom('mb-15', (($p-5 + $p-2) * 2.5));
@include margin-left('ml-15', (($p-5 + $p-2) * 2.5));
@include margin-right('mr-15', (($p-5 + $p-2) * 2.5));
@include margin-x('mx-15', (($p-5 + $p-2) * 2.5));
@include margin-y('my-15', (($p-5 + $p-2) * 2.5));

@include margin('m-16', (($p-5 + $p-3) * 2));
@include margin-top('mt-16', (($p-5 + $p-3) * 2));
@include margin-bottom('mb-16', (($p-5 + $p-3) * 2));
@include margin-left('ml-16', (($p-5 + $p-3) * 2));
@include margin-right('mr-16', (($p-5 + $p-3) * 2));
@include margin-x('mx-16', (($p-5 + $p-3) * 2));
@include margin-y('my-16', (($p-5 + $p-3) * 2));


input[type="checkbox"],
input[type="radio"] {
    &:checked {
        &+.custom-control-label {
            color: $gray-700;
            font-weight: 500;
        }
    }
}

.line-height-0 {
    line-height: 0 !important;
}

.line-height {
    line-height: 1 !important;
}

.line-height-1 {
    line-height: 1.1 !important;
}

details {
    & summary {
        & .new-line {
            display: block;
            padding-left: 0.8rem;
        }
    }
}

.d-invisible {
    visibility: hidden !important;
}

.modal {
    z-index: -1;

    &.show {
        z-index: 1040;
    }
}

.modal-backdrop {
    z-index: -1;

    &.show {
        z-index: 1030;
    }
}

label {
    & small {
        display: inline-block;
        color: $gray-600;
        font-size: $font-size-xs;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: $p-o;
        margin-bottom: $p-1;
    }

    &.text-normal {
        & small {
            text-transform: none !important;
        }
    }

    &.required {
        &::after {
            display: inline-block;
            content: "*";
            color: $color-danger;
            font-weight: 700;
            margin-left: $p-h;
        }
    }
}

.subheader {
    & .subheader-newline {
        display: block;
    }
}

.nav {
    & .nav-item {
        & .nav-link {
            & .btn {
                &.btn-link {
                    color: $gray-600;

                    &:active,
                    &:focus,
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &.active {
                & .btn {
                    &.btn-link {
                        color: $gray-700;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.nav-tabs-scroll {
    width: 100%;
    overflow-x: hidden;

    &>.nav-tabs {
        display: -webkit-box;
        flex-wrap: nowrap;
    }
}

.modal {
    background-color: rgba($dark, .5);
}

.bootbox-modal-icon-newline {
    padding-left: 0 !important;
}

.dropdown-toggle {
    &.dropdown-toggle-no-icon {

        &:before,
        &:after {
            display: none !important;
        }
    }

    &+.dropdown-menu {

        &:hover,
        &:active {
            display: block;
        }

        & .dropdown-item {
            padding: $p-2 $p-3;
        }
    }

    &:active,
    &:focus {
        &+.dropdown-menu {
            display: block;

            & .dropdown-item {
                padding: $p-2 $p-3;
            }
        }
    }

    &+.dropdown-menu {
        &.dropdown-menu-inline {
            min-width: auto !important;

            &.dropdown-menu-rounded {
                border-radius: 30px;
            }
        }

        & .dropdown-item {
            &.dropdown-item-no-highlight {

                &:active,
                &:focus,
                &:hover {
                    background: none;
                    cursor: default;
                }
            }
        }
    }
}

.dropdown-menu {
    &.dropdown-menu-inline {
        min-width: auto !important;

        &.dropdown-menu-rounded {
            border-radius: 30px;
        }
    }

    & .dropdown-item {
        &.dropdown-item-no-highlight {

            &:active,
            &:focus,
            &:hover {
                background: none;
                cursor: default;
            }
        }
    }
}

.no-scroll {
    overflow: hidden !important;
}

.flashing {
    animation: flashing 1s linear infinite;

    &.slow {
        animation: flashing 1.5s linear infinite;
    }

    &.slower {
        animation: flashing 2s linear infinite;
    }

    &.slowest {
        animation: flashing 2.5s linear infinite;
    }

    &.fast {
        animation: flashing .75s linear infinite;
    }

    &.faster {
        animation: flashing .5s linear infinite;
    }

    &.fastest {
        animation: flashing .25s linear infinite;
    }

    &.flash-2x {
        animation-iteration-count: 2 !important;
    }

    &.flash-3x {
        animation-iteration-count: 3 !important;
    }

    &.flash-4x {
        animation-iteration-count: 4 !important;
    }

    &.flash-5x {
        animation-iteration-count: 5 !important;
    }
}

.animated {
    &.slow {
        animation-duration: 1.5s;
    }

    &.slower {
        animation-duration: 2s;
    }

    &.slowest {
        animation-duration: 2.5s;
    }

    &.fast {
        animation-duration: .75s;
    }

    &.faster {
        animation-duration: .5s;
    }

    &.fastest {
        animation-duration: .25s;
    }
}

.rotate-45 {
    transform: rotate(45deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.no-float {
    float: none !important;
}

.width-auto {
    width: auto !important;
}

.divider {
    border-bottom: 1px solid $gray-300;
    margin-top: $p-1;
    margin-bottom: $p-2;
}

.card {
    &.attachment {
        min-width: 150px;
        max-width: 300px;
        width: 100%;
        float: left;
        margin-right: $p-2;

        & .card-header,
        & .card-body,
        & .card-footer {
            overflow: hidden;
        }
    }
}

.autocomplete-wrapper {
    & .autocomplete-dropdown-container {
        position: relative;

        &.show {
            &>.dropdown-menu {
                margin: 0;

                &.dropdown-menu-animated {
                    @include transform(scale(1) !important);
                    @include transform-origin(29px -50px);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.align-self-start {
    align-self: flex-start;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: flex-end;
}

.tt-normal {
    text-transform: none !important;
}

.tt-uppercase {
    text-transform: uppercase !important;
}

.tt-lowercase {
    text-transform: lowercase !important;
}

.tt-capitalize {
    text-transform: capitalize !important;
}

.tt-italic {
    font-style: italic !important;
}

.tt-underline {
    text-decoration: underline !important;
}

.vh-100 {
    height: 100vh !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.thin-scroll-y {
    @include thin-scroll();
}

.validated {

    & input,
    & select,
    & .quill {
        &.invalid {
            border-color: $color-danger;
        }
    }

    & .custom-control-label {
        &.invalid {
            color: $color-danger;

            &::before {
                border-color: $color-danger;
            }
        }
    }
}

.note-filters-wrapper {
    position: relative;
    display: flex;
    background: $white;
    width: 100%;
    padding: $p-3 $p-4;
    padding-bottom: 0;
    padding-right: $p-3 * 3;
    height: $p-3 * 4;
    overflow-y: hidden;
    transition: height linear .15s;

    @include thin-scroll();

    & .btn-expand {
        position: absolute !important;
        font-size: $font-size-lg;
        padding: ($p-o / 2) $p-h;
        margin-top: $p-o * -1;
        right: $p-3 - .05;
    }

    &.expanded {
        height: $p-4 * 9;
        transition: height linear .15s;
    }

    &>.note-filters {
        list-style: none;
        margin: 0;
        padding: 0;

        &>li {
            display: inline-block;
            margin: 0;
            margin-right: $p-2;
            margin-bottom: $p-3;
            padding: 0;
        }
    }
}

.validate {
    &.has-error {
        & label {

            &[for],
            &.required {
                &>small {
                    color: $color-danger;
                }
            }

            &.has-error {
                color: $color-danger;

                &>small {
                    color: $color-danger !important;
                }
            }
        }

        & input[type=text],
        & input[type=number],
        & input[type=password],
        & input[type=email],
        & select {
            &.form-control {
                border-color: $color-danger;
            }
        }

        & .custom-control {

            &.custom-checkbox,
            &.custom-radio {
                & .custom-control-label {
                    &::before {
                        border-color: $color-danger;
                    }
                }
            }
        }

        & .rw-dropdown-list {
            & .rw-widget-input {
                border-color: $color-danger;
            }
        }

        & .rw-multiselect {
            & .rw-widget-input {
                border-color: $color-danger;
            }
        }

        & .rw-datetime-picker {
            & .rw-widget-container {
                border-color: $color-danger;
            }
        }

        & .input-group {

            & .input-group-prepend,
            & .input-group-append {
                & .input-group-text {
                    border-color: $color-danger;
                }
            }
        }
    }
}

.d-none-before {
    &::before {
        display: none;
    }
}

.d-none-after {
    &::after {
        display: none !important;
    }
}

.min-width-auto {
    min-width: auto;
}

.min-height-auto {
    min-height: auto;
}

.fade-100 {
    &>div {
        height: 100%;
    }
}

.icon-stack {
    & i {

        &.icon-stack-1x {
            &.icon-stack-bottom-right {
                left: 45% !important;
                top: 45% !important;
            }
        }
    }
}

@keyframes flashing {
    50% {
        opacity: 0;
    }
}