.genki {

    /* General input */
    & .dx-texteditor-container {
        & .dx-texteditor-input-container {
            & input[type=text] {
                height: calc(1.47em + 1rem + 2px);
                padding: $input-padding-y $input-padding-x;
                color: $gray-700;
            }
        }
    }

    & .dx-texteditor {
        &.dx-invalid {
            &.dx-show-invalid-badge {
                & .dx-texteditor-input-container {
                    &::after {
                        color: $color-danger !important;
                        font-size: $fs-xl !important;
                        background-color: transparent !important;
                    }
                }
            }
        }

        &.dx-editor-outlined {
            &.dx-state-focused {
                border-color: $color-primary;
            }
        }
    }

    /* Popup and overlay */
    & .dx-overlay-wrapper,
    & .dx-widget {
        font-family: $page-font;
        font-size: rem($fs-base);

        & input,
        textarea {
            font-family: $page-font;
            line-height: 1.5;
        }
    }

    & .dx-popup-wrapper {
        &>.dx-overlay-content {
            border-radius: $border-radius;
            box-shadow: 0px 0px 13px 0px rgba($gray-800, 28%);
        }
    }

    & .dx-popup-recenter {
        /* This is a custom button added for re-centering popup */
        position: fixed;
        bottom: $p-3;
        left: $p-3;
        background-color: $color-alt;
        color: $white;
        padding: $p-1 $p-2;
        border: 1px solid lighten($color-alt, 25%);
        border-radius: $border-radius;
        font-size: $p-3;
    }

    /**/

    /* Data Grid */
    & .dx-datagrid {
        background-color: transparent;

        & .dx-datagrid-headers {
            background-color: $gray-50;

            & .dx-datagrid-table {
                & tbody {
                    & tr {
                        &.dx-row {
                            &.dx-datagrid-filter-row {
                                & .dx-editor-container {
                                    & .dx-datebox {
                                        width: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.dx-state-focused {
                & .dx-header-row {
                    & td {
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        & .dx-column-indicators {
            float: right;
            margin-left: $p-2;
            margin-right: -12px;

            & .dx-sort-up,
            & .dx-sort-down {
                &::before {
                    color: $info-500;
                }
            }

            & .dx-header-filter {
                color: $info-500;

                &::before {
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 900;
                    content: "\f0b0";
                }

                &.dx-header-filter-empty {
                    color: $gray-500;

                    &::before {
                        font-weight: 300;
                    }
                }
            }
        }

        & .dx-datagrid-filter-row {
            background-color: $white;

            & .dx-editor-with-menu {
                & .dx-menu {
                    margin-left: 0;
                    margin-top: 0;

                    & .dx-menu-item {
                        & .dx-menu-item-content {
                            padding: 8px 3px 10px 3px;
                        }
                    }
                }

                & .dx-editor-container {
                    height: calc(1.47em + 1rem + 2px);
                    display: flex;
                    align-items: center;

                    & .dx-texteditor-container {
                        & .dx-texteditor-input-container {
                            & input[type=text] {
                                padding-left: 26px;
                            }
                        }
                    }

                    & .dx-datebox {
                        border: none !important;
                    }
                }
            }
        }

        & .dx-editor-cell {
            & .dx-htmleditor {
                border-radius: 0;

                &.dx-htmleditor-outlined {
                    &.dx-state-focused {
                        border: 2px solid #5c95c5;

                        & .ql-container {
                            & .ql-editor {
                                padding: 2px;
                            }
                        }
                    }
                }

                & .ql-container {
                    border-radius: 0;
                    border: 1px solid transparent !important;

                    & .ql-editor {
                        padding: 3px;
                        @include thin-scroll();
                    }
                }
            }
        }

        & .dx-pager {
            & .dx-page-sizes {
                margin-top: $p-2;

                & .dx-dropdowneditor {
                    display: inline-block;
                }

                & .dx-page-size {
                    border-color: $border-color;
                    border-radius: $border-radius;
                    padding: $p-2;
                    font-size: $font-size-base;
                    border: 1px solid transparent;
                    transition: background-color linear 0.15s;

                    &.dx-selection,
                    &:hover {
                        background-color: $color-alt;
                        color: $white;
                    }
                }

                &::before {
                    content: 'Showing:';
                    color: #666;
                    margin-right: $p-2;
                }

                &::after {
                    content: 'per page';
                    color: #666;
                    margin-left: $p-2;
                }
            }

            &.dx-light-mode {
                & .dx-page-sizes {
                    margin-top: $p-1;
                }
            }


            & .dx-pages {
                margin-top: $p-2;

                & .dx-page-indexes {
                    & .dx-light-pages {
                        margin-top: -2px;

                        & .dx-texteditor-container {
                            & .dx-texteditor-input {
                                font-size: $fs-sm;
                                height: auto;
                                text-align: center;
                            }
                        }
                    }

                    & .dx-page,
                    & .dx-navigate-button {
                        border-radius: $border-radius;
                        padding: $p-2;
                        font-size: $font-size-base;
                        border: 1px solid transparent;
                        transition: background-color linear 0.15s;

                        &.dx-selection,
                        &:hover {
                            background-color: $color-alt;
                            color: $white;
                        }
                    }

                    & .dx-page {
                        border-color: $border-color;
                        min-width: 30px;
                        text-align: center;
                    }

                    & .dx-next-button {
                        margin-left: $p-1;
                    }
                }
            }
        }
    }

    & .dx-header-filter-menu {
        & .dx-toolbar {
            & .dx-toolbar-items-container {
                & .dx-button {
                    border-radius: $border-radius;
                    width: auto;
                    line-height: 1.5rem;
                    padding: $p-o;
                    font-weight: 500;
                    font-size: 90%;
                    text-transform: uppercase;
                    transition: color linear .2s, background-color linear .2s;

                    &.dx-state-hover,
                    &.dx-state-focused,
                    &.dx-state-active {
                        background-color: $gray-200;
                    }

                    &[aria-label=OK] {
                        background-color: $color-primary;
                        color: $white;
                        min-width: 75px;

                        &.dx-state-hover,
                        &.dx-state-focused,
                        &.dx-state-active {
                            background-color: $primary-700;
                        }
                    }

                    &[aria-label=Cancel] {
                        border: none;
                        color: $gray-600;
                        background-color: transparent;
                        min-width: 75px;

                        &.dx-state-hover,
                        &.dx-state-focused,
                        &.dx-state-active {
                            color: $gray-500;
                            background-color: transparent;
                        }
                    }

                    &.dx-state-hover,
                    &.dx-state-focused,
                    &.dx-state-active {
                        transition: color linear .2s, background-color linear .2s;
                    }
                }
            }
        }

        &>.dx-dropdowneditor-overlay {
            &>.dx-popup-content {
                &>.dx-treeview {
                    & .dx-treeview-select-all-item {
                        & .dx-checkbox-text {
                            padding-left: 10px !important;
                        }
                    }
                }

                &>.dx-scrollview {
                    & .dx-scrollable-wrapper {
                        & .dx-list-select-all {
                            display: flex;
                            border-bottom: 1px solid #ddd;
                            padding: 6px 0 10px 15px;
                            margin: 0 6px 6px 0;

                            & .dx-checkbox {
                                margin-left: 0;
                            }
                        }

                        & .dx-item {
                            padding: 4px 6px;
                            padding-left: 10px;
                            min-height: 28px;

                            & .dx-list-item-content {
                                padding: 3px 5px 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    /**/

    /* HTML Editor */
    & .dx-htmleditor {
        width: 100%;
        border: 1px solid $input-border-color;
        border-radius: $border-radius;
        transition: box-shadow ease-in-out .15s, border-color ease-in-out .15s;

        &.dx-htmleditor-outlined {
            border-radius: $border-radius;

            &.dx-state-focused {
                transition: box-shadow ease-in-out .15s, border-color ease-in-out .15s;
                border-color: $input-focus-border-color;

                & .ql-container {
                    border-color: transparent !important;
                }
            }
        }

        & .ql-container {
            border-radius: $border-radius;
            border: 1px solid transparent !important;
            font-family: $page-font;
            font-size: $font-size-base;

            & .ql-editor {
                min-height: 85px;
                font-family: $page-font !important;
                font-size: $font-size-base !important;
                line-height: $line-height-base;
                background-color: $white;
                padding: $input-padding-y $input-padding-x;
                color: $gray-700;

                @include thin-scroll();

                &.ql-blank {
                    &::before {
                        font-style: normal;
                        color: $gray-600;
                        left: auto;
                        right: auto;
                    }
                }
            }
        }

        &.input-sm {
            & .ql-container {

                &.ql-snow,
                &.ql-bubble {
                    & .ql-editor {
                        height: auto;
                        min-height: calc(1.47em + 1rem + 2px);
                        max-height: calc((1.47em + 1rem + 2px) * 2);
                        font-size: $font-size-sm;
                    }
                }
            }

            &.quill-mobile {
                & .ql-container {
                    &.ql-snow {
                        & .ql-editor {
                            max-height: calc(1.47em + 1rem + 2px);
                        }
                    }
                }
            }
        }

        &.input-lg {
            & .ql-container {
                &.ql-snow {
                    & .ql-editor {
                        max-height: calc((1.47em + 1rem + 2px) * 3);
                    }
                }
            }

            &.quill-mobile {
                & .ql-container {
                    &.ql-snow {
                        & .ql-editor {
                            max-height: calc(1.47em + 1rem + 2px);
                        }
                    }
                }
            }
        }

        &.fs-sm {
            & .ql-container {
                &.ql-snow {
                    & .ql-editor {
                        font-size: $font-size-sm;
                        padding: $input-padding-y-sm $input-padding-x-sm;
                    }
                }
            }
        }

        &.dx-state-disabled {

            & .ql-container,
            & .ql-editor {
                background-color: #f3f3f3;
                color: #868e96;
                opacity: 1;
            }

            opacity: 1;
            border: 1px solid #e5e5e5;
        }
    }

    & .dx-popup-wrapper {
        &.dx-suggestion-list-wrapper {
            & .dx-overlay-content {
                border: none;

                & .dx-popup-content {
                    padding: 0;

                    & .dx-suggestion-list {
                        & .dx-list-item {
                            &.dx-state-focused {
                                background-color: $color-primary;
                            }
                        }
                    }
                }
            }
        }

        &.dx-dropdownlist-popup-wrapper.dx-selectbox-popup-wrapper {
            &>.dx-popup-normal {
                &>.dx-popup-content {
                    max-height: 180px !important;
                }
            }
        }
    }

    & .has-error {
        & .dx-htmleditor {
            &.dx-htmleditor-outlined {
                border-color: $color-danger;
            }
        }
    }

    /**/

    /** DateBox **/

    & .dx-datebox {
        border: 1px solid $input-border-color !important;
        border-radius: $border-radius !important;

        & .dx-dropdowneditor-input-wrapper {
            & .dx-texteditor-buttons-container {
                & .dx-dropdowneditor-button {
                    height: 34px;
                    min-height: 34px;
                    width: 34px;
                    min-width: 34px;
                    margin-right: 2px;

                    & .dx-dropdowneditor-icon {
                        width: 32px;
                        min-width: 32px;
                    }
                }
            }

            & .dx-placeholder {
                &::before {
                    padding: $input-padding-y $input-padding-x;
                }
            }
        }

        &.dx-editor-outlined {
            &.dx-state-hover {
                border-color: $input-border-color !important;
            }

            &.dx-state-focused {
                border-color: $input-focus-border-color !important;
            }
        }

        &.dx-state-disabled {
            background-color: #f3f3f3 !important;
            opacity: 1;

            & .dx-texteditor-container {
                & .dx-texteditor-input-container {
                    & .dx-texteditor-input {
                        color: $gray-700;
                    }
                }
            }
        }

        &.dx-datebox-sm {
            & .dx-dropdowneditor-input-wrapper {
                & .dx-texteditor-container {
                    & .dx-texteditor-input {
                        height: calc(1.5em + 0.75rem + 2px);
                        padding: 0.375rem 0.844rem;
                        font-size: 0.75rem;
                        line-height: 1.5;
                        border-radius: 2px;
                    }
                }

                & .dx-texteditor-buttons-container {
                    & .dx-dropdowneditor-button {
                        height: 26px;
                        min-height: 26px;
                        width: 26px;
                        min-width: 26px;
                        margin-right: 4px;

                        & .dx-dropdowneditor-icon {
                            width: 26px;
                            min-width: 26px;
                        }
                    }
                }

                & .dx-placeholder {
                    &::before {
                        padding: $input-padding-y $input-padding-x;
                    }
                }
            }
        }
    }

    & .dx-datebox-wrapper {
        &.dx-popup-wrapper {
            & .dx-popup-content {
                & .dx-calendar-navigator {
                    & .dx-button {
                        border: none;

                        & .dx-icon,
                        & .dx-button-text {
                            color: $gray-700;
                        }

                        & .dx-icon {
                            font-size: $font-size-sm;
                            font-weight: 700;
                        }
                    }

                    & .dx-calendar-caption-button {
                        text-transform: none;
                    }
                }

                & .dx-calendar {
                    height: 233.5px;
                    min-height: 233.5px;

                    & .dx-calendar-body {
                        & .dx-calendar-views-wrapper {
                            & .dx-widget {
                                & table {
                                    & thead {
                                        & tr {
                                            & th {
                                                color: $gray-700;
                                                font-weight: 500;
                                            }
                                        }
                                    }

                                    & tbody {
                                        & tr {
                                            & td {
                                                & span {
                                                    color: $gray-700;
                                                }
                                            }

                                            & td.dx-calendar-other-month {
                                                & span {
                                                    color: $gray-500;
                                                }
                                            }

                                            & td.dx-calendar-selected-date {
                                                box-shadow: inset 0 0 0 1px $gray-50, inset 0 0 0 1000px $color-primary;

                                                & span {
                                                    color: $white;
                                                }
                                            }

                                            & td.dx-calendar-empty-cell {
                                                background: none;
                                                cursor: not-allowed;

                                                & span {
                                                    color: $gray-300;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & .dx-timeview-clock {
                    & .dx-timeview-hourarrow {
                        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%2248%22%20viewBox%3D%220%200%206%2048%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%226%22%20height%3D%2244%22%20rx%3D%223%22%20fill%3D%22%235657a6%22%2F%3E%0A%3C%2Fsvg%3E%0A');
                    }

                    & .dx-timeview-minutearrow {
                        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2210%22%20height%3D%2266%22%20viewBox%3D%220%200%2010%2066%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%223%22%20width%3D%224%22%20height%3D%2264%22%20rx%3D%222%22%20fill%3D%22%235657a6%22%2F%3E%0A%3Ccircle%20cx%3D%225%22%20cy%3D%2261%22%20r%3D%223.5%22%20fill%3D%22%23fff%22%20stroke%3D%22%235657a6%22%20stroke-width%3D%223%22%2F%3E%0A%3C%2Fsvg%3E%0A');
                    }
                }

                & .dx-timeview-field {
                    & .dx-texteditor {
                        &.dx-editor-outlined {
                            & .dx-texteditor-container {
                                & .dx-texteditor-input-container {
                                    & input[type=text] {
                                        padding: 4px 5px;
                                        height: auto;
                                        font-size: 12px;
                                    }
                                }
                            }

                            &.dx-state-hover {
                                border-color: $input-border-color;
                            }

                            &.dx-state-focused {
                                border-color: $color-primary;
                            }
                        }
                    }
                }
            }

            & .dx-toolbar {
                & .dx-toolbar-items-container {
                    & .dx-button {
                        border-radius: $border-radius;
                        width: auto;
                        line-height: 1.5rem;
                        padding: $p-o;
                        font-weight: 500;
                        font-size: 90%;
                        text-transform: uppercase;
                        transition: color linear .2s, background-color linear .2s;

                        &.dx-state-hover,
                        &.dx-state-focused,
                        &.dx-state-active {
                            background-color: $gray-200;
                        }

                        &.dx-popup-done {
                            background-color: $color-primary;
                            color: $white;

                            &.dx-state-hover,
                            &.dx-state-focused,
                            &.dx-state-active {
                                background-color: $primary-700;
                            }
                        }

                        &.dx-popup-cancel {
                            border: none;
                            color: $gray-600;
                            background-color: transparent;

                            &.dx-state-hover,
                            &.dx-state-focused,
                            &.dx-state-active {
                                color: $gray-500;
                                background-color: transparent;
                            }
                        }

                        &.dx-state-hover,
                        &.dx-state-focused,
                        &.dx-state-active {
                            transition: color linear .2s, background-color linear .2s;
                        }
                    }
                }
            }
        }
    }

    /**/

    /** Tooltip **/

    & .dx-tooltip {
        display: inline;
    }

    & .dx-tooltip-wrapper {

        &.dx-popup-wrapper {
            &>.dx-overlay-content {
                border: 1px solid $gray-800;
                border-radius: $border-radius * 2;
                background: $gray-800;
                color: $white;

                & .dx-popover-arrow {
                    &::after {
                        border: 1px solid $gray-800;
                        background: $gray-800;
                    }
                }

                & .dx-popup-content {
                    padding: $p-2;
                }
            }
        }
    }

    /**/

    /** Context Menu **/

    & .dx-context-menu {
        display: inline;

        & .dx-submenu {
            & .dx-menu-items-container {
                & .dx-menu-item-wrapper {
                    & .dx-menu-item {
                        & .dx-menu-item-content {
                            padding: $p-2;

                            & .dx-menu-item-text {
                                font-family: $page-font;
                                font-size: $font-size-base;
                            }
                        }

                        &.dx-menu-item-has-icon {
                            & .dx-menu-item-content {
                                & .dx-menu-item-text {
                                    padding-left: $p-4;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**/
}

.dx-html {
    & p {
        margin-bottom: 0;
    }
}