@import '../../../styles/_modules/variables';

.update-appointment {
    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .control {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 10;

                    &.transparent {
                        & > .rw-datetime-picker {
                            & > .rw-widget-container {
                                opacity: 0;
                                border: none;
                            }
                        }
                    }

                    & .control-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    & .rw-widget.rw-state-focus {
                        & .rw-widget-picker {
                            border: none;
                            box-shadow: none;
                        }
                    }
                }

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                        transition: color linear .2s;
                        cursor: pointer;
                    }

                    & .date,
                    & .time {
                        display: block;
                        transition: color linear .2s;
                        cursor: pointer;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }

                    &:hover,
                    &:active,
                    &.active {
                        & .weekday {
                            color: $color-primary;
                            transition: color linear .2s;
                        }

                        & .date,
                        & .time {
                            color: $color-primary;
                            transition: color linear .2s;
                        }
                    }
                }
            }
        }
    }

    & .customer {
        & .profile-wrapper {
            display: flex;
            flex-direction: row;
            padding: $p-2;
            padding-left: 0;
            padding-top: 0;
            transition: background-color linear .2s;

            & .profile {
                margin-right: $p-2;
            }

            & .description {
                display: flex;
                flex-direction: column;
                justify-content: center;

                & .name {
                    font-size: $font-size-lg;
                    font-weight: normal;
                }

                & .info {
                    color: $gray-600;

                    & address {
                        margin-bottom: 0;

                        & span {
                            white-space: nowrap;

                            &:after {
                                content: ' ';
                            }

                            &.locality,
                            &.region {
                                &:after {
                                    content: ', ';
                                }
                            }

                            &.line1,
                            &.line2 {
                                display: block;
                            }

                            &.country {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    & .resource {
        & .rw-dropdown-list {
            & .rw-widget-container {
                padding-top: $p-1;
                padding-bottom: $p-1;
                
                & .rw-dropdown-list-input {
                    padding-left: $p-1;
                }
            }

            & .profile-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-right: $p-2;
                cursor: pointer;
                transition: background-color linear .2s;

                & .profile {
                    margin-right: $p-2;

                    & .profile-image {
                        width: $p-3 * 2;
                        height: $p-3 * 2;
                        margin-right: $p-2;
                    }
                }

                & .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: $font-size-lg;
                }
            }
        }
    }

    & .services {
        & .rw-widget {
            &.rw-multiselect {
                & .rw-widget-container {
                    &.rw-widget-input {
                        & div {
                            & .rw-multiselect-taglist {
                                & .rw-multiselect-tag {
                                    position: relative;
                                    padding: 0;
                                    border: none;

                                    &>* {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        padding: $p-h $p-2;
                                        color: $white;
                                    }

                                    &>.tag {
                                        padding-right: 25px;
                                        border: 1px solid transparent;
                                    }

                                    &>div {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        z-index: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}