@import '../../../styles/_modules/variables';
@import '../../../styles/_mixins/mixins';
@import '../../_shared/QuickDrawer';

.view-inventory-history-drawer {
    & .note-filters-wrapper {
        width: $quick-drawer-width;
        border-bottom: 1px solid $table-border-color;
        border-right: 1px solid $table-border-color;
        box-shadow: 0px 0px 5px 2px rgba($gray-300, .5);
    }

    & .view-inventory-history {
        & .d-flex {
            &.d-none {
                display: none !important;
            }
        }

        & .quantity {
            width: 70px;

            & .text-truncate {
                display: inline-block;
                max-width: 70px;
            }
        }

        & .profile-image {
            position: relative;

            & .badge {
                position: absolute;
                width: $p-2;
                height: $p-2;
                min-width: $p-2;
                min-height: $p-2;
                line-height: 1;
                right: 0;
                bottom: $p-o * -1;
            }
        }

        & hr {
            border-bottom-style: solid;

            &.new {
                position: relative;
                border-bottom: 1px solid $info-50;

                &:after {
                    position: absolute;
                    content: 'Recent';
                    font-size: $font-size-sm;
                    width: $p-2 * 8;
                    top: calc($font-size-sm / -1.5);
                    left: 50%;
                    margin-left: calc(($p-2 * 8) / -2);
                    background: $white;
                    text-align: center;
                    color: $info-200;
                }
            }
        }
    }
}