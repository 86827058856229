@import '../../styles/_modules/variables';
@import '../../styles/_mixins/mixins';

$quick-drawer-width: 25rem;

.quick-drawer-container {
    & .off-canvas {
        & .off-canvas-sidebar {
            background: rgba($black, .1);
            height: 100%;
            min-height: 100%;
            width: $quick-drawer-width;
            border-left: 1px solid $table-border-color;
            z-index: 505;
            overflow-x: hidden;

            & .quick-drawer-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba($black, .3);
                cursor: not-allowed;
            }

            & form {
                height: 100%;
                min-height: 100%;

                & fieldset {
                    height: 100%;
                    min-height: 100%;
                    margin-bottom: 0;

                    & .quick-drawer {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        min-height: 100%;
                    }
                }
            }

            & .quick-drawer-header {
                position: relative;
                display: flex;
                flex-direction: column;
                width: $quick-drawer-width;
                border-right: 1px solid $table-border-color;

                &>.header-content {
                    display: flex;
                    align-items: center;
                    padding: $p-4;
                    min-height: $p-4 * 3;
                    background-color: $page-bg;
                    border-bottom: 1px solid $table-border-color;

                    & i {
                        &.title-icon {
                            color: $gray-500;
                            font-size: $font-size-lg * 2;
                            position: absolute;
                            left: 0;
                            top: $p-h;
                        }

                        &.close-icon {
                            font-size: $font-size-lg;
                        }
                    }

                    & .title {
                        position: relative;
                        padding-left: $p-4 * 2;

                        & .subject {
                            padding: $p-h 0;

                            & .action {
                                color: $gray-600;
                                display: block;
                                font-size: $font-size-xs;
                                font-weight: 500;
                                line-height: 1.2;
                                text-transform: uppercase;
                            }

                            & .category {
                                color: $gray-700;
                                font-size: $font-size-lg;
                                font-weight: 500;
                                line-height: 1.2;
                            }
                        }
                    }

                    & .btn-close {
                        position: absolute;
                        right: $p-3;
                        font-size: $font-size-base;

                        &:active {
                            box-shadow: none;
                        }
                    }

                    &.delete {
                        background-color: $danger-800 !important;

                        & i {
                            color: $white !important;
                        }

                        & .subject {

                            & .action,
                            & .category {
                                color: $white !important;
                            }
                        }
                    }
                }
            }

            & .quick-drawer-body {
                display: flex;
                flex-direction: column;
                flex: 1;
                background: $white;
                width: $quick-drawer-width;
                overflow-y: auto;
                padding-left: $p-2;
                padding-right: $p-2;
                border-right: 1px solid $table-border-color;

                @include thin-scroll();

                & .body-content {
                    padding: $p-4;

                    & .row {

                        & .col-1,
                        & .col-2,
                        & .col-3,
                        & .col-4,
                        & .col-5,
                        & .col-6,
                        & .col-7,
                        & .col-8,
                        & .col-9,
                        & .col-10,
                        & .col-11,
                        & .col-12,
                        & .col,
                        & .col-auto,
                        & .col-sm-1,
                        & .col-sm-2,
                        & .col-sm-3,
                        & .col-sm-4,
                        & .col-sm-5,
                        & .col-sm-6,
                        & .col-sm-7,
                        & .col-sm-8,
                        & .col-sm-9,
                        & .col-sm-10,
                        & .col-sm-11,
                        & .col-sm-12,
                        & .col-sm,
                        & .col-sm-auto,
                        & .col-md-1,
                        & .col-md-2,
                        & .col-md-3,
                        & .col-md-4,
                        & .col-md-5,
                        & .col-md-6,
                        & .col-md-7,
                        & .col-md-8,
                        & .col-md-9,
                        & .col-md-10,
                        & .col-md-11,
                        & .col-md-12,
                        & .col-md,
                        & .col-md-auto,
                        & .col-lg-1,
                        & .col-lg-2,
                        & .col-lg-3,
                        & .col-lg-4,
                        & .col-lg-5,
                        & .col-lg-6,
                        & .col-lg-7,
                        & .col-lg-8,
                        & .col-lg-9,
                        & .col-lg-10,
                        & .col-lg-11,
                        & .col-lg-12,
                        & .col-lg,
                        & .col-lg-auto,
                        & .col-xl-1,
                        & .col-xl-2,
                        & .col-xl-3,
                        & .col-xl-4,
                        & .col-xl-5,
                        & .col-xl-6,
                        & .col-xl-7,
                        & .col-xl-8,
                        & .col-xl-9,
                        & .col-xl-10,
                        & .col-xl-11,
                        & .col-xl-12,
                        & .col-xl,
                        & .col-xl-auto {
                            padding-left: $p-1;
                            padding-right: $p-1;
                        }
                    }

                    & section {
                        margin-top: $p-4;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            & .quick-drawer-action {
                display: flex;
                flex-direction: column;
                width: $quick-drawer-width;
                padding: $p-3 $p-4;
                background-color: $white;
                border-top: 1px solid $table-border-color;
                border-right: 1px solid $table-border-color;
            }

            &.active {
                &~.off-canvas-overlay {
                    z-index: 410;
                }
            }
        }
    }

    &.drawer-2 {
        & .off-canvas {
            & .off-canvas-sidebar {
                padding-right: $quick-drawer-width + ($p-3);

                & .quick-drawer-overlay {
                    left: $quick-drawer-width + ($p-3);
                }
            }
        }
    }

    &.drawer-3 {
        & .off-canvas {
            & .off-canvas-sidebar {
                padding-right: $quick-drawer-width + ($p-3 * 2);
                margin-right: 1px;

                & .quick-drawer-overlay {
                    left: $quick-drawer-width + ($p-3 * 2);
                }
            }
        }
    }

    &.drawer-4 {
        & .off-canvas {
            & .off-canvas-sidebar {
                padding-right: $quick-drawer-width + ($p-3 * 3);
                margin-right: 2px;

                & .quick-drawer-overlay {
                    left: $quick-drawer-width + ($p-3 * 3);
                }
            }
        }
    }

    &.drawer-5 {
        & .off-canvas {
            & .off-canvas-sidebar {
                padding-right: $quick-drawer-width + ($p-3 * 4);
                margin-right: 3px;

                & .quick-drawer-overlay {
                    left: $quick-drawer-width + ($p-3 * 4);
                }
            }
        }
    }

    &.drawer-6 {
        & .off-canvas {
            & .off-canvas-sidebar {
                padding-right: $quick-drawer-width + ($p-3 * 5);
                margin-right: 4px;

                & .quick-drawer-overlay {
                    left: $quick-drawer-width + ($p-3 * 5);
                }
            }
        }
    }

    & ul.leaders {
        max-width: 40em;
        padding: 0;
        overflow-x: hidden;
        list-style: none;
        margin: 0;

        & li {
            padding: 0;
            padding-bottom: $p-2;

            &:after {
                float: left;
                width: 0;
                white-space: nowrap;
                content:
                    ". . . . . . . . . . . . . . . . . . . . "
                    ". . . . . . . . . . . . . . . . . . . . "
                    ". . . . . . . . . . . . . . . . . . . . "
                    ". . . . . . . . . . . . . . . . . . . . ";
            }

            & span {
                font-size: $font-size-sm;

                &:first-child {
                    font-weight: 500;
                    padding-right: $p-2;
                    background: $white;
                }

                &+span {
                    float: right;
                    padding-left: $p-2;
                    background: $white;
                    position: relative;
                    z-index: 1
                }
            }
        }
    }
}