.timeline {
    & .timeline-item {
        display: flex;
        margin-bottom: $p-3;
        position: relative;

        &::before {
            background: $gray-300;
            content: "";
            height: 100%;
            left: ($timeline-icon-size / 2) - ($p-h / 3);
            position: absolute;
            top: $timeline-icon-top;
            width: $p-h / 2;
            z-index: 0;
        }

        & .timeline-left {
            flex: 0 0 auto;
            z-index: 0;
        }

        & .timeline-icon {
            align-items: center;
            border-radius: 50%;
            color: $white;
            display: flex;
            height: $timeline-icon-size;
            justify-content: center;
            text-align: center;
            width: $timeline-icon-size;

            &:not(.has-icon) {
                &::before {
                    border: $p-o solid $color-primary;
                    border-radius: 50%;
                    content: "";
                    display: block;
                    height: $timeline-no-icon-size;
                    left: $timeline-no-icon-size * 1.35;
                    position: absolute;
                    top: $timeline-no-icon-size / 1.3;
                    width: $timeline-no-icon-size;
                }
            }

            &.has-icon {
                background: $color-primary;
                margin-top: $timeline-no-icon-size / -2;
            }
        }

        & .timeline-content {
            flex: 1 1 auto;
            padding: 0 0 $p-2 $p-3;
        }
    }
}