.tab-notification {
    height: 363px;

    .tab-pane {
        height: 100%;
    }
}


.notification {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;

    > li {
        position: relative;
        background: $white;

        &.unread {

            background: lighten($warning-50, 9%);

            .name {
                font-weight: 500;
            }

        }

        > :first-child {
            padding: $p-1+$p-2 $p-4;
            border-bottom: 1px solid rgba($black, 0.06);

            &:hover {
                text-decoration: none;
                background-image: linear-gradient(rgba(29, 33, 41, .03), rgba(29, 33, 41, .04));
            }

            &:focus {
                text-decoration: none;
            }

            >span {
                position: relative;

                >span {
                    /* IE fix */
                    display: block;
                }
            }

        }

        &:last-child {
            >a {
                border: 0;
            }
        }
    }

    .name {
        color: lighten($black, 13.5%);
        font-weight: 400;
        font-size: rem($fs-base);
    }

    .msg-a,
    .msg-b {
        color: lighten($black, 33.5%);
    }

    &.notification-layout-2 {
        & > li {
            background: $gray-50;

            &.unread {
                background: $white;

                .name {
                    font-weight: bold;
                }
            }

            > :first-child {
                position: relative;
                border-bottom: 1px solid rgba($black, 0.04);
                z-index: 1;

                &.active,
                &:hover {
                    background: transparent;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        //background: #ed1c24;
                        z-index: -1;
                        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
                    }
                }
            }

            .name {
                font-size: rem($fs-base + 1px);
            }
        }

        &:hover {
            cursor: pointer;
        }

        &.no-hover {
            li {
                > :first-child {
                    &.active,
                    &:hover {
                        &:after {
                            box-shadow: none;
                        }
                    }
                }

				&:hover {
					cursor: auto;
				}
            }
        }
    }


    &:not(.notification-loading) {

        &:before {
            content: "No new messages";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            padding: 1.5rem;
            width: 100%;
            display: block;
        }

    }

}