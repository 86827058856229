@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~react-tippy/dist/tippy.css';

@mixin event-color($name, $color) {
    &.event-#{$name} {
        border-color: $color;
        border-left-color: darken($color, 40%);
        background-color: lighten($color, 12%);

        &:hover {
            border-color: darken($color, 20%);
            border-left-color: darken($color, 50%);
            background-color: lighten($color, 15%);
            box-shadow: 0 0 0 $p-1 rgba($color, 0.2);
            transition: all linear .2s;
        }
    }
}

@mixin event-hour($hour) {
    &.event-hour#{$hour} {
        border-top: 1px solid $table-border-color;

        & td {
            padding-top: $p-2;
        }

        &~.event-hour#{$hour} {
            border-top: none;

            & td {
                padding-top: 0;

                &.event-hour {
                    visibility: hidden;
                }
            }
        }
    }
}

.fc {
    & .fc-toolbar.fc-header-toolbar {
        margin-bottom: $p-2;

        & .fc-left,
        & .fc-center,
        & .fc-right {
            flex: 1;

            & .fc-button {
                border-radius: $border-radius;
            }

            & .fc-button-group {
                &>.fc-button:not(:first-child) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &>.fc-button:not(:last-child) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            & .view-dropdown {
                & select {
                    &.custom-select {
                        padding-left: 0.8em !important;
                        padding-top: 0.5em !important;
                        padding-bottom: 0.4em !important;
                        font-size: 0.97em;
                    }
                }
            }
        }

        & .fc-center {
            text-align: center;
        }

        & .fc-right {
            text-align: right;
        }
    }

    & .fc-timeGrid-view {
        & th {
            &.fc-resource-cell {
                padding: $p-1 0;
                font-weight: 500;
            }
        }

        & .fc-bg {
            & td {
                &.fc-today {
                    background-color: lighten($primary-50, 33%);
                }
            }
        }

        & .fc-slats {
            & .fc-minor {
                & .fc-time {
                    border-color: transparent;
                }
            }

            & .fc-axis {
                font-size: $font-size-sm;
                font-weight: 500;
                padding-top: $p-1;
                padding-bottom: $p-1;
            }

            & tr:not([data-time$="00:00"]) {
                & .fc-axis {
                    color: $gray-500;
                    font-weight: normal;
                }
            }
        }
    }

    & .fc-body {
        & .fc-bg {
            & .fc-axis {
                color: transparent;
            }
        }

        & .fc-highlight-skeleton {
            & .fc-highlight {
                opacity: 0;
            }
        }

        & .fc-bgevent-skeleton {
            & .fc-bgevent {
                & strong {
                    display: block;
                    line-height: 1.3;
                    padding: $p-o;

                    & small {
                        display: block;
                    }

                    &.long-title {
                        display: block;
                    }

                    &.short-title {
                        display: none;
                    }
                }

                &.fc-workday,
                &.fc-timeoff,
                &.fc-not-set,
                &.fc-loading {
                    text-align: center;
                    vertical-align: middle;
                    height: 100%;
                    color: $white;
                    font-weight: 500;
                    opacity: 0.95;
                }

                &.fc-workday {
                    background: darken($info-800, 5%);
                }

                &.fc-not-set {
                    background: $gray-700;
                }

                &.fc-timeoff {
                    background: repeating-linear-gradient(-45deg,
                            $danger-800,
                            $danger-800 10px,
                            darken($danger-800, 5%) 10px,
                            darken($danger-800, 5%) 20px);
                }

                &.fc-loading {
                    background: $gray-50;
                }
            }
        }

        & .fc-content-skeleton {
            & .fc-bgevent {
                font-weight: 500;
                padding: $p-1 (
                    $p-1 + $p-o
                );
            border: 1px solid #dedede;
            border-left: none;
            border-right: none;

            & strong {
                display: block;
                line-height: 1.3;

                & small {
                    display: block;
                }

                &.long-title {
                    display: block;
                }

                &.short-title {
                    display: none;
                }
            }

            &.fc-workday,
            &.fc-not-set {
                opacity: 0;
            }

            &.fc-break {
                color: $gray-800;
                background: transparent;
                opacity: 1;

                &:after {
                    content: "";
                    border: 1px dashed #000;
                    background: repeating-linear-gradient(-45deg,
                            #d7d7d7,
                            #d7d7d7 10px,
                            darken(#d7d7d7, 10%) 10px,
                            darken(#d7d7d7, 10%) 20px);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.35;
                    z-index: -1;
                }
            }

            &.fc-timeoff {
                color: transparent;
                padding: $p-2;
                background: repeating-linear-gradient(-45deg,
                        #d7d7d7,
                        #d7d7d7 10px,
                        darken(#d7d7d7, 10%) 10px,
                        darken(#d7d7d7, 10%) 20px);
                opacity: 0.3;
            }

            &.fc-loading {
                color: transparent;
                background: transparent;
            }
        }

    }
}
}

.fc-ltr {
    & .fc-time-grid {
        & .fc-now-indicator-arrow {
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            border-radius: 100%;
            z-index: 100;
        }

        & .fc-now-indicator-line {
            border-color: #ff000f;
            border-top-width: 2px;
            margin-top: -1px;
            opacity: .5;
        }
    }
}

.fc {

    & .appointment-event,
    & .online-booking-event,
    & .timeslot-schedule-event,
    & .appointment-offer-event {
        background: transparent;
        border: none;
        border-radius: 0;
    }

    & .fc-resourceTimeGrid-view {
        & .timeline {
            position: absolute;
            list-style: none;
            padding: 0;
            margin: 0;
            top: -0.5rem;
            left: $p-1;
            z-index: 1;

            &>li {
                display: inline-block;
                padding: 0;
                margin: 0;
                margin-right: $p-h;
                line-height: 1;

                & .badge {
                    font-size: 9px;
                    font-weight: 500;
                    padding: $p-1 $p-1 $p-1 $p-1;
                    margin: 0;
                    line-height: 1;
                    height: $p-2;
                    width: $p-2;
                    border-radius: 100%;
                    cursor: default;
                }
            }

            &.not-selected {
                &>li {
                    &.badge[data-badge] {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        & .appointment-event,
        & .online-booking-event,
        & .timeslot-schedule-event,
        & .appointment-offer-event {
            background-color: #fff;
            border-radius: $border-radius;

            & .event-content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $white;
                border: 1.5px solid lighten($color-primary, 40%);
                border-radius: $border-radius + 2;
                transition: all linear .2s;
                padding: $p-1;
                cursor: pointer;
                overflow: hidden;

                &:hover {
                    transition: all linear .2s;
                    box-shadow: 0 0 0 3px rgba($gray-600, .3);
                }

                &.event-completed,
                &.event-noshow,
                &.event-warning {
                    opacity: .85;
                }

                &.event-noshow {
                    border-color: $danger-800 !important;
                }

                &.event-warning {
                    border-color: $warning-800 !important;
                }

                &.not-selected {
                    opacity: .5;
                }

                & .title {
                    display: flex;
                    font-weight: normal;
                    margin-right: $p-1;
                    margin-bottom: 0;
                }

                & .p-title {}

                & .p-time {
                    font-size: $font-size-sm;
                }

                & .p-notes {
                    font-size: $font-size-sm;
                    padding: $p-1 0;
                    padding-top: $p-1;

                    & p {
                        margin-bottom: $p-1;
                    }
                }

                &.online-booking-content {
                    color: $gray-800;
                    background: lighten($danger-50, 12%);
                    outline-width: 1px;
                    outline-offset: 0;
                    outline-color: rgba($danger-500, 0.75);
                    outline-style: solid;
                    animation: animateOutline 2s ease infinite;
                }

                &.appointment-offer-content {
                    color: $gray-800;
                    background-image: linear-gradient(135deg, white 25%, lighten($info-50, 15%) 25%, lighten($info-50, 15%) 50%, white 50%, white 75%, lighten($info-50, 15%) 75%);
                    background-size: 20px 20px;
                    background-position: 0 0;
                    border: 1px solid lighten($color-info, 5%);
                }
            }
        }
    }

    & .fc-resourceTimeGridWeek-view {

        & .appointment-event,
        & .online-booking-event,
        & .timeslot-schedule-event,
        & .appointment-offer-event {
            background-color: #fff;
            border-radius: $border-radius;

            & .event-content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $white;
                border: 1px solid lighten($color-primary, 40%);
                border-radius: $border-radius;
                transition: all linear .2s;
                padding: $p-1;
                cursor: pointer;
                overflow: hidden;

                &:hover {
                    transition: all linear .2s;
                    box-shadow: 0 0 0 3px rgba($gray-600, .3);
                }

                &.event-completed,
                &.event-noshow,
                &.event-warning {
                    opacity: .85;
                }

                &.event-noshow {
                    border-color: $danger-800 !important;
                }

                &.event-warning {
                    border-color: $warning-800 !important;
                }

                &.not-selected {
                    opacity: .5;
                }

                & .title {
                    display: flex;
                    font-weight: normal;
                    margin-right: $p-1;
                    margin-bottom: 0;
                }

                & .p-title {
                    display: flex;
                    margin-right: $p-1;
                    margin-bottom: 0;
                }

                & .p-time {
                    font-size: $font-size-sm;
                }

                & .p-notes {
                    font-size: $font-size-sm;
                    padding: $p-1 0;
                    padding-top: $p-1;

                    & p {
                        margin-bottom: $p-1;
                    }
                }

                &.timeslot-active {
                    color: $gray-800;
                    background-image: linear-gradient(135deg, white 25%, linen 25%, linen 50%, white 50%, white 75%, linen 75%);
                    background-size: 20px 20px;
                    background-position: 0 0;
                }

                &.online-booking-content {
                    color: $gray-800;
                    background: lighten($danger-50, 12%);
                    outline-width: 1px;
                    outline-offset: 0;
                    outline-color: rgba($danger-500, 0.75);
                    outline-style: solid;
                    animation: animateOutline 2s ease infinite;
                }

                &.appointment-offer-content {
                    color: $gray-800;
                    background-image: linear-gradient(135deg, white 25%, lighten($info-50, 15%) 25%, lighten($info-50, 15%) 50%, white 50%, white 75%, lighten($info-50, 15%) 75%);
                    background-size: 20px 20px;
                    background-position: 0 0;
                    border: 1px solid lighten($color-info, 5%);
                }
            }
        }
    }
}

.fc-resource-md,
.fc-resource-sm,
.fc-resource-xs {
    & .fc {
        & .fc-resourceTimeGridWeek-view {

            & .appointment-event,
            & .online-booking-event,
            & .timeslot-schedule-event,
            & .appointment-offer-event {
                & .event-content {

                    & .title,
                    & .p-time,
                    & .p-notes {
                        display: none;
                    }
                }
            }
        }
    }
}

@keyframes animateOutline {
    0% {
      outline-width: 3px;
      outline-offset: -5px;
      outline-color: rgba($danger-500, 0);
    }
  
    10% {
      outline-color: rgba($danger-500, 0.75);
    }
  
    /* The animation finishes at 50% */
    50% {
      outline-width: 5px;
      outline-offset: 0;
      outline-color: rgba($danger-500, 0);
    }
  
    100% {
      outline-width: 7px;
      outline-offset: 0;
      outline-color: rgba(102, 102, 102, 0);
    }
  }