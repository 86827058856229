@import '../../styles/_modules/variables.scss';

.loading-relative-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.loading-overlay {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($white, 0.5);
    z-index: 1002;

    & > .loading-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        & .spinner-border {
            margin: 0 auto;
        }

        & .loading-message {
            padding-top: $p-3;
            text-align: center;
        }
    }

    & > .h-spinner-container {
        overflow: hidden;
        width: 50%;
        max-width: 200px;
    
        & .spinner-horizontal {
            display: block;
            width: 100%;
            height: $p-o;
            margin: 0;
            background-color: $gray-300;
    
            & .spinner {
                width: 50%;
                height: 100%;
                margin-left: 0;
                background-color: $primary-200;
    
                animation-name: h-spinner;
                animation-duration: 1000ms;
                animation-timing-function: ease-in-out;
                animation-play-state: running;
                animation-direction: alternate-reverse;
                animation-iteration-count: infinite;
            }
        }
    }
}