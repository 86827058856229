@import '../../../styles/_modules/variables';

.migrate-paper-exam-container {
    & .resource {
        & .rw-dropdown-list {

            & .rw-widget-container {
                padding-top: $p-1;
                padding-bottom: $p-1;
                min-height: 42px;
                
                & .rw-dropdown-list-input {
                    padding-left: $p-1;
                }
            }

            & .profile-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-right: $p-2;
                cursor: pointer;
                transition: background-color linear .15s;

                & .profile {
                    margin-right: $p-2;

                    & .profile-image {
                        width: $p-3 * 2;
                        height: $p-3 * 2;
                        margin-right: $p-2;
                    }
                }

                & .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: $font-size-lg;
                }
            }
        }
    }
}