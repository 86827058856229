.react-contextmenu {
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    color: $gray-700;
    font-size: $font-size-sm;
    min-width: 200px;
    max-width: 250px;
    outline: none;
    opacity: 0;
    margin: 0;
    padding: $p-h 0;
    pointer-events: none;
    text-align: left;

    &.react-contextmenu--visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 9999;
    }

    & .react-contextmenu-item {
        background: 0 0;
        border: 0;
        color: $gray-700;
        cursor: pointer;
        font-weight: 400;
        line-height: 1;
        padding: ($p-2 + $p-h);
        text-align: inherit;
        white-space: nowrap;

        &>a {
            display: block;
            width: 100%;
            color: $gray-700;
            margin-bottom: 0;
        }

        &.react-contextmenu-item--selected,
        &.react-contextmenu-item--active {
            color: $gray-700;
            background-color: $gray-300;
            border-color: $gray-700;
            text-decoration: none;
        }

        &.react-contextmenu-item--disabled,
        &.react-contextmenu-item--disabled:hover {
            background-color: transparent;
            color: $gray-500;
            cursor: not-allowed;
        }

        &.react-contextmenu-submenu {
            padding: 0;

            &>.react-contextmenu-item:after {
                content: "▸";
                display: inline-block;
                position: absolute;
                right: ($p-2+$p-h);
            }
        }

        &.title {
            cursor: default;

            &.react-contextmenu-item--selected,
            &.react-contextmenu-item--active {
                color: $gray-700;
                background-color: $white;
                border-color: $white;
                text-decoration: none;
            }
        }
    }

    & .react-contextmenu-item--divider {
        border-bottom: 1px $border-color solid;
        cursor: inherit;
        margin-bottom: $p-1;
        padding: 0;
    }
}