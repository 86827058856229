@import '../../../styles/_modules/variables';

.new-product-type {
    & .extra-data {
        & ul {
            & li {
                &.data {
                    width: 230px;
                }
            }
        }
    }
}