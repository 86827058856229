@import '../../../../../styles/_modules/variables';


.update-prescription-control {
    & .options {
        & ul {
            & li {
                font-size: $font-size-sm;

                &.option {
                    width: 230px;
                }

                &.option-xs {
                    width: 80px;
                }

                &.option-sm {
                    width: 140px;
                }

                &.option-md {
                    width: 200px;
                }

                &.option-lg {
                    width: 280px;
                }

                &.option-xs,
                &.option-sm,
                &.option-md,
                &.option-lg {
                    & .react-mentions-wrapper {
                        top: $p-2 + $p-h;

                        & .react-mentions-wrapper__highlighter,
                        & .react-mentions-wrapper__input {
                            height: calc(1.5em + 0.75rem + 2px);
                            padding: 0.375rem 0.844rem;
                            font-size: 0.75rem;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}