@import '../../../styles/_modules/variables';

.update-customer-address-container {   
    & address {
        margin-top: $p-2 * -1;
        
        & .line1,
        & .line2,
        & .line3,
        & .line4 {
            display: inline-block;
            margin-right: $p-1;
        }
    } 
}