@import '../../styles/_modules/variables.scss';
@import '../../styles/_mixins/mixins.scss';
@import '../_shared/QuickDrawer';

.correspondence-attachments {
    & .view-note {
        opacity: 0;
        overflow: hidden;
        transition: opacity linear .15s;

        &.rendered {
            opacity: 1;
            overflow: auto;
            transition: opacity linear .15s;
        }

        & .d-flex {
            &.d-none {
                display: none !important;
            }
        }

        & .profile-image {
            position: relative;

            & .badge {
                position: absolute;
                width: $p-2;
                height: $p-2;
                min-width: $p-2;
                min-height: $p-2;
                line-height: 1;
                right: 0;
                bottom: $p-o * -1;
            }
        }

        & hr {
            border-bottom-style: solid;

            &.new {
                position: relative;
                border-bottom: 1px solid $info-50;

                &:after {
                    position: absolute;
                    content: 'Recent';
                    font-size: $font-size-sm;
                    width: $p-2 * 8;
                    top: calc($font-size-sm / -1.5);
                    left: 50%;
                    margin-left: calc(($p-2 * 8) / -2);
                    background: $white;
                    text-align: center;
                    color: $info-200;
                }
            }
        }

        & .attachment {
            & .attachment-extension {
                display: inline-block;
                color: $gray-800;
                font-weight: 500;
                border-radius: 0;
                border-top: none;
                border-left: none;
                border-right: none;
                padding: 0;
                height: auto;
                min-width: 15px;
                width: fit-content;
            }

            & .attachment-filename {
                &.quill,
                &.dx-htmleditor {
                    display: inline-block;
                    border-radius: 0;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    padding: 0;
                    height: 19px;
                    min-width: 15px;
                    max-width: 180px;
                    width: fit-content;
                    overflow-x: auto;
                    overflow-y: hidden;

                    & .ql-container {
                        border: none !important;
                        padding: 0;
                        height: auto;
                        min-width: 15px;
                        max-width: 180px;
                        width: fit-content;
                        overflow-x: auto;

                        & .ql-editor {
                            padding: 0;
                            height: 19px;
                            min-width: 15px;
                            max-width: 180px;
                            width: fit-content;
                            overflow-x: hidden;
                            overflow-y: hidden;
                            white-space: nowrap;

                            & p {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    & .quick-drawer-action {
        padding: 0 !important;

        & .dropzone {
            outline: none;
            height: 180px;

            & .border {
                transition: border linear .15s;
            }
        }

        & .actions {
            padding: $p-3 ($p-3 * 2);
        }
    }
}

// @import '../../../styles/variables';

// .new-note {
//     & .notes-timeline {
//         & .timeline-item {
//             & .timeline-content {
//                 & .tile {
//                     & .preview {
//                         display: block;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         width: 395px;
//                     }
//                 }
//             }
//         }
//     }

//     & .attachments {
//         height: 100%;
//         border: 1px solid $border-color-dark;
//         border-left: none;

//         & ul.attachment-list {
//             list-style: none;
//             margin: 0;
//             padding: 0;
//             height: 162px;
//             overflow-x: auto;

//             & li {
//                 position: relative;
//                 margin: 0;
//                 padding: $unit-2;
//                 border-bottom: 1px solid $border-color-dark;

//                 &>.name {
//                     display: block;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                     font-size: $font-size-sm;
//                     font-weight: 500;
//                     width: 100px;
//                 }

//                 &>.size {
//                     color: $gray-color-light;
//                     font-size: $font-size-xs;
//                 }

//                 &>.actions {
//                     position: absolute;
//                     right: $unit-2;
//                     bottom: $unit-2;
//                     display: none;

//                     &>.btn {
//                         padding: 0;
//                         height: auto;
//                         line-height: 1;
//                         margin-left: $unit-2;

//                         &>i {
//                             font-size: 1rem;

//                             &.inactive {
//                                 display: block;
//                             }

//                             &.active {
//                                 display: none;
//                             }
//                         }

//                         &:hover,
//                         &:active,
//                         &:focus,
//                         &.active {
//                             &>i {
//                                 &.inactive {
//                                     display: none;
//                                 }

//                                 &.active {
//                                     display: block;
//                                 }
//                             }
//                         }

//                         &.btn-delete {
//                             color: $error-color;

//                             &:hover,
//                             &:active,
//                             &:focus {
//                                 color: lighten($error-color, 5%);
//                             }
//                         }
//                     }
//                 }

//                 &:hover,
//                 &:active,
//                 &:focus {
//                     &>.actions {
//                         display: block;
//                     }
//                 }
//             }
//         }
//     }
// }