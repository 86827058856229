@import '../../../../styles/_modules/variables';

.new-business-day {
    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                    }

                    & .date,
                    & .time {
                        display: block;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }
                }
            }
        }
    }

    & .business-hours {
        & ul {
            & li {
                font-size: $font-size-sm;

                &.time {
                    width: 115px;

                    & .rw-datetime-picker {
                        & .rw-widget-container {
                            & .rw-widget-input.rw-input {
                                text-transform: lowercase;
                                font-size: $font-size-sm;
                            }
                        }
                    }
                }
            }
        }
    }
}