@import '../../../../../styles/_modules/variables';

.update-prescription-text {
    & .fields {
        & ul {
            & li.list-inline-item {
                display: inline-flex;

                &.field {
                    width: 230px;
                    flex: 1;

                    & .react-mentions-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// .update-invoice-text {
//     & .options {
//         & ul {
//             & li {
//                 font-size: $font-size-sm;

//                 &.option {
//                     width: 230px;
//                 }
//             }
//         }
//     }
// }

// .new-patient {
//     & .resource-dropdown {
//         width: 100%;

//         & .btn {
//             display: flex;
//             align-items: center;
//             position: relative;
//             width: 100%;
//             height: auto;
//             text-align: left;
//             padding-left: 0;

//             & .description {
//                 margin-left: $unit-4;
//                 color: $gray-color-dark;
//                 font-size: $font-size-xl;
//             }

//             & i {
//                 position: absolute;
//                 right: $unit-4;
//             }
//         }

//         & .menu {
//             width: 100%;

//             & .menu-item {
//                 & a {
//                     display: flex;
//                     align-items: center;

//                     & .description {
//                         margin-left: $unit-4;
//                         color: $gray-color;
//                     }

//                     &:hover,
//                     &:focus,
//                     &:active {
//                         background-color: transparent;

//                         & .description {
//                             color: $primary-color;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }