@import '../../../styles/_modules/variables';

.add-external-appointment-container {
    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .control {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 10;

                    &.transparent {
                        &>.rw-datetime-picker {
                            &>.rw-widget-container {
                                opacity: 0;
                                border: none;
                            }
                        }
                    }

                    & .control-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    & .rw-widget.rw-state-focus {
                        & .rw-widget-picker {
                            border: none;
                            box-shadow: none;
                        }
                    }
                }

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                        transition: color linear .15s;
                        cursor: pointer;
                    }

                    & .date,
                    & .time {
                        display: block;
                        transition: color linear .15s;
                        cursor: pointer;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }

                    &:hover,
                    &:active,
                    &.active {
                        & .weekday {
                            color: $color-primary;
                            transition: color linear .15s;
                        }

                        & .date,
                        & .time {
                            color: $color-primary;
                            transition: color linear .15s;
                        }
                    }
                }
            }
        }
    }

    & .external-clinic {
        & .dropdown {
            width: 100%;

            &>.external-clinic-status {
                position: absolute;
                top: $input-padding-y + .1;
                right: $input-padding-x;
                color: $color-success;
                background: $white;
                padding-left: $p-2;
                font-size: $font-size-lg;
                opacity: 0;
                transition: opacity linear .15s;

                &.external-clinic-status-selected {
                    opacity: 1;
                    transition: opacity linear .15s;
                }
            }

            &>.dropdown-menu {
                width: 100%;
                margin-top: -3px;
                padding: 0;

                & .dropdown-menu-item {
                    transition: background-color linear .15s;
                    padding: 0 !important;

                    &.active {
                        background-color: rgba($color-primary, .1) !important;
                        transition: background-color linear .15s;
                    }

                    & .profile-wrapper {
                        display: flex;
                        flex-direction: row;
                        padding: $p-2;
                        cursor: pointer;
                        transition: background-color linear .15s;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: rgba($gray-300, .5) !important;
                            transition: background-color linear .15s;
                        }

                        & .profile {
                            margin-right: $p-2;
                        }

                        & .description {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            & .name,
                            & .info {
                                font-size: $font-size-sm;

                                &.not-ready {
                                    background-color: $gray-300;
                                    height: 10px;
                                }
                            }

                            & .name {
                                color: $gray-700;
                                font-weight: normal;
                            }

                            & .info {
                                color: $gray-600;

                                &.not-ready {
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}