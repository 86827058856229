@import '../../../styles/_modules/variables.scss';
@import '../../../styles/_mixins/mixins.scss';
@import '../../_shared/QuickDrawer.scss';

.update-customer-container {
    &.quick-drawer {
        & .customer-info-nav {
            display: flex;
            flex-direction: row;
            background-color: $white;
            width: $quick-drawer-width;
            border-top: 1px solid $table-border-color;
            border-right: 1px solid $table-border-color;

            & .btn {
                padding: 0;
                flex: 1;
                border-radius: 0;
                border-right: 1px solid $table-border-color;

                &.btn-icon {
                    width: auto;
                    height: 60px;
                    line-height: 1;

                    & i {
                        font-size: 1.2rem;
                    }
                }

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}