@import '../../styles/_modules/variables';

.switch-pin-screen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    z-index: 10100;

    &>form {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1 0;
        align-items: center;
        justify-content: center;

        & fieldset {
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            padding: 0;

            & .icon-wrapper {
                margin-bottom: $p-4;
                text-align: center;

                &>i {
                    font-size: 3.8rem;
                    color: $white;
                }
            }

            &>.input-group {
                position: relative;

                &>.form-control {
                    text-align: center;
                    width: 300px;
                    left: 0;
                    font-size: $font-size-lg;
                    line-height: 1;
                    border-radius: $border-radius;
                }

                &>div {
                    &.mask-input {
                        font-size: 2.6rem;
                        padding: 0;
                        margin: 0;
                        line-height: 1;
                        position: absolute;
                        text-align: center;
                        background-color: $white;
                        top: 1px;
                        left: 1px;
                        bottom: 1px;
                        right: 1px;
                        z-index: 5;

                        &.loading {
                            color: $gray-500;
                            background-color: $gray-100;
                        }
                    }
                }
            }
        }
    }
}