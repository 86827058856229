.react-grid-layout {
    min-height: 100%;

    & .element-input {
        min-height: 60px;

        &.element-input-sm {
            min-height: 50px;
        }

        &.element-input-link {
            min-height: 40px;
        }

        &.no-label {
            min-height: 40px;

            &.element-input-sm {
                min-height: 30px;
            }

            &.element-input-link {
                min-height: 20px;
            }
        }

        & .form-group {
            &.no-label {
                & .control-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

    & .react-grid-item {
        padding-left: $p-1;
        padding-right: $p-1;

        &.react-draggable {
            & .react-resizable-handle {
                display: none;
            }

            // & .btn-close,
            // & .react-resizable-handle {
            //     opacity: 0;
            //     transition: opacity linear .15s;
            // }

            & .btn-close {
                color: $color-danger;
            }

            // &:hover {

            //     & .btn-close,
            //     & .react-resizable-handle {
            //         opacity: 1;
            //         transition: opacity linear .15s;
            //     }
            // }
        }

        &.react-grid-placeholder {
            background-color: $color-info;
        }

        &.react-grid-placeholder[style*="height: 10px"] {
            background-color: transparent;
        }

        & .btn-close {
            top: -5px;
        }

        & .react-resizable-handle {
            bottom: -1px;
            z-index: 12;
            background-image: none;
            width: 10px;
            height: 10px;

            &:after {
                background-color: $color-primary;
                border: none;
                width: 6px;
                height: 6px;
                border-radius: 100%;
            }

            &.react-resizable-handle-n,
            &.react-resizable-handle-s {
                cursor: ns-resize !important
            }

            &.react-resizable-handle-w,
            &.react-resizable-handle-e {
                cursor: ew-resize !important;
            }

            &.react-resizable-handle-sw,
            &.react-resizable-handle-ne {
                cursor: nesw-resize !important;
            }

            &.react-resizable-handle-nw,
            &.react-resizable-handle-se {
                cursor: nwse-resize !important;
            }

            &.react-resizable-handle-n,
            &.react-resizable-handle-s,
            &.react-resizable-handle-w,
            &.react-resizable-handle-e,
            &.react-resizable-handle-sw,
            &.react-resizable-handle-ne,
            &.react-resizable-handle-nw,
            &.react-resizable-handle-se {
                transform: none;
            }

            &.react-resizable-handle-n,
            &.react-resizable-handle-s {
                margin-left: -3px;
            }

            &.react-resizable-handle-n,
            &.react-resizable-handle-ne,
            &.react-resizable-handle-nw {
                &:after {
                    top: -3px;
                }
            }

            &.react-resizable-handle-ne {
                &:after {
                    right: -3px;
                }
            }

            &.react-resizable-handle-nw {
                &:after {
                    left: -3px;
                }
            }

            &.react-resizable-handle-s,
            &.react-resizable-handle-se,
            &.react-resizable-handle-sw {
                &:after {
                    bottom: -3px;
                }
            }

            &.react-resizable-handle-se {
                &:after {
                    right: -3px;
                }
            }

            &.react-resizable-handle-sw {
                &:after {
                    left: -3px;
                }
            }

            &.react-resizable-handle-e,
            &.react-resizable-handle-w {
                margin-top: -3px;
            }

            &.react-resizable-handle-e {
                &:after {
                    right: -3px;
                }
            }

            &.react-resizable-handle-w {
                &:after {
                    left: -3px;
                }
            }
        }

        &.resize-mode {
            & .editing-overlay {
                border: 1px dotted $gray-500;
            }

            & .react-resizable-handle {
                display: block !important;
            }
        }

        &.delete-mode {
            & .btn-close {
                display: block !important;
            }

            & .react-resizable-handle {
                display: none;
            }
        }

        &.multi-select-mode,
        &.multi-select-mode-1st {
            border: 2px dashed gray;

            & .btn-close {
                display: none;
            }

            & .react-resizable-handle {
                display: none;
            }
        }

        &.multi-select-mode-1st {
            border: 3px dashed #2196f3 !important;

            & .btn-close {
                display: none;
            }

            & .react-resizable-handle {
                display: none;
            }
        }

        & .editing-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            z-index: 11;

            &:hover,
            &:active,
            &:focus {
                border: 1px dotted $gray-500;
            }
        }

        & .form-group {
            position: relative;
            height: 100%;
            padding-top: 15px;
            margin-bottom: 0;

            &>label {
                position: absolute;
                height: 15px;
                top: -5px;
                left: 0;
                right: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &>small {
                    margin-top: $p-o;
                    color: $gray-500;
                }

                &.label-text-input {
                    &.label-text-input-sm {

                        &>small {
                            margin-top: $p-h;
                            font-size: $font-size-xs - .05;

                            &>input[type="text"] {
                                font-size: 90%;
                            }
                        }
                    }

                    &.label-text-input-lg {
                        height: 20px;

                        &>small {
                            margin-top: $p-o * -1;
                            font-size: $font-size-xs + .2;
                        }
                    }
                }
            }

            & h1,
            h2,
            h3,
            h4,
            h5,
            .html {
                position: absolute;
                top: -5px;
                left: 0;
                right: 0;
            }

            & .control-wrapper {
                // position: absolute;
                // top: 15px;
                // left: 0;
                // right: 0;
                height: 100%;
                bottom: $p-1;

                & textarea {
                    height: 100%;
                    resize: none;
                }

                & .quill {
                    position: relative;
                    padding-top: 42px;
                    height: 100%;

                    & .ql-toolbar {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        z-index: 1;
                    }

                    & .ql-container {
                        height: 100%;

                        & .ql-editor {
                            height: 100%;
                            padding-left: $p-2;
                            padding-right: $p-2;
                        }
                    }

                    &.quill-no-toolbar {
                        padding-top: 0;
                    }
                }

                & .form-control {
                    padding-left: $p-2;
                    padding-right: $p-2;
                    border-radius: $border-radius;
                }

                &.enable-scroll {
                    overflow: auto;
                }

                &.enable-scroll-x {
                    overflow-x: auto;
                }

                &.enable-scroll-y {
                    overflow-y: auto;
                }

                &.disable-scroll {
                    overflow: hidden;
                }
            }

            &.no-label {
                padding-top: 0;

                & .control-wrapper {
                    top: 0;
                }
            }

            & .checkbox-wrapper,
            & .radio-wrapper {
                padding-top: $p-1;
            }

            & .circle-wrapper {
                height: 100%;
                width: 100%;

                & .circle-input {
                    height: 100%;
                    width: 100%;

                    &.selected {
                        border-radius: 100%;
                    }
                }
            }

            & .check-wrapper {
                height: 100%;
                width: 100%;

                & .check-input {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.text-top,
            &.text-middle,
            &.text-bottom {
                display: flex;
                flex-direction: column;
                padding: 0;

                &>label,
                & h1,
                h2,
                h3,
                h4,
                h5,
                .html {
                    position: inherit;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                }
            }

            &.text-top {
                justify-content: flex-start;
            }

            &.text-middle {
                justify-content: center;
            }

            &.text-bottom {
                justify-content: flex-end;
            }
        }

        &.react-draggable,
        &.react-resizable {
            & .form-group {
                & .control-wrapper {
                    & .image-background {
                        z-index: -10;
                    }
                }
            }
        }
    }
}

.form-template {
    & .react-grid-layout {
        & .react-grid-item {
            padding-left: 0;
            padding-right: 0;
        }

        & .form-group {
            padding-top: 20px;

            &>label {
                font-family: Arial, Helvetica, sans-serif;
                top: 0 !important;
                line-height: 1.2;
                height: 20px !important;

                &>small {
                    text-transform: none;
                    font-size: 13px;
                    font-weight: normal;
                    color: $gray-600 !important;
                }
            }

            & input,
            & select,
            & .custom-control-label,
            & .ql-container,
            & .ql-container .ql-editor {
                font-family: Arial, Helvetica, sans-serif;
                color: $gray-800 !important;
            }

            & .ql-container .ql-editor {
                max-height: none;
            }
        }

        & .element-input {
            min-height: 60px;

            &.element-input-sm {
                min-height: 50px;
            }

            &.element-input-link {
                min-height: 40px;
            }

            &.no-label {
                min-height: 40px;

                &.element-input-sm {
                    min-height: 30px;
                }

                &.element-input-link {
                    min-height: 20px;
                }
            }

            & .form-group {
                &.no-label {
                    & .control-wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }

        & .print-element {
            & p {
                margin-bottom: 0;
            }

            & strong {
                font-weight: bold;
            }
        }
    }

    &.form-mobile {
        & .react-grid-layout {
            height: auto !important;

            & .custom-control-inline {
                display: block;
                margin-bottom: $p-1;

                & .custom-control-label {
                    width: 100%;
                }
            }

            & .react-grid-item {
                position: initial !important;
                height: auto !important;
                transform: none !important;
                padding: $p-2 0;

                & .form-group {
                    padding-top: 0 !important;

                    &.text-middle,
                    &.text-bottom {
                        // justify-content: flex-start;
                    }

                    & > label {
                        position: relative !important;
                        margin-bottom: 0 !important;
                        height: auto !important;
                        overflow: auto !important;
                        text-overflow: initial !important;
                        white-space: normal !important;
                    }
                }
            }
        }
    }

    &.form-template-internal {
        & .react-grid-layout {
            & .form-group {
                &>label {
                    font-family: $page-font;
                }

                & input,
                & select,
                & .custom-control-label,
                & .ql-container,
                & .ql-container .ql-editor {
                    font-family: $page-font;
                }
            }
        }
    }

    &.internal-form-template,
    &.referral-template {
        & .react-grid-layout {
            & .element-input {
                &.no-label {
                    min-height: 15px;

                    & .control-wrapper {
                        & .form-control {
                            padding: $p-o;
                            background-color: rgba($info-200, $alpha: 0.2);
                        }
                    }

                    & .circle-wrapper {
                        background-color: rgba($info-200, $alpha: 0.2);
                    }

                    & .check-wrapper {
                        background-color: rgba($info-200, $alpha: 0.2);
                    }
                }
            }
        }
    }
}