@import '../../../styles/_modules/variables';
@import '../../../styles/_mixins/mixins';

.referrals-container {
    &>.body-content {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;
        align-items: stretch;

        &>.referrals {
            flex: 1 1 auto;
            overflow-y: auto;
            padding: $p-4;
            padding-bottom: 0;
            @include thin-scroll();

            & .referral-wrapper
            {
                & .descriptor {
                    transition: color .2s linear;
                }

                &:hover {
                    cursor: pointer;
    
                    & .descriptor {
                        color: $color-info !important;
                        transition: color .2s linear;
                    }
                }
            }
        }
    }
}