.off-canvas {
    display: flex;
    flex-flow: nowrap;
    height: 100%;
    position: relative;
    width: 100%;

    & .off-canvas-overlay {
        background: rgba($dark, .5);
        border-color: transparent;
        border-radius: 0;
        bottom: 0;
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
    }

    & .off-canvas-sidebar {
        background: $white;
        bottom: 0;
        left: 0;
        min-width: $off-canvas-md-width;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        transition: transform .2s;
        width: $off-canvas-md-width;
        z-index: 500;

        &.active,
        &:target {
            transform: translateX(0);
            box-shadow: $off-canvas-shadow;

            &~.off-canvas-overlay {
                display: block;
                z-index: 100;
            }

            &.no-shadow {
                box-shadow: none;
            }
        }
    }

    &.off-canvas-right {
        & .off-canvas-sidebar {
            left: auto;
            right: 0;
            transform: translateX(100%);

            &:target,
            &.active {
                transform: translateX(0);
            }
        }
    }
}