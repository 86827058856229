@import '../../../styles/_modules/variables';

.new-group-appointment {
    & .date-time {
        font-size: $font-size-lg;

        & ul {
            &>li {
                position: relative;

                & .control {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 10;

                    &.transparent {
                        &>.rw-datetime-picker {
                            &>.rw-widget-container {
                                opacity: 0;
                                border: none;
                            }
                        }
                    }

                    & .control-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    & .rw-widget.rw-state-focus {
                        & .rw-widget-picker {
                            border: none;
                            box-shadow: none;
                        }
                    }
                }

                & .text {
                    position: relative;
                    z-index: 11;
                    background-color: $white;
                    padding: $p-o 0;

                    & .weekday {
                        display: block;
                        font-size: $font-size-xs;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-top: $p-o;
                        transition: color linear .15s;
                        cursor: pointer;
                    }

                    & .date,
                    & .time {
                        display: block;
                        transition: color linear .15s;
                        cursor: pointer;
                    }

                    & .time {
                        margin-left: $p-o;
                        margin-right: $p-o;
                    }

                    &:hover,
                    &:active,
                    &.active {
                        & .weekday {
                            color: $color-primary;
                            transition: color linear .15s;
                        }

                        & .date,
                        & .time {
                            color: $color-primary;
                            transition: color linear .15s;
                        }
                    }
                }
            }
        }
    }

    & .resource {
        & .rw-dropdown-list {
            & .rw-widget-container {
                padding-top: $p-1;
                padding-bottom: $p-1;

                & .rw-dropdown-list-input {
                    padding-left: $p-1;
                }
            }

            & .profile-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-right: $p-2;
                cursor: pointer;
                transition: background-color linear .15s;

                & .profile {
                    margin-right: $p-2;

                    & .profile-image {
                        width: $p-3 * 2;
                        height: $p-3 * 2;
                        margin-right: $p-2;
                    }
                }

                & .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: $font-size-lg;
                }
            }
        }
    }

    & .primary-contact {
        & .rw-dropdown-list {
            & .rw-widget-container {
                padding-top: $p-1;
                padding-bottom: $p-1;

                & .rw-dropdown-list-input {
                    padding-left: $p-1;
                }
            }

            & .rw-state-selected {

                & .text-danger,
                & .text-info {
                    color: $white !important;
                }
            }

            & .profile-wrapper {
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-right: $p-2;
                cursor: pointer;
                transition: background-color linear .15s;

                & .profile {
                    margin-right: $p-2;

                    & .profile-image {
                        width: $p-3 * 2;
                        height: $p-3 * 2;
                        margin-right: $p-2;
                    }
                }

                & .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: $font-size-lg;
                }
            }
        }
    }

    & .services {
        & .rw-widget {
            &.rw-multiselect {
                & .rw-widget-container {
                    &.rw-widget-input {
                        & div {
                            & .rw-multiselect-taglist {
                                // & .rw-multiselect-tag {
                                //     position: relative;
                                //     padding: 0;
                                //     border: none;

                                //     &>* {
                                //         display: flex;
                                //         flex-direction: column;
                                //         justify-content: center;
                                //         padding: $p-h $p-2;
                                //         color: $white;
                                //     }

                                //     &>.tag {
                                //         padding-right: 25px;
                                //         border: 1px solid transparent;
                                //     }

                                //     &>div {
                                //         position: absolute;
                                //         top: 0;
                                //         right: 0;
                                //         bottom: 0;
                                //         z-index: 1;
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
        }
    }

    & .customer {
        & .dropdown {
            width: 100%;

            &>.customer-status {
                position: absolute;
                top: $input-padding-y + .1;
                right: $input-padding-x;
                color: $color-success;
                font-size: $font-size-lg;
                opacity: 0;
                transition: opacity linear .15s;

                &.customer-status-selected {
                    opacity: 1;
                    transition: opacity linear .15s;
                }
            }

            &>.dropdown-menu {
                width: 100%;
                margin-top: -3px;
                padding: 0;

                & .dropdown-menu-item {
                    transition: background-color linear .15s;
                    padding: 0 !important;

                    &.active {
                        background-color: rgba($color-primary, .1) !important;
                        transition: background-color linear .15s;
                    }

                    & .profile-wrapper {
                        display: flex;
                        flex-direction: row;
                        padding: $p-2;
                        cursor: pointer;
                        transition: background-color linear .15s;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: rgba($gray-300, .5) !important;
                            transition: background-color linear .15s;
                        }

                        & .profile {
                            margin-right: $p-2;

                            & .profile-image {
                                width: $p-3 * 2;
                                height: $p-3 * 2;
                                margin-right: $p-2;
                            }
                        }

                        & .description {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            & .name,
                            & .info {
                                font-size: $font-size-sm;

                                &.not-ready {
                                    background-color: $gray-300;
                                    height: 10px;
                                }
                            }

                            & .name {
                                color: $gray-700;
                                font-weight: normal;
                            }

                            & .info {
                                color: $gray-600;

                                &.not-ready {
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .customer-selected {
        & .profile-wrapper {
            display: flex;
            flex-direction: row;
            padding: $p-2;
            padding-bottom: 0;

            & .profile {
                margin-right: $p-2;

                & .profile-image {
                    width: $p-3 * 2;
                    height: $p-3 * 2;
                    margin-right: $p-2;
                }
            }

            & .description {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: $font-size-lg;
            }
        }
    }
}

// .new-appointment {
//     & .date-time {
//         font-size: $font-size-xl;

//         & ul {
//             &>li {
//                 position: relative;

//                 & .control {
//                     position: absolute;
//                     left: 0;
//                     bottom: 0;
//                     z-index: 10;

//                     &.transparent {
//                         & .rw-widget-container {
//                             opacity: 0;
//                         }
//                     }

//                     & .control-overlay {
//                         position: fixed;
//                         top: 0;
//                         left: 0;
//                         right: 0;
//                         bottom: 0;                        
//                     }

//                     & .rw-widget.rw-state-focus {
//                         & .rw-widget-picker {
//                             border: none;
//                             box-shadow: none;
//                         }
//                     }
//                 }

//                 & .text {
//                     position: relative;
//                     z-index: 11;
//                     background-color: $light-color;
//                     padding: $unit-1 0;

//                     & .weekday {
//                         display: block;
//                         color: $dark-color;
//                         font-size: $unit-3;
//                         font-weight: 500;
//                         text-transform: uppercase;
//                         margin-top: $unit-1;
//                         transition: color linear .15s;
//                         cursor: pointer;
//                     }

//                     & .date,
//                     & .time {
//                         display: block;
//                         transition: color linear .15s;
//                         cursor: pointer;
//                     }

//                     &:hover,
//                     &:active,
//                     &.active {
//                         & .weekday {
//                             color: $primary-button-color;
//                             transition: color linear .15s;
//                         }

//                         & .date,
//                         & .time {
//                             color: $color-primary;
//                             transition: color linear .15s;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     & .customer {
//         & .dropdown {
//             width: 100%;

//             & > .menu {
//                 width: 100%;
//                 margin-top: -3px;
//                 padding: 0;

//                 & .menu-item {
//                     transition: background-color linear .15s;
//                     padding: 0;

//                     &.active {
//                         background-color: rgba($color-primary, .1) !important;
//                         transition: background-color linear .15s;
//                     }

//                     & .profile-wrapper {
//                         display: flex;
//                         flex-direction: row;
//                         padding: $p-1;
//                         cursor: pointer;
//                         transition: background-color linear .15s;

//                         &:hover,
//                         &:active,
//                         &:focus {
//                             background-color: rgba($gray-300, .5) !important;
//                             transition: background-color linear .15s;
//                         }

//                         & .profile {
//                             margin-right: $p-1;
//                         }

//                         & .description {
//                             display: flex;
//                             flex-direction: column;
//                             justify-content: center;

//                             & .name,
//                             & .info {
//                                 font-size: $font-size-sm;

//                                 &.not-ready {
//                                     background-color: $gray-300;
//                                     height: 10px;
//                                 }
//                             }

//                             & .name {
//                                 color: $gray-700;
//                                 font-weight: normal;
//                             }

//                             & .info {
//                                 color: $gray-500;

//                                 &.not-ready {
//                                     width: 80px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     & .resource {
//         & .rw-dropdown-list {
//             & .figure-description {
//                 position: relative;
//                 font-size: $font-size;
//                 padding: $unit-1 0 $unit-1 $unit-10;

//                 & .avatar {
//                     position: absolute;
//                     left: 0;
//                     top: 0;                    
//                 }
//             }
//         }
//     }

//     & .services {
//         & .rw-widget {        
//             &.rw-multiselect {
//                 & .rw-widget-container {
//                     &.rw-widget-input {
//                         & div {
//                             & .rw-multiselect-taglist {        
//                                 & .rw-multiselect-tag {      
//                                     position: relative;
//                                     padding: 0;  
//                                     border: none;

//                                     & > * {
//                                         display: flex;
//                                         flex-direction: column;
//                                         justify-content: center;
//                                         padding: 6px;
//                                         color: $light-color;
//                                         height: 40px;
//                                     }

//                                     & > .tag {
//                                         padding-right: 25px;
//                                         border: 1px solid transparent;
//                                     }

//                                     & > div {
//                                         position: absolute;
//                                         top: 0;
//                                         right: 0;
//                                         bottom: 0;
//                                         z-index: 1;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }