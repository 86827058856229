@import '../../../styles/_modules/variables';

.add-customer-relationships-container {
    & .customer {
        & .dropdown {
            width: 100%;

            &>.customer-status {
                position: absolute;
                top: $input-padding-y + .1;
                right: $input-padding-x;
                color: $color-success;
                font-size: $font-size-lg;
                opacity: 0;
                transition: opacity linear .15s;

                &.customer-status-selected {
                    opacity: 1;
                    transition: opacity linear .15s;
                }
            }

            &>.dropdown-menu {
                width: 100%;
                margin-top: -3px;
                padding: 0;

                & .dropdown-menu-item {
                    transition: background-color linear .15s;
                    padding: 0 !important;

                    &.active {
                        background-color: rgba($color-primary, .1) !important;
                        transition: background-color linear .15s;
                    }

                    & .profile-wrapper {
                        display: flex;
                        flex-direction: row;
                        padding: $p-2;
                        cursor: pointer;
                        transition: background-color linear .15s;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: rgba($gray-300, .5) !important;
                            transition: background-color linear .15s;
                        }

                        & .profile {
                            margin-right: $p-2;
                        }

                        & .description {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            & .name,
                            & .info {
                                font-size: $font-size-sm;

                                &.not-ready {
                                    background-color: $gray-300;
                                    height: 10px;
                                }
                            }

                            & .name {
                                color: $gray-700;
                                font-weight: normal;
                            }

                            & .info {
                                color: $gray-600;

                                &.not-ready {
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}