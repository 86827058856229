@import '../../styles/_modules/variables.scss';

.color-picker {
    & .cp-color {
        width: 23px;
        height: 23px;
        border-radius: 2px;
    }

    & .cp-swatch {
        padding: 5px;
        background: #fff;
        border-radius: 1px;
        border: 1px solid $table-border-color;
        display: inline-block;
        cursor: pointer;
    }

    & .cp-popover {
        position: absolute;
        z-index: 2;

        &.cp-popover-right {
            right: $p-4 * 2;
        }
    }

    & .cp-cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}