/*  THEME COLORs
========================================================================== */
/* Looks good on chrome default color profile */
$color-primary:						#5657a6;
$color-success:						#18c7c9;
$color-info:						#2196f3;
$color-warning:						#fbb03a;
$color-danger:						#fd3995;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); 
$color-alt:							#2c3e50;

$facebook-color: 					#4267b2;
$google-color: 						#4285f4;
$twitter-color: 					#4da7de;
$instagram-color: 					#000;
$microsoft-color: 					#00a4ef;
$yahoo-color: 						#720e9e;
$youtube-color: 					#e02a20;

$color-default: 					#868e96;
$color-blue: 						#1976D2;
$color-yellow: 						#FBC02D;
$color-red: 						#D32F2F;
$color-purple: 						#7B1FA2;
$color-green: 						#388E3C;
$color-orange: 						#F57C00;
$color-teal: 						#00796B;
$color-brown: 						#5D4037;
$color-pink: 						#F06292;
$color-black: 						#212121;

// $color-primary:						#886ab5;
// $color-success:						#1dc9b7;
// $color-info:						#2196F3;
// $color-warning:						#ffc241;
// $color-danger:						#fd3995;
// $color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); 
// $color-alt:							#2c3e50;

/* We will manually convert these primary colors to rgb for the dark mode option of the theme */
$rgb-primary:						hexToRGBString($color-primary) !default;
$rgb-success:						hexToRGBString($color-success) !default;
$rgb-info:							hexToRGBString($color-info) !default;
$rgb-warning:						hexToRGBString($color-warning) !default;
$rgb-danger:						hexToRGBString($color-danger) !default;
$rgb-fusion:						hexToRGBString($color-fusion) !default;
$rgb-alt:							hexToRGBString($color-alt) !default; 

/* looks good in sRGB but washed up on chrome default 
$color-primary:						#826bb0;
$color-success:						#31cb55;
$color-info:						#5e93ec;
$color-warning:						#eec559;
$color-danger:						#dc4b92;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); */

/*  Color Polarity
========================================================================== */
$white:								#fff !default;
$black:								#000 !default;
$disabled:							darken($white, 20%) !default;

/*  PAINTBUCKET MIXER
========================================================================== */
/* the grays */ 
$gray-50:							#f9f9f9;
$gray-100:							#f8f9fa;
$gray-200:							#f3f3f3;
$gray-300:							#dee2e6;
$gray-400:							#ced4da;
$gray-500:							#adb5bd;
$gray-600:							#868e96;
$gray-700:							#495057;
$gray-800:							#343a40;
$gray-900:							#212529;

/* the sapphires */
$primary-50:						lighten($color-primary, 25%) !default;	
$primary-100:						lighten($color-primary, 20%) !default;	
$primary-200:						lighten($color-primary, 15%) !default;	
$primary-300:						lighten($color-primary, 10%) !default;	
$primary-400:						lighten($color-primary, 5%) !default;
$primary-500:						$color-primary !default;
$primary-600:						darken($color-primary, 5%) !default;
$primary-700:						darken($color-primary, 10%) !default;
$primary-800:						darken($color-primary, 15%) !default;
$primary-900:						darken($color-primary, 20%) !default;

/* the emeralds */
$success-50:						lighten($color-success, 25%) !default;	
$success-100:						lighten($color-success, 20%) !default;	
$success-200:						lighten($color-success, 15%) !default;	
$success-300:						lighten($color-success, 10%) !default;	
$success-400:						lighten($color-success, 5%) !default;
$success-500:						$color-success !default;
$success-600:						darken($color-success, 5%) !default;
$success-700:						darken($color-success, 10%) !default;
$success-800:						darken($color-success, 15%) !default;
$success-900:						darken($color-success, 20%) !default;

/* the amethyths */
$info-50:							lighten($color-info, 25%) !default;	
$info-100:							lighten($color-info, 20%) !default;	
$info-200:							lighten($color-info, 15%) !default;	
$info-300:							lighten($color-info, 10%) !default;	
$info-400:							lighten($color-info, 5%) !default;
$info-500:							$color-info !default;
$info-600:							darken($color-info, 5%) !default;
$info-700:							darken($color-info, 10%) !default;
$info-800:							darken($color-info, 15%) !default;
$info-900:							darken($color-info, 20%) !default;

/* the topaz */
$warning-50:						lighten($color-warning, 25%) !default;	
$warning-100:						lighten($color-warning, 20%) !default;	
$warning-200:						lighten($color-warning, 15%) !default;	
$warning-300:						lighten($color-warning, 10%) !default;	
$warning-400:						lighten($color-warning, 5%) !default;
$warning-500:						$color-warning !default;
$warning-600:						darken($color-warning, 5%) !default;
$warning-700:						darken($color-warning, 10%) !default;
$warning-800:						darken($color-warning, 15%) !default;
$warning-900:						darken($color-warning, 20%) !default;

/* the rubies */
$danger-50:							lighten($color-danger, 25%) !default;	
$danger-100:						lighten($color-danger, 20%) !default;	
$danger-200:						lighten($color-danger, 15%) !default;	
$danger-300:						lighten($color-danger, 10%) !default;	
$danger-400:						lighten($color-danger, 5%) !default;
$danger-500:						$color-danger !default;
$danger-600:						darken($color-danger, 5%) !default;
$danger-700:						darken($color-danger, 10%) !default;
$danger-800:						darken($color-danger, 15%) !default;
$danger-900:						darken($color-danger, 20%) !default;

/* the graphites */
$fusion-50:							lighten($color-fusion, 25%) !default;	
$fusion-100:						lighten($color-fusion, 20%) !default;	
$fusion-200:						lighten($color-fusion, 15%) !default;	
$fusion-300:						lighten($color-fusion, 10%) !default;	
$fusion-400:						lighten($color-fusion, 5%) !default;
$fusion-500:						$color-fusion !default;
$fusion-600:						darken($color-fusion, 5%) !default;
$fusion-700:						darken($color-fusion, 10%) !default;
$fusion-800:						darken($color-fusion, 15%) !default;
$fusion-900:						darken($color-fusion, 20%) !default;

$alt-50:							lighten($color-alt, 25%) !default;	
$alt-100:							lighten($color-alt, 20%) !default;	
$alt-200:							lighten($color-alt, 15%) !default;	
$alt-300:							lighten($color-alt, 10%) !default;	
$alt-400:							lighten($color-alt, 5%) !default;
$alt-500:							$color-alt !default;
$alt-600:							darken($color-alt, 5%) !default;
$alt-700:							darken($color-alt, 10%) !default;
$alt-800:							darken($color-alt, 15%) !default;
$alt-900:							darken($color-alt, 20%) !default;

$theme-colors-extended: () !default;
$theme-colors-extended: map-merge((
	"primary":						$color-primary,
	"success":						$color-success,
	"info":							$color-info,
	"warning":						$color-warning,
	"danger":						$color-danger,
	"alt":							$color-alt,
	"rgb-primary":					$rgb-primary,
	"rgb-success":					$rgb-success,
	"rgb-info":						$rgb-info,
	"rgb-warning":					$rgb-warning,
	"rgb-danger":					$rgb-danger,
	"rgb-fusion":					$rgb-fusion,
	"gray-50":						$gray-50,
	"gray-100":						$gray-100,
	"gray-200":						$gray-200,
	"gray-300":						$gray-300,
	"gray-400":						$gray-400,
	"gray-500":						$gray-500,
	"gray-600":						$gray-600,
	"gray-700":						$gray-700,
	"gray-800":						$gray-800,
	"gray-900":						$gray-900,
	"primary-50":					$primary-50,
	"primary-100":					$primary-100,
	"primary-200":					$primary-200,
	"primary-300":					$primary-300,
	"primary-400":					$primary-400,
	"primary-500":					$primary-500,
	"primary-600":					$primary-600,
	"primary-700":					$primary-700,
	"primary-800":					$primary-800,
	"primary-900":					$primary-900,
	"success-50":					$success-50,
	"success-100":					$success-100,
	"success-200":					$success-200,
	"success-300":					$success-300,
	"success-400":					$success-400,
	"success-500":					$success-500,
	"success-600":					$success-600,
	"success-700":					$success-700,
	"success-800":					$success-800,
	"success-900":					$success-900,
	"info-50":						$info-50,
	"info-100":						$info-100,
	"info-200":						$info-200,
	"info-300":						$info-300,
	"info-400":						$info-400,
	"info-500":						$info-500,
	"info-600":						$info-600,
	"info-700":						$info-700,
	"info-800":						$info-800,
	"info-900":						$info-900,
	"warning-50":					$warning-50,
	"warning-100":					$warning-100,
	"warning-200":					$warning-200,
	"warning-300":					$warning-300,
	"warning-400":					$warning-400,
	"warning-500":					$warning-500,
	"warning-600":					$warning-600,
	"warning-700":					$warning-700,
	"warning-800":					$warning-800,
	"warning-900":					$warning-900,  
	"danger-50":					$danger-50,
	"danger-100":					$danger-100,
	"danger-200":					$danger-200,
	"danger-300":					$danger-300,
	"danger-400":					$danger-400,
	"danger-500":					$danger-500,
	"danger-600":					$danger-600,
	"danger-700":					$danger-700,
	"danger-800":					$danger-800,
	"danger-900":					$danger-900,
	"fusion-50":					$fusion-50,
	"fusion-100":					$fusion-100,
	"fusion-200":					$fusion-200,
	"fusion-300":					$fusion-300,
	"fusion-400":					$fusion-400,
	"fusion-500":					$fusion-500,
	"fusion-600":					$fusion-600,
	"fusion-700":					$fusion-700,
	"fusion-800":					$fusion-800,
	"fusion-900":					$fusion-900,
	"alt-50":						$alt-50,
	"alt-100":						$alt-100,
	"alt-200":						$alt-200,
	"alt-300":						$alt-300,
	"alt-400":						$alt-400,
	"alt-500":						$alt-500,
	"alt-600":						$alt-600,
	"alt-700":						$alt-700,
	"alt-800":						$alt-800,
	"alt-900":						$alt-900

), $theme-colors-extended);

/*  EXTRA COLORS
========================================================================== */
$color0: #f44336;
$color1: #e91E63;
$color2: #9c27b0;
$color3: #673ab7;
$color4: #3f51b5;
$color5: #2196f3;
$color6: #03a9f4;
$color7: #00bcd4;
$color8: #4db6ac;
$color9: #4caf50;
$color10: #8bc34a;
$color11: #cddc39;
$color12: #ffd600;
$color13: #ffc107;
$color14: #ff9800;
$color15: #ff5722;
$color16: #e65239;
$color17: #e55f9e;
$color18: #dec35a;
$color19: #f3d149;
$color20: #5998e4;
$color21: #488abc;
$color22: #f7a800;
$color23: #e99a8d;
$color24: #a43d39;
$color25: #04b769;
$color26: #89af7c;
$color27: #02ddae;
$color28: #e95627;
$color29: #f7b800;
$color30: #7d66f7;
$color31: #9456af;
$color32: #107190;
$color33: #f77464;
$color34: #107576;
$color35: #7fb87f;

/* color0 */
$color0-50:							lighten($color0, 25%) !default;	
$color0-100:						lighten($color0, 20%) !default;	
$color0-200:						lighten($color0, 15%) !default;	
$color0-300:						lighten($color0, 10%) !default;	
$color0-400:						lighten($color0, 5%) !default;
$color0-500:						$color0 !default;
$color0-600:						darken($color0, 5%) !default;
$color0-700:						darken($color0, 10%) !default;
$color0-800:						darken($color0, 15%) !default;
$color0-900:						darken($color0, 20%) !default;

/* color1 */
$color1-50:							lighten($color1, 25%) !default;	
$color1-100:						lighten($color1, 20%) !default;	
$color1-200:						lighten($color1, 15%) !default;	
$color1-300:						lighten($color1, 10%) !default;	
$color1-400:						lighten($color1, 5%) !default;
$color1-500:						$color1 !default;
$color1-600:						darken($color1, 5%) !default;
$color1-700:						darken($color1, 10%) !default;
$color1-800:						darken($color1, 15%) !default;
$color1-900:						darken($color1, 20%) !default;

/* color2 */
$color2-50:							lighten($color2, 25%) !default;	
$color2-100:						lighten($color2, 20%) !default;	
$color2-200:						lighten($color2, 15%) !default;	
$color2-300:						lighten($color2, 10%) !default;	
$color2-400:						lighten($color2, 5%) !default;
$color2-500:						$color2 !default;
$color2-600:						darken($color2, 5%) !default;
$color2-700:						darken($color2, 10%) !default;
$color2-800:						darken($color2, 15%) !default;
$color2-900:						darken($color2, 20%) !default;

/* color3 */
$color3-50:							lighten($color3, 25%) !default;	
$color3-100:						lighten($color3, 20%) !default;	
$color3-200:						lighten($color3, 15%) !default;	
$color3-300:						lighten($color3, 10%) !default;	
$color3-400:						lighten($color3, 5%) !default;
$color3-500:						$color3 !default;
$color3-600:						darken($color3, 5%) !default;
$color3-700:						darken($color3, 10%) !default;
$color3-800:						darken($color3, 15%) !default;
$color3-900:						darken($color3, 20%) !default;

/* color4 */
$color4-50:							lighten($color4, 25%) !default;	
$color4-100:						lighten($color4, 20%) !default;	
$color4-200:						lighten($color4, 15%) !default;	
$color4-300:						lighten($color4, 10%) !default;	
$color4-400:						lighten($color4, 5%) !default;
$color4-500:						$color4 !default;
$color4-600:						darken($color4, 5%) !default;
$color4-700:						darken($color4, 10%) !default;
$color4-800:						darken($color4, 15%) !default;
$color4-900:						darken($color4, 20%) !default;

/* color5 */
$color5-50:							lighten($color5, 25%) !default;	
$color5-100:						lighten($color5, 20%) !default;	
$color5-200:						lighten($color5, 15%) !default;	
$color5-300:						lighten($color5, 10%) !default;	
$color5-400:						lighten($color5, 5%) !default;
$color5-500:						$color5 !default;
$color5-600:						darken($color5, 5%) !default;
$color5-700:						darken($color5, 10%) !default;
$color5-800:						darken($color5, 15%) !default;
$color5-900:						darken($color5, 20%) !default;

/* color6 */
$color6-50:							lighten($color6, 25%) !default;	
$color6-100:						lighten($color6, 20%) !default;	
$color6-200:						lighten($color6, 15%) !default;	
$color6-300:						lighten($color6, 10%) !default;	
$color6-400:						lighten($color6, 5%) !default;
$color6-500:						$color6 !default;
$color6-600:						darken($color6, 5%) !default;
$color6-700:						darken($color6, 10%) !default;
$color6-800:						darken($color6, 15%) !default;
$color6-900:						darken($color6, 20%) !default;

/* color7 */
$color7-50:							lighten($color7, 25%) !default;	
$color7-100:						lighten($color7, 20%) !default;	
$color7-200:						lighten($color7, 15%) !default;	
$color7-300:						lighten($color7, 10%) !default;	
$color7-400:						lighten($color7, 5%) !default;
$color7-500:						$color7 !default;
$color7-600:						darken($color7, 5%) !default;
$color7-700:						darken($color7, 10%) !default;
$color7-800:						darken($color7, 15%) !default;
$color7-900:						darken($color7, 20%) !default;

/* color8 */
$color8-50:							lighten($color8, 25%) !default;	
$color8-100:						lighten($color8, 20%) !default;	
$color8-200:						lighten($color8, 15%) !default;	
$color8-300:						lighten($color8, 10%) !default;	
$color8-400:						lighten($color8, 5%) !default;
$color8-500:						$color8 !default;
$color8-600:						darken($color8, 5%) !default;
$color8-700:						darken($color8, 10%) !default;
$color8-800:						darken($color8, 15%) !default;
$color8-900:						darken($color8, 20%) !default;

/* color9 */
$color9-50:							lighten($color9, 25%) !default;	
$color9-100:						lighten($color9, 20%) !default;	
$color9-200:						lighten($color9, 15%) !default;	
$color9-300:						lighten($color9, 10%) !default;	
$color9-400:						lighten($color9, 5%) !default;
$color9-500:						$color9 !default;
$color9-600:						darken($color9, 5%) !default;
$color9-700:						darken($color9, 10%) !default;
$color9-800:						darken($color9, 15%) !default;
$color9-900:						darken($color9, 20%) !default;

/* color10 */
$color10-50:						lighten($color10, 25%) !default;	
$color10-100:						lighten($color10, 20%) !default;	
$color10-200:						lighten($color10, 15%) !default;	
$color10-300:						lighten($color10, 10%) !default;	
$color10-400:						lighten($color10, 5%) !default;
$color10-500:						$color10 !default;
$color10-600:						darken($color10, 5%) !default;
$color10-700:						darken($color10, 10%) !default;
$color10-800:						darken($color10, 15%) !default;
$color10-900:						darken($color10, 20%) !default;

/* color11 */
$color11-50:						lighten($color11, 25%) !default;	
$color11-100:						lighten($color11, 20%) !default;	
$color11-200:						lighten($color11, 15%) !default;	
$color11-300:						lighten($color11, 10%) !default;	
$color11-400:						lighten($color11, 5%) !default;
$color11-500:						$color11 !default;
$color11-600:						darken($color11, 5%) !default;
$color11-700:						darken($color11, 10%) !default;
$color11-800:						darken($color11, 15%) !default;
$color11-900:						darken($color11, 20%) !default;

/* color12 */
$color12-50:						lighten($color12, 25%) !default;	
$color12-100:						lighten($color12, 20%) !default;	
$color12-200:						lighten($color12, 15%) !default;	
$color12-300:						lighten($color12, 10%) !default;	
$color12-400:						lighten($color12, 5%) !default;
$color12-500:						$color12 !default;
$color12-600:						darken($color12, 5%) !default;
$color12-700:						darken($color12, 10%) !default;
$color12-800:						darken($color12, 15%) !default;
$color12-900:						darken($color12, 20%) !default;

/* color13 */
$color13-50:						lighten($color13, 25%) !default;	
$color13-100:						lighten($color13, 20%) !default;	
$color13-200:						lighten($color13, 15%) !default;	
$color13-300:						lighten($color13, 10%) !default;	
$color13-400:						lighten($color13, 5%) !default;
$color13-500:						$color13 !default;
$color13-600:						darken($color13, 5%) !default;
$color13-700:						darken($color13, 10%) !default;
$color13-800:						darken($color13, 15%) !default;
$color13-900:						darken($color13, 20%) !default;

/* color14 */
$color14-50:						lighten($color14, 25%) !default;	
$color14-100:						lighten($color14, 20%) !default;	
$color14-200:						lighten($color14, 15%) !default;	
$color14-300:						lighten($color14, 10%) !default;	
$color14-400:						lighten($color14, 5%) !default;
$color14-500:						$color14 !default;
$color14-600:						darken($color14, 5%) !default;
$color14-700:						darken($color14, 10%) !default;
$color14-800:						darken($color14, 15%) !default;
$color14-900:						darken($color14, 20%) !default;

/* color15 */
$color15-50:						lighten($color15, 25%) !default;	
$color15-100:						lighten($color15, 20%) !default;	
$color15-200:						lighten($color15, 15%) !default;	
$color15-300:						lighten($color15, 10%) !default;	
$color15-400:						lighten($color15, 5%) !default;
$color15-500:						$color15 !default;
$color15-600:						darken($color15, 5%) !default;
$color15-700:						darken($color15, 10%) !default;
$color15-800:						darken($color15, 15%) !default;
$color15-900:						darken($color15, 20%) !default;

/* color16 */
$color16-50:						lighten($color16, 25%) !default;	
$color16-100:						lighten($color16, 20%) !default;	
$color16-200:						lighten($color16, 15%) !default;	
$color16-300:						lighten($color16, 10%) !default;	
$color16-400:						lighten($color16, 5%) !default;
$color16-500:						$color16 !default;
$color16-600:						darken($color16, 5%) !default;
$color16-700:						darken($color16, 10%) !default;
$color16-800:						darken($color16, 15%) !default;
$color16-900:						darken($color16, 20%) !default;

/* color17 */
$color17-50:						lighten($color17, 25%) !default;	
$color17-100:						lighten($color17, 20%) !default;	
$color17-200:						lighten($color17, 15%) !default;	
$color17-300:						lighten($color17, 10%) !default;	
$color17-400:						lighten($color17, 5%) !default;
$color17-500:						$color17 !default;
$color17-600:						darken($color17, 5%) !default;
$color17-700:						darken($color17, 10%) !default;
$color17-800:						darken($color17, 15%) !default;
$color17-900:						darken($color17, 20%) !default;

/* color18 */
$color18-50:						lighten($color18, 25%) !default;	
$color18-100:						lighten($color18, 20%) !default;	
$color18-200:						lighten($color18, 15%) !default;	
$color18-300:						lighten($color18, 10%) !default;	
$color18-400:						lighten($color18, 5%) !default;
$color18-500:						$color18 !default;
$color18-600:						darken($color18, 5%) !default;
$color18-700:						darken($color18, 10%) !default;
$color18-800:						darken($color18, 15%) !default;
$color18-900:						darken($color18, 20%) !default;

/* color19 */
$color19-50:						lighten($color19, 25%) !default;	
$color19-100:						lighten($color19, 20%) !default;	
$color19-200:						lighten($color19, 15%) !default;	
$color19-300:						lighten($color19, 10%) !default;	
$color19-400:						lighten($color19, 5%) !default;
$color19-500:						$color19 !default;
$color19-600:						darken($color19, 5%) !default;
$color19-700:						darken($color19, 10%) !default;
$color19-800:						darken($color19, 15%) !default;
$color19-900:						darken($color19, 20%) !default;

/* color20 */
$color20-50:						lighten($color20, 25%) !default;	
$color20-100:						lighten($color20, 20%) !default;	
$color20-200:						lighten($color20, 15%) !default;	
$color20-300:						lighten($color20, 10%) !default;	
$color20-400:						lighten($color20, 5%) !default;
$color20-500:						$color20 !default;
$color20-600:						darken($color20, 5%) !default;
$color20-700:						darken($color20, 10%) !default;
$color20-800:						darken($color20, 15%) !default;
$color20-900:						darken($color20, 20%) !default;

/* color21 */
$color21-50:						lighten($color21, 25%) !default;	
$color21-100:						lighten($color21, 20%) !default;	
$color21-200:						lighten($color21, 15%) !default;	
$color21-300:						lighten($color21, 10%) !default;	
$color21-400:						lighten($color21, 5%) !default;
$color21-500:						$color21 !default;
$color21-600:						darken($color21, 5%) !default;
$color21-700:						darken($color21, 10%) !default;
$color21-800:						darken($color21, 15%) !default;
$color21-900:						darken($color21, 20%) !default;

/* color22 */
$color22-50:						lighten($color22, 25%) !default;	
$color22-100:						lighten($color22, 20%) !default;	
$color22-200:						lighten($color22, 15%) !default;	
$color22-300:						lighten($color22, 10%) !default;	
$color22-400:						lighten($color22, 5%) !default;
$color22-500:						$color22 !default;
$color22-600:						darken($color22, 5%) !default;
$color22-700:						darken($color22, 10%) !default;
$color22-800:						darken($color22, 15%) !default;
$color22-900:						darken($color22, 20%) !default;

/* color23 */
$color23-50:						lighten($color23, 25%) !default;	
$color23-100:						lighten($color23, 20%) !default;	
$color23-200:						lighten($color23, 15%) !default;	
$color23-300:						lighten($color23, 10%) !default;	
$color23-400:						lighten($color23, 5%) !default;
$color23-500:						$color23 !default;
$color23-600:						darken($color23, 5%) !default;
$color23-700:						darken($color23, 10%) !default;
$color23-800:						darken($color23, 15%) !default;
$color23-900:						darken($color23, 20%) !default;

/* color24 */
$color24-50:						lighten($color24, 25%) !default;	
$color24-100:						lighten($color24, 20%) !default;	
$color24-200:						lighten($color24, 15%) !default;	
$color24-300:						lighten($color24, 10%) !default;	
$color24-400:						lighten($color24, 5%) !default;
$color24-500:						$color24 !default;
$color24-600:						darken($color24, 5%) !default;
$color24-700:						darken($color24, 10%) !default;
$color24-800:						darken($color24, 15%) !default;
$color24-900:						darken($color24, 20%) !default;

/* color25 */
$color25-50:						lighten($color25, 25%) !default;	
$color25-100:						lighten($color25, 20%) !default;	
$color25-200:						lighten($color25, 15%) !default;	
$color25-300:						lighten($color25, 10%) !default;	
$color25-400:						lighten($color25, 5%) !default;
$color25-500:						$color25 !default;
$color25-600:						darken($color25, 5%) !default;
$color25-700:						darken($color25, 10%) !default;
$color25-800:						darken($color25, 15%) !default;
$color25-900:						darken($color25, 20%) !default;

/* color26 */
$color26-50:						lighten($color26, 25%) !default;	
$color26-100:						lighten($color26, 20%) !default;	
$color26-200:						lighten($color26, 15%) !default;	
$color26-300:						lighten($color26, 10%) !default;	
$color26-400:						lighten($color26, 5%) !default;
$color26-500:						$color26 !default;
$color26-600:						darken($color26, 5%) !default;
$color26-700:						darken($color26, 10%) !default;
$color26-800:						darken($color26, 15%) !default;
$color26-900:						darken($color26, 20%) !default;

/* color27 */
$color27-50:						lighten($color27, 25%) !default;	
$color27-100:						lighten($color27, 20%) !default;	
$color27-200:						lighten($color27, 15%) !default;	
$color27-300:						lighten($color27, 10%) !default;	
$color27-400:						lighten($color27, 5%) !default;
$color27-500:						$color27 !default;
$color27-600:						darken($color27, 5%) !default;
$color27-700:						darken($color27, 10%) !default;
$color27-800:						darken($color27, 15%) !default;
$color27-900:						darken($color27, 20%) !default;

/* color28 */
$color28-50:						lighten($color28, 25%) !default;	
$color28-100:						lighten($color28, 20%) !default;	
$color28-200:						lighten($color28, 15%) !default;	
$color28-300:						lighten($color28, 10%) !default;	
$color28-400:						lighten($color28, 5%) !default;
$color28-500:						$color28 !default;
$color28-600:						darken($color28, 5%) !default;
$color28-700:						darken($color28, 10%) !default;
$color28-800:						darken($color28, 15%) !default;
$color28-900:						darken($color28, 20%) !default;

/* color29 */
$color29-50:						lighten($color29, 25%) !default;	
$color29-100:						lighten($color29, 20%) !default;	
$color29-200:						lighten($color29, 15%) !default;	
$color29-300:						lighten($color29, 10%) !default;	
$color29-400:						lighten($color29, 5%) !default;
$color29-500:						$color29 !default;
$color29-600:						darken($color29, 5%) !default;
$color29-700:						darken($color29, 10%) !default;
$color29-800:						darken($color29, 15%) !default;
$color29-900:						darken($color29, 20%) !default;

/* color30 */
$color30-50:						lighten($color30, 25%) !default;	
$color30-100:						lighten($color30, 20%) !default;	
$color30-200:						lighten($color30, 15%) !default;	
$color30-300:						lighten($color30, 10%) !default;	
$color30-400:						lighten($color30, 5%) !default;
$color30-500:						$color30 !default;
$color30-600:						darken($color30, 5%) !default;
$color30-700:						darken($color30, 10%) !default;
$color30-800:						darken($color30, 15%) !default;
$color30-900:						darken($color30, 20%) !default;

/* color31 */
$color31-50:						lighten($color31, 25%) !default;	
$color31-100:						lighten($color31, 20%) !default;	
$color31-200:						lighten($color31, 15%) !default;	
$color31-300:						lighten($color31, 10%) !default;	
$color31-400:						lighten($color31, 5%) !default;
$color31-500:						$color31 !default;
$color31-600:						darken($color31, 5%) !default;
$color31-700:						darken($color31, 10%) !default;
$color31-800:						darken($color31, 15%) !default;
$color31-900:						darken($color31, 20%) !default;

/* color32 */
$color32-50:						lighten($color32, 25%) !default;	
$color32-100:						lighten($color32, 20%) !default;	
$color32-200:						lighten($color32, 15%) !default;	
$color32-300:						lighten($color32, 10%) !default;	
$color32-400:						lighten($color32, 5%) !default;
$color32-500:						$color32 !default;
$color32-600:						darken($color32, 5%) !default;
$color32-700:						darken($color32, 10%) !default;
$color32-800:						darken($color32, 15%) !default;
$color32-900:						darken($color32, 20%) !default;

/* color33 */
$color33-50:						lighten($color33, 25%) !default;	
$color33-100:						lighten($color33, 20%) !default;	
$color33-200:						lighten($color33, 15%) !default;	
$color33-300:						lighten($color33, 10%) !default;	
$color33-400:						lighten($color33, 5%) !default;
$color33-500:						$color33 !default;
$color33-600:						darken($color33, 5%) !default;
$color33-700:						darken($color33, 10%) !default;
$color33-800:						darken($color33, 15%) !default;
$color33-900:						darken($color33, 20%) !default;

/* color34 */
$color34-50:						lighten($color34, 25%) !default;	
$color34-100:						lighten($color34, 20%) !default;	
$color34-200:						lighten($color34, 15%) !default;	
$color34-300:						lighten($color34, 10%) !default;	
$color34-400:						lighten($color34, 5%) !default;
$color34-500:						$color34 !default;
$color34-600:						darken($color34, 5%) !default;
$color34-700:						darken($color34, 10%) !default;
$color34-800:						darken($color34, 15%) !default;
$color34-900:						darken($color34, 20%) !default;

/* color35 */
$color35-50:						lighten($color35, 25%) !default;	
$color35-100:						lighten($color35, 20%) !default;	
$color35-200:						lighten($color35, 15%) !default;	
$color35-300:						lighten($color35, 10%) !default;	
$color35-400:						lighten($color35, 5%) !default;
$color35-500:						$color35 !default;
$color35-600:						darken($color35, 5%) !default;
$color35-700:						darken($color35, 10%) !default;
$color35-800:						darken($color35, 15%) !default;
$color35-900:						darken($color35, 20%) !default;

$extra-colors-extended: () !default;
$extra-colors-extended: map-merge((
	"color0-50":					$color0-50,
	"color0-100":					$color0-100,
	"color0-200":					$color0-200,
	"color0-300":					$color0-300,
	"color0-400":					$color0-400,
	"color0-500":					$color0-500,
	"color0-600":					$color0-600,
	"color0-700":					$color0-700,
	"color0-800":					$color0-800,
	"color0-900":					$color0-900,
	"color1-50":					$color1-50,
	"color1-100":					$color1-100,
	"color1-200":					$color1-200,
	"color1-300":					$color1-300,
	"color1-400":					$color1-400,
	"color1-500":					$color1-500,
	"color1-600":					$color1-600,
	"color1-700":					$color1-700,
	"color1-800":					$color1-800,
	"color1-900":					$color1-900,
	"color2-50":					$color2-50,
	"color2-100":					$color2-100,
	"color2-200":					$color2-200,
	"color2-300":					$color2-300,
	"color2-400":					$color2-400,
	"color2-500":					$color2-500,
	"color2-600":					$color2-600,
	"color2-700":					$color2-700,
	"color2-800":					$color2-800,
	"color2-900":					$color2-900,
	"color3-50":					$color3-50,
	"color3-100":					$color3-100,
	"color3-200":					$color3-200,
	"color3-300":					$color3-300,
	"color3-400":					$color3-400,
	"color3-500":					$color3-500,
	"color3-600":					$color3-600,
	"color3-700":					$color3-700,
	"color3-800":					$color3-800,
	"color3-900":					$color3-900,
	"color4-50":					$color4-50,
	"color4-100":					$color4-100,
	"color4-200":					$color4-200,
	"color4-300":					$color4-300,
	"color4-400":					$color4-400,
	"color4-500":					$color4-500,
	"color4-600":					$color4-600,
	"color4-700":					$color4-700,
	"color4-800":					$color4-800,
	"color4-900":					$color4-900,
	"color5-50":					$color5-50,
	"color5-100":					$color5-100,
	"color5-200":					$color5-200,
	"color5-300":					$color5-300,
	"color5-400":					$color5-400,
	"color5-500":					$color5-500,
	"color5-600":					$color5-600,
	"color5-700":					$color5-700,
	"color5-800":					$color5-800,
	"color5-900":					$color5-900,
	"color6-50":					$color6-50,
	"color6-100":					$color6-100,
	"color6-200":					$color6-200,
	"color6-300":					$color6-300,
	"color6-400":					$color6-400,
	"color6-500":					$color6-500,
	"color6-600":					$color6-600,
	"color6-700":					$color6-700,
	"color6-800":					$color6-800,
	"color6-900":					$color6-900,
	"color7-50":					$color7-50,
	"color7-100":					$color7-100,
	"color7-200":					$color7-200,
	"color7-300":					$color7-300,
	"color7-400":					$color7-400,
	"color7-500":					$color7-500,
	"color7-600":					$color7-600,
	"color7-700":					$color7-700,
	"color7-800":					$color7-800,
	"color7-900":					$color7-900,
	"color8-50":					$color8-50,
	"color8-100":					$color8-100,
	"color8-200":					$color8-200,
	"color8-300":					$color8-300,
	"color8-400":					$color8-400,
	"color8-500":					$color8-500,
	"color8-600":					$color8-600,
	"color8-700":					$color8-700,
	"color8-800":					$color8-800,
	"color8-900":					$color8-900,
	"color9-50":					$color9-50,
	"color9-100":					$color9-100,
	"color9-200":					$color9-200,
	"color9-300":					$color9-300,
	"color9-400":					$color9-400,
	"color9-500":					$color9-500,
	"color9-600":					$color9-600,
	"color9-700":					$color9-700,
	"color9-800":					$color9-800,
	"color9-900":					$color9-900,
	"color10-50":					$color10-50,
	"color10-100":					$color10-100,
	"color10-200":					$color10-200,
	"color10-300":					$color10-300,
	"color10-400":					$color10-400,
	"color10-500":					$color10-500,
	"color10-600":					$color10-600,
	"color10-700":					$color10-700,
	"color10-800":					$color10-800,
	"color10-900":					$color10-900,
	"color11-50":					$color11-50,
	"color11-100":					$color11-100,
	"color11-200":					$color11-200,
	"color11-300":					$color11-300,
	"color11-400":					$color11-400,
	"color11-500":					$color11-500,
	"color11-600":					$color11-600,
	"color11-700":					$color11-700,
	"color11-800":					$color11-800,
	"color11-900":					$color11-900,
	"color12-50":					$color12-50,
	"color12-100":					$color12-100,
	"color12-200":					$color12-200,
	"color12-300":					$color12-300,
	"color12-400":					$color12-400,
	"color12-500":					$color12-500,
	"color12-600":					$color12-600,
	"color12-700":					$color12-700,
	"color12-800":					$color12-800,
	"color12-900":					$color12-900,
	"color13-50":					$color13-50,
	"color13-100":					$color13-100,
	"color13-200":					$color13-200,
	"color13-300":					$color13-300,
	"color13-400":					$color13-400,
	"color13-500":					$color13-500,
	"color13-600":					$color13-600,
	"color13-700":					$color13-700,
	"color13-800":					$color13-800,
	"color13-900":					$color13-900,
	"color14-50":					$color14-50,
	"color14-100":					$color14-100,
	"color14-200":					$color14-200,
	"color14-300":					$color14-300,
	"color14-400":					$color14-400,
	"color14-500":					$color14-500,
	"color14-600":					$color14-600,
	"color14-700":					$color14-700,
	"color14-800":					$color14-800,
	"color14-900":					$color14-900,
	"color15-50":					$color15-50,
	"color15-100":					$color15-100,
	"color15-200":					$color15-200,
	"color15-300":					$color15-300,
	"color15-400":					$color15-400,
	"color15-500":					$color15-500,
	"color15-600":					$color15-600,
	"color15-700":					$color15-700,
	"color15-800":					$color15-800,
	"color15-900":					$color15-900,
	"color16-50":					$color16-50,
	"color16-100":					$color16-100,
	"color16-200":					$color16-200,
	"color16-300":					$color16-300,
	"color16-400":					$color16-400,
	"color16-500":					$color16-500,
	"color16-600":					$color16-600,
	"color16-700":					$color16-700,
	"color16-800":					$color16-800,
	"color16-900":					$color16-900,
	"color17-50":					$color17-50,
	"color17-100":					$color17-100,
	"color17-200":					$color17-200,
	"color17-300":					$color17-300,
	"color17-400":					$color17-400,
	"color17-500":					$color17-500,
	"color17-600":					$color17-600,
	"color17-700":					$color17-700,
	"color17-800":					$color17-800,
	"color17-900":					$color17-900,
	"color18-50":					$color18-50,
	"color18-100":					$color18-100,
	"color18-200":					$color18-200,
	"color18-300":					$color18-300,
	"color18-400":					$color18-400,
	"color18-500":					$color18-500,
	"color18-600":					$color18-600,
	"color18-700":					$color18-700,
	"color18-800":					$color18-800,
	"color18-900":					$color18-900,
	"color19-50":					$color19-50,
	"color19-100":					$color19-100,
	"color19-200":					$color19-200,
	"color19-300":					$color19-300,
	"color19-400":					$color19-400,
	"color19-500":					$color19-500,
	"color19-600":					$color19-600,
	"color19-700":					$color19-700,
	"color19-800":					$color19-800,
	"color19-900":					$color19-900,
	"color20-50":					$color20-50,
	"color20-100":					$color20-100,
	"color20-200":					$color20-200,
	"color20-300":					$color20-300,
	"color20-400":					$color20-400,
	"color20-500":					$color20-500,
	"color20-600":					$color20-600,
	"color20-700":					$color20-700,
	"color20-800":					$color20-800,
	"color20-900":					$color20-900,
	"color21-50":					$color21-50,
	"color21-100":					$color21-100,
	"color21-200":					$color21-200,
	"color21-300":					$color21-300,
	"color21-400":					$color21-400,
	"color21-500":					$color21-500,
	"color21-600":					$color21-600,
	"color21-700":					$color21-700,
	"color21-800":					$color21-800,
	"color21-900":					$color21-900,
	"color22-50":					$color22-50,
	"color22-100":					$color22-100,
	"color22-200":					$color22-200,
	"color22-300":					$color22-300,
	"color22-400":					$color22-400,
	"color22-500":					$color22-500,
	"color22-600":					$color22-600,
	"color22-700":					$color22-700,
	"color22-800":					$color22-800,
	"color22-900":					$color22-900,
	"color23-50":					$color23-50,
	"color23-100":					$color23-100,
	"color23-200":					$color23-200,
	"color23-300":					$color23-300,
	"color23-400":					$color23-400,
	"color23-500":					$color23-500,
	"color23-600":					$color23-600,
	"color23-700":					$color23-700,
	"color23-800":					$color23-800,
	"color23-900":					$color23-900,
	"color24-50":					$color24-50,
	"color24-100":					$color24-100,
	"color24-200":					$color24-200,
	"color24-300":					$color24-300,
	"color24-400":					$color24-400,
	"color24-500":					$color24-500,
	"color24-600":					$color24-600,
	"color24-700":					$color24-700,
	"color24-800":					$color24-800,
	"color24-900":					$color24-900,
	"color25-50":					$color25-50,
	"color25-100":					$color25-100,
	"color25-200":					$color25-200,
	"color25-300":					$color25-300,
	"color25-400":					$color25-400,
	"color25-500":					$color25-500,
	"color25-600":					$color25-600,
	"color25-700":					$color25-700,
	"color25-800":					$color25-800,
	"color25-900":					$color25-900,
	"color26-50":					$color26-50,
	"color26-100":					$color26-100,
	"color26-200":					$color26-200,
	"color26-300":					$color26-300,
	"color26-400":					$color26-400,
	"color26-500":					$color26-500,
	"color26-600":					$color26-600,
	"color26-700":					$color26-700,
	"color26-800":					$color26-800,
	"color26-900":					$color26-900,
	"color27-50":					$color27-50,
	"color27-100":					$color27-100,
	"color27-200":					$color27-200,
	"color27-300":					$color27-300,
	"color27-400":					$color27-400,
	"color27-500":					$color27-500,
	"color27-600":					$color27-600,
	"color27-700":					$color27-700,
	"color27-800":					$color27-800,
	"color27-900":					$color27-900,
	"color28-50":					$color28-50,
	"color28-100":					$color28-100,
	"color28-200":					$color28-200,
	"color28-300":					$color28-300,
	"color28-400":					$color28-400,
	"color28-500":					$color28-500,
	"color28-600":					$color28-600,
	"color28-700":					$color28-700,
	"color28-800":					$color28-800,
	"color28-900":					$color28-900,
	"color29-50":					$color29-50,
	"color29-100":					$color29-100,
	"color29-200":					$color29-200,
	"color29-300":					$color29-300,
	"color29-400":					$color29-400,
	"color29-500":					$color29-500,
	"color29-600":					$color29-600,
	"color29-700":					$color29-700,
	"color29-800":					$color29-800,
	"color29-900":					$color29-900,
	"color30-50":					$color30-50,
	"color30-100":					$color30-100,
	"color30-200":					$color30-200,
	"color30-300":					$color30-300,
	"color30-400":					$color30-400,
	"color30-500":					$color30-500,
	"color30-600":					$color30-600,
	"color30-700":					$color30-700,
	"color30-800":					$color30-800,
	"color30-900":					$color30-900,
	"color31-50":					$color31-50,
	"color31-100":					$color31-100,
	"color31-200":					$color31-200,
	"color31-300":					$color31-300,
	"color31-400":					$color31-400,
	"color31-500":					$color31-500,
	"color31-600":					$color31-600,
	"color31-700":					$color31-700,
	"color31-800":					$color31-800,
	"color31-900":					$color31-900,
	"color32-50":					$color32-50,
	"color32-100":					$color32-100,
	"color32-200":					$color32-200,
	"color32-300":					$color32-300,
	"color32-400":					$color32-400,
	"color32-500":					$color32-500,
	"color32-600":					$color32-600,
	"color32-700":					$color32-700,
	"color32-800":					$color32-800,
	"color32-900":					$color32-900,
	"color33-50":					$color33-50,
	"color33-100":					$color33-100,
	"color33-200":					$color33-200,
	"color33-300":					$color33-300,
	"color33-400":					$color33-400,
	"color33-500":					$color33-500,
	"color33-600":					$color33-600,
	"color33-700":					$color33-700,
	"color33-800":					$color33-800,
	"color33-900":					$color33-900,
	"color34-50":					$color34-50,
	"color34-100":					$color34-100,
	"color34-200":					$color34-200,
	"color34-300":					$color34-300,
	"color34-400":					$color34-400,
	"color34-500":					$color34-500,
	"color34-600":					$color34-600,
	"color34-700":					$color34-700,
	"color34-800":					$color34-800,
	"color34-900":					$color34-900,
	"color35-50":					$color35-50,
	"color35-100":					$color35-100,
	"color35-200":					$color35-200,
	"color35-300":					$color35-300,
	"color35-400":					$color35-400,
	"color35-500":					$color35-500,
	"color35-600":					$color35-600,
	"color35-700":					$color35-700,
	"color35-800":					$color35-800,
	"color35-900":					$color35-900
), $extra-colors-extended);

/* SYSTEM COLORS
========================================================================== */

$color-customer: lighten($color2, 30%);
$color-appointment: lighten($color6, 30%);
$color-exam: lighten($color8, 30%);
$color-purchase: lighten($color15, 30%);
$color-work-order: lighten($color10, 15%);
$color-referral: lighten($color3, 30%);
$color-invoice: lighten($color5, 30%);
$color-schedule: lighten($color12, 30%);
$color-product: lighten($color1, 30%);
$color-service: lighten($color11, 30%);
$color-report: lighten($color4, 30%);
$color-note: lighten($color14, 30%);

/* customer color */
$color-customer-50:							lighten($color-customer, 25%) !default;	
$color-customer-100:						lighten($color-customer, 20%) !default;	
$color-customer-200:						lighten($color-customer, 15%) !default;	
$color-customer-300:						lighten($color-customer, 10%) !default;	
$color-customer-400:						lighten($color-customer, 5%) !default;
$color-customer-500:						$color-customer !default;
$color-customer-600:						darken($color-customer, 5%) !default;
$color-customer-700:						darken($color-customer, 10%) !default;
$color-customer-800:						darken($color-customer, 15%) !default;
$color-customer-900:						darken($color-customer, 20%) !default;

/* appointment color */
$color-appointment-50:						lighten($color-appointment, 25%) !default;	
$color-appointment-100:						lighten($color-appointment, 20%) !default;	
$color-appointment-200:						lighten($color-appointment, 15%) !default;	
$color-appointment-300:						lighten($color-appointment, 10%) !default;	
$color-appointment-400:						lighten($color-appointment, 5%) !default;
$color-appointment-500:						$color-appointment !default;
$color-appointment-600:						darken($color-appointment, 5%) !default;
$color-appointment-700:						darken($color-appointment, 10%) !default;
$color-appointment-800:						darken($color-appointment, 15%) !default;
$color-appointment-900:						darken($color-appointment, 20%) !default;

/* exam color */
$color-exam-50:								lighten($color-exam, 25%) !default;	
$color-exam-100:							lighten($color-exam, 20%) !default;	
$color-exam-200:							lighten($color-exam, 15%) !default;	
$color-exam-300:							lighten($color-exam, 10%) !default;	
$color-exam-400:							lighten($color-exam, 5%) !default;
$color-exam-500:							$color-exam !default;
$color-exam-600:							darken($color-exam, 5%) !default;
$color-exam-700:							darken($color-exam, 10%) !default;
$color-exam-800:							darken($color-exam, 15%) !default;
$color-exam-900:							darken($color-exam, 20%) !default;

/* purchase color */
$color-purchase-50:							lighten($color-purchase, 25%) !default;	
$color-purchase-100:						lighten($color-purchase, 20%) !default;	
$color-purchase-200:						lighten($color-purchase, 15%) !default;	
$color-purchase-300:						lighten($color-purchase, 10%) !default;	
$color-purchase-400:						lighten($color-purchase, 5%) !default;
$color-purchase-500:						$color-purchase !default;
$color-purchase-600:						darken($color-purchase, 5%) !default;
$color-purchase-700:						darken($color-purchase, 10%) !default;
$color-purchase-800:						darken($color-purchase, 15%) !default;
$color-purchase-900:						darken($color-purchase, 20%) !default;

/* referral color */
$color-referral-50:								lighten($color-referral, 25%) !default;	
$color-referral-100:								lighten($color-referral, 20%) !default;	
$color-referral-200:								lighten($color-referral, 15%) !default;	
$color-referral-300:								lighten($color-referral, 10%) !default;	
$color-referral-400:								lighten($color-referral, 5%) !default;
$color-referral-500:								$color-referral !default;
$color-referral-600:								darken($color-referral, 5%) !default;
$color-referral-700:								darken($color-referral, 10%) !default;
$color-referral-800:								darken($color-referral, 15%) !default;
$color-referral-900:								darken($color-referral, 20%) !default;

/* work order color */
$color-work-order-50:								lighten($color-work-order, 25%) !default;	
$color-work-order-100:								lighten($color-work-order, 20%) !default;	
$color-work-order-200:								lighten($color-work-order, 15%) !default;	
$color-work-order-300:								lighten($color-work-order, 10%) !default;	
$color-work-order-400:								lighten($color-work-order, 5%) !default;
$color-work-order-500:								$color-work-order !default;
$color-work-order-600:								darken($color-work-order, 5%) !default;
$color-work-order-700:								darken($color-work-order, 10%) !default;
$color-work-order-800:								darken($color-work-order, 15%) !default;
$color-work-order-900:								darken($color-work-order, 20%) !default;

/* invoice color */
$color-invoice-50:							lighten($color-invoice, 25%) !default;	
$color-invoice-100:							lighten($color-invoice, 20%) !default;	
$color-invoice-200:							lighten($color-invoice, 15%) !default;	
$color-invoice-300:							lighten($color-invoice, 10%) !default;	
$color-invoice-400:							lighten($color-invoice, 5%) !default;
$color-invoice-500:							$color-invoice !default;
$color-invoice-600:							darken($color-invoice, 5%) !default;
$color-invoice-700:							darken($color-invoice, 10%) !default;
$color-invoice-800:							darken($color-invoice, 15%) !default;
$color-invoice-900:							darken($color-invoice, 20%) !default;

/* schedule color */
$color-schedule-50:							lighten($color-schedule, 25%) !default;	
$color-schedule-100:						lighten($color-schedule, 20%) !default;	
$color-schedule-200:						lighten($color-schedule, 15%) !default;	
$color-schedule-300:						lighten($color-schedule, 10%) !default;	
$color-schedule-400:						lighten($color-schedule, 5%) !default;
$color-schedule-500:						$color-schedule !default;
$color-schedule-600:						darken($color-schedule, 5%) !default;
$color-schedule-700:						darken($color-schedule, 10%) !default;
$color-schedule-800:						darken($color-schedule, 15%) !default;
$color-schedule-900:						darken($color-schedule, 20%) !default;

/* product color */
$color-product-50:							lighten($color-product, 25%) !default;	
$color-product-100:							lighten($color-product, 20%) !default;	
$color-product-200:							lighten($color-product, 15%) !default;	
$color-product-300:							lighten($color-product, 10%) !default;	
$color-product-400:							lighten($color-product, 5%) !default;
$color-product-500:							$color-product !default;
$color-product-600:							darken($color-product, 5%) !default;
$color-product-700:							darken($color-product, 10%) !default;
$color-product-800:							darken($color-product, 15%) !default;
$color-product-900:							darken($color-product, 20%) !default;

/* service color */
$color-service-50:							lighten($color-service, 25%) !default;	
$color-service-100:							lighten($color-service, 20%) !default;	
$color-service-200:							lighten($color-service, 15%) !default;	
$color-service-300:							lighten($color-service, 10%) !default;	
$color-service-400:							lighten($color-service, 5%) !default;
$color-service-500:							$color-service !default;
$color-service-600:							darken($color-service, 5%) !default;
$color-service-700:							darken($color-service, 10%) !default;
$color-service-800:							darken($color-service, 15%) !default;
$color-service-900:							darken($color-service, 20%) !default;

/* report color */
$color-report-50:							lighten($color-report, 25%) !default;	
$color-report-100:							lighten($color-report, 20%) !default;	
$color-report-200:							lighten($color-report, 15%) !default;	
$color-report-300:							lighten($color-report, 10%) !default;	
$color-report-400:							lighten($color-report, 5%) !default;
$color-report-500:							$color-report !default;
$color-report-600:							darken($color-report, 5%) !default;
$color-report-700:							darken($color-report, 10%) !default;
$color-report-800:							darken($color-report, 15%) !default;
$color-report-900:							darken($color-report, 20%) !default;

/* note color */
$color-note-50:								lighten($color-note, 25%) !default;	
$color-note-100:							lighten($color-note, 20%) !default;	
$color-note-200:							lighten($color-note, 15%) !default;	
$color-note-300:							lighten($color-note, 10%) !default;	
$color-note-400:							lighten($color-note, 5%) !default;
$color-note-500:							$color-note !default;
$color-note-600:							darken($color-note, 5%) !default;
$color-note-700:							darken($color-note, 10%) !default;
$color-note-800:							darken($color-note, 15%) !default;
$color-note-900:							darken($color-note, 20%) !default;

$system-colors-extended: () !default;
$system-colors-extended: map-merge((
	"customer-50":						$color-customer-50,	
	"customer-100":						$color-customer-100,	
	"customer-200":						$color-customer-200,	
	"customer-300":						$color-customer-300,	
	"customer-400":						$color-customer-400,
	"customer-500":						$color-customer-500,
	"customer-600":						$color-customer-600,
	"customer-700":						$color-customer-700,
	"customer-800":						$color-customer-800,
	"customer-900":						$color-customer-900,
	"appointment-50":					$color-appointment-50,	
	"appointment-100":					$color-appointment-100,	
	"appointment-200":					$color-appointment-200,	
	"appointment-300":					$color-appointment-300,	
	"appointment-400":					$color-appointment-400,
	"appointment-500":					$color-appointment-500,
	"appointment-600":					$color-appointment-600,
	"appointment-700":					$color-appointment-700,
	"appointment-800":					$color-appointment-800,
	"appointment-900":					$color-appointment-900,
	"exam-50":							$color-exam-50,	
	"exam-100":							$color-exam-100,	
	"exam-200":							$color-exam-200,	
	"exam-300":							$color-exam-300,	
	"exam-400":							$color-exam-400,
	"exam-500":							$color-exam-500,
	"exam-600":							$color-exam-600,
	"exam-700":							$color-exam-700,
	"exam-800":							$color-exam-800,
	"exam-900":							$color-exam-900,
	"purchase-50":						$color-purchase-50,	
	"purchase-100":						$color-purchase-100,	
	"purchase-200":						$color-purchase-200,	
	"purchase-300":						$color-purchase-300,	
	"purchase-400":						$color-purchase-400,
	"purchase-500":						$color-purchase-500,
	"purchase-600":						$color-purchase-600,
	"purchase-700":						$color-purchase-700,
	"purchase-800":						$color-purchase-800,
	"purchase-900":						$color-purchase-900,
	"work-order-50":					$color-work-order-50,	
	"work-order-100":					$color-work-order-100,	
	"work-order-200":					$color-work-order-200,	
	"work-order-300":					$color-work-order-300,	
	"work-order-400":					$color-work-order-400,
	"work-order-500":					$color-work-order-500,
	"work-order-600":					$color-work-order-600,
	"work-order-700":					$color-work-order-700,
	"work-order-800":					$color-work-order-800,
	"work-order-900":					$color-work-order-900,
	"referral-50":						$color-referral-50,	
	"referral-100":						$color-referral-100,	
	"referral-200":						$color-referral-200,	
	"referral-300":						$color-referral-300,	
	"referral-400":						$color-referral-400,
	"referral-500":						$color-referral-500,
	"referral-600":						$color-referral-600,
	"referral-700":						$color-referral-700,
	"referral-800":						$color-referral-800,
	"referral-900":						$color-referral-900,
	"invoice-50":						$color-invoice-50,	
	"invoice-100":						$color-invoice-100,	
	"invoice-200":						$color-invoice-200,	
	"invoice-300":						$color-invoice-300,	
	"invoice-400":						$color-invoice-400,
	"invoice-500":						$color-invoice-500,
	"invoice-600":						$color-invoice-600,
	"invoice-700":						$color-invoice-700,
	"invoice-800":						$color-invoice-800,
	"invoice-900":						$color-invoice-900,
	"schedule-50":						$color-schedule-50,	
	"schedule-100":						$color-schedule-100,	
	"schedule-200":						$color-schedule-200,	
	"schedule-300":						$color-schedule-300,	
	"schedule-400":						$color-schedule-400,
	"schedule-500":						$color-schedule-500,
	"schedule-600":						$color-schedule-600,
	"schedule-700":						$color-schedule-700,
	"schedule-800":						$color-schedule-800,
	"schedule-900":						$color-schedule-900,
	"product-50":						$color-product-50,	
	"product-100":						$color-product-100,	
	"product-200":						$color-product-200,	
	"product-300":						$color-product-300,	
	"product-400":						$color-product-400,
	"product-500":						$color-product-500,
	"product-600":						$color-product-600,
	"product-700":						$color-product-700,
	"product-800":						$color-product-800,
	"product-900":						$color-product-900,
	"service-50":						$color-service-50,	
	"service-100":						$color-service-100,	
	"service-200":						$color-service-200,	
	"service-300":						$color-service-300,	
	"service-400":						$color-service-400,
	"service-500":						$color-service-500,
	"service-600":						$color-service-600,
	"service-700":						$color-service-700,
	"service-800":						$color-service-800,
	"service-900":						$color-service-900,	
	"report-50":						$color-report-50,	
	"report-100":						$color-report-100,	
	"report-200":						$color-report-200,	
	"report-300":						$color-report-300,	
	"report-400":						$color-report-400,
	"report-500":						$color-report-500,
	"report-600":						$color-report-600,
	"report-700":						$color-report-700,
	"report-800":						$color-report-800,
	"report-900":						$color-report-900,
	"note-50":							$color-note-50,	
	"note-100":							$color-note-100,	
	"note-200":							$color-note-200,	
	"note-300":							$color-note-300,	
	"note-400":							$color-note-400,
	"note-500":							$color-note-500,
	"note-600":							$color-note-600,
	"note-700":							$color-note-700,
	"note-800":							$color-note-800,
	"note-900":							$color-note-900,
), $system-colors-extended);


/*  Define universal border difition (div outlines, etc)
========================================================================== */
$theme-border-utility-size:				0px;

/*  MOBILE BREAKPOINT & GUTTERS (contains some bootstrap responsive overrides)
========================================================================== */
$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 576px,
	// Medium screen / tablet
	md: 768px,
	// Large screen / desktop
	lg: 992px, // also change 'mobileResolutionTrigger' in app.config.js
	// Decently size screen / wide laptop
	xl: 1399px 
);

$mobile-breakpoint:						lg !default;                               /* define when mobile menu activates, here we are declearing (lg) so it targets the one after it */
$mobile-breakpoint-size:				map-get($grid-breakpoints, lg) !default;   /* bootstrap reference xs: 0,  sm: 544px, md: 768px, lg: 992px, xl: 1200px*/
//$mobile-font-size:						15px; 	                                   /* bigger fontsize for mobile screens */
$grid-gutter-width-base:				3rem;
$grid-gutter-width:						1.5rem;

$grid-gutter-widths: (
	xs: calc($grid-gutter-width-base / 2),         
	sm: calc($grid-gutter-width-base / 2),          
	md: calc($grid-gutter-width-base / 2),        
	lg: calc($grid-gutter-width-base / 2),        
	xl: calc($grid-gutter-width-base / 2)        
);


/* global var used for spacing*/
$spacer:                  1rem;
$spacers: () ;
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 2),
		6: ($spacer * 2.5)
	),
	$spacers
);

/* Uniform Padding variable */
/* Heads up! This is a global scoped variable - changing may impact the whole template */
$p-o:									0.15rem;
$p-h:									0.2rem;
$p-1:									0.25rem;
$p-2:									0.5rem;
$p-3:									1rem;
$p-4:									1.5rem;
$p-5:									2rem;


/*   BOOTSTRAP OVERRIDES (bootstrap variables)
========================================================================== */ 
$grays: (
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900
);

$colors: (
	"blue": $color-primary,
	"red": $color-danger,
	"orange": $color-warning,
	"yellow": $color-warning,
	"green": $color-success,
	"white": $white,
	"gray": $gray-600,
	"gray-dark": $gray-700
);

/* usage: theme-colors("primary"); */
$theme-colors: (
	"primary": $color-primary,
	"secondary": $gray-600,
	"success": $color-success,
	"info": $color-info,
	"warning": $color-warning,
	"danger": $color-danger,
	"light": $white,
	"dark": $fusion-500
);

/* forms */
/*$input-height:							calc(2.25rem + 1px); //I had to add this because the input gruops was having improper height for some reason... */
$input-border-color:					#E5E5E5;
$input-focus-border-color:				#886ab5;
$input-btn-focus-color:					transparent;
$input-padding-y:						.5rem;  
$input-padding-x:						.875rem;
$label-margin-bottom:					.3rem;
$form-group-margin-bottom:				1.5rem;

/* links */
$link-color:							$info-500;
$link-hover-color:						$info-300;

/* checkbox */ 
$custom-control-indicator-size:					1.125rem;
$custom-checkbox-indicator-border-radius:		2px;
$custom-control-indicator-border-width: 		2px;
$custom-control-indicator-bg-size:				0.5rem;

/*$custom-file-height-inner:				calc(2.25rem - 1px);*/
//$custom-file-padding-y:					$input-padding-y;

/* not part of bootstrap variable */
$custom-control-indicator-bg-size-checkbox:  50% 50% !default;

/* custom checkbox */
// the checkbox needs to be a little darker for input groups
$custom-control-indicator-checked-bg:				$primary-600;
$custom-control-indicator-checked-border-color: 	$primary-700;
$custom-control-indicator-checked-disabled-bg:		$primary-100;

$custom-control-indicator-active-bg:				$primary-100; 
$custom-control-indicator-active-border-color:		$primary-100;
$custom-control-indicator-active-color:				$primary-100;

$custom-control-indicator-focus-border-color:		$primary-400;
$custom-select-focus-border-color:					$primary-500;

$custom-checkbox-indicator-indeterminate-border-color: $primary-500;
$custom-checkbox-indicator-indeterminate-bg: $primary-500;


/* custom range */
$custom-range-thumb-width:				1rem;
$custom-range-thumb-border-radius:		50%;
$custom-range-track-height:				0.325rem;
$custom-range-thumb-bg:					$primary-500;
$custom-range-thumb-active-bg:			$primary-300;
$custom-range-thumb-focus-box-shadow:	0 0 0 1px $white, 0 0 0 0.2rem rgba($primary-500, 0.25);

/* custom file */
$custom-file-focus-border-color:		$primary-500;

/* badge */
$badge-font-size:						85%;
$badge-font-weight:						500;

/* cards */
$card-spacer-y:							1rem;
$card-spacer-x:							1rem;
$card-cap-bg:							inherit;
$card-border-color:						rgba(0, 0, 0, 0.08);
$list-group-border-color:				$card-border-color;

$border-color: 							rgba(0,0,0,0.15);

/*border radius*/
$border-radius:							2px;
$border-radius-lg:						$border-radius;
$border-radius-sm:						$border-radius;
$border-radius-plus:					2px;

/* alert */
$alert-padding-y:						.5rem;
$alert-padding-x:						.75rem;
$alert-margin-bottom:					$grid-gutter-width + 0.5rem;

/* toast */
$toast-padding-y:						0.5rem;
$toast-padding-x:						0.75rem;
$toast-header-color:					$fusion-500;

/* breadcrumb */
$breadcrumb-bg:							lighten($fusion-50, 40%);
$breadcrumb-divider-color:				inherit;

/* input button */
$input-btn-padding-y-sm:				.375rem;
$input-btn-padding-x-sm:				.844rem;

$input-btn-padding-y:					.5rem;
$input-btn-padding-x:					1.125rem;

$input-btn-padding-y-lg:				.75rem;
$input-btn-padding-x-lg:				1.5rem;

/* nav link */
$nav-link-padding-y:					$input-btn-padding-y;
$nav-link-padding-x:					$input-btn-padding-x;

/* nav, tabs, pills */
$nav-tabs-border-color:					rgba($black, 0.1);
$nav-tabs-link-active-border-color:		rgba($black, 0.1) rgba($black, 0.1) $white;
$nav-tabs-link-hover-border-color:		rgba($black, 0.07) rgba($black, 0.07) transparent;

/* tables */
$table-border-color:					lighten(desaturate($primary-500, 60%), 35%); //rgba($black, 0.09);
$table-hover-bg:						lighten(desaturate($primary-900, 70%), 63%);
$table-accent-bg:						rgba($fusion-500,.02);
$table-dark-bg:							$fusion-300;
$table-dark-border-color:				$fusion-400;
$table-dark-accent-bg:					rgba($white, .05);
$table-dark-hover-bg:					$color-primary;

/* dropdowns */
$dropdown-border-width:					$theme-border-utility-size; 
$dropdown-padding-y:					.3125rem;
$dropdown-item-padding-y:				.75rem;
$dropdown-item-padding-x:				1.5rem; 
$dropdown-link-active-bg:				lighten($primary-50, 13%);  
$dropdown-link-active-color:			$primary-900;
$dropdown-link-hover-color:				$primary-700;

/* dropdowns sizes */
$dropdown-xl-width:						21.875rem !default;
$dropdown-lg-width:						17.5rem !default;
$dropdown-md-width:						14rem !default;
$dropdown-sm-width:						8rem !default;
$dropdown-shadow:						0 0 15px 1px rgba(desaturate($primary-900, 20%), calc(20/100));   

/* off-canvas sizes */
$off-canvas-xl-width:					30.875rem !default;
$off-canvas-lg-width:					27.5rem !default;
$off-canvas-md-width:					23rem !default;
$off-canvas-sm-width:					17rem !default;
$off-canvas-shadow:						0 0 15px 1px rgba(desaturate($primary-900, 20%), calc(20/100));   

/* timeline sizes */
$timeline-line-width:					2px;
$timeline-no-icon-size:					.5rem;
$timeline-icon-size:					1.8rem;
$timeline-icon-top:						1.2rem;

/* popovers */
$popover-border-color:					rgba(0, 0, 0, 0.2);
$popover-header-padding-y:				1rem;
$popover-header-padding-x:				1rem;
$popover-header-bg:						transparent;
$popover-border-width:					3px;
$popover-arrow-width:					15px;
$popover-arrow-height:					7px;
$popover-arrow-outer-color:				inherit;
$popover-arrow-color:					transparent;
$popover-font-size:						14px;
$popover-box-shadow:					1px 0 13px rgba(90, 80, 105, 0.2);
$popover-border-radius:					0.5rem;

/* tooltips */
$tooltip-max-width:						200px;
$tooltip-color:							$white;
$tooltip-bg:							rgba($fusion-700, 0.9);
$tooltip-border-radius:					5px;
$tooltip-opacity:						1;
$tooltip-padding-y:						.3rem;
$tooltip-padding-x:						.6rem;
$tooltip-margin:						2px;
$tooltip-arrow-width:					8px;
$tooltip-arrow-height:					5px;

/* modal */
$modal-header-padding-y:				1.25rem;
$modal-header-padding-x:				1.25rem;
$modal-header-padding:					$modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
$modal-inner-padding:					1.25rem;
$modal-backdrop-opacity:				0.2;
$modal-content-border-color:			transparent;
$modal-header-border-width:				0px;
$modal-footer-border-width:				0px;

//$modal-lg:								650px;

/* reference guide
http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
8px = 0.5rem
9px = 0.5625rem
10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
15px = 0.9375rem
16px = 1rem (base)
17px = 1.0625rem
18px = 1.125rem
19px = 1.1875rem
20px = 1.25rem
21px = 1.3125rem
22px = 1.375rem
24px = 1.5rem
25px = 1.5625rem
26px = 1.625rem
28px = 1.75rem
30px = 1.875rem
32px = 2rem
34px = 2.125rem
36px = 2.25rem
38px = 2.375rem
40px = 2.5rem
*/

/* Fonts */
$font-size-base:						0.8125rem;
$font-size-lg:							1rem;
$font-size-sm:							0.75rem;
$font-size-xs:							0.65rem;
$line-height-base:						1.47;
$headings-line-height:					1.57;

$h1-font-size:							1.5rem;
$h2-font-size:							1.375rem;
$h3-font-size:							1.1875rem;
$h4-font-size:							1.0625rem;
$h5-font-size:							0.9375rem;
$h6-font-size:							0.875rem;

$display1-size:							5rem;
$display2-size:							4.5rem;
$display3-size:							3.5rem;
$display4-size:							2.5rem;

$navbar-toggler-font-size:				21px;
$navbar-toggler-padding-y:				7.5px; 
$navbar-toggler-padding-x:				18px;

/* carousel */
$carousel-indicator-height:				13px;
$carousel-indicator-width:				13px;

/*  BASE VARS
========================================================================== */
// usage: background-image: url("#{$baseURL}/media/img/bg.png"); 

$baseURL:								"../" !default;
$webfontsURL:							"../webfonts" !default;
$base-text-color:						darken($white,60%) !default;

/* font vars below will auto change to rem values using function rem($value)*/
$fs-base:								13px !default;
$fs-nano:								$fs-base - 2;   /* 11px   */
$fs-xs: 								$fs-base - 1;   /* 12px   */
$fs-sm: 								$fs-base - 0.5; /* 12.5px */
$fs-md: 								$fs-base + 1;   /* 14px   */
$fs-lg: 								$fs-base + 2;   /* 15px   */
$fs-xl: 								$fs-base + 3;   /* 16px   */
$fs-xxl: 								$fs-base + 15;  /* 28px   */

/*  Font Family
========================================================================== */
										/*hint: you can also try the font called 'Poppins' by replacing the font 'Roboto' */
$font-import:							"https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" !default;
$page-font:								"Roboto", 'Helvetica Neue', Helvetica, Arial !default;
$nav-font:								$page-font !default;
$heading-font-family:					$page-font !default; 
$mobile-page-font:						'HelveticaNeue-Light','Helvetica Neue Light','Helvetica Neue',Helvetica,Arial,sans-serif;

/*  ANIMATIONS
========================================================================== */
$nav-hide-animate: 						all 470ms cubic-bezier(0.34, 1.25, 0.3, 1) !default;		/* this addresses all animation related to nav hide to nav minify */

/*  Z-INDEX declearation
========================================================================== */
$space:									1000 !default;
$cloud:									950 !default;
$ground:								0 !default;
$water:									-99 !default;
/* we adjust bootstrap z-index to be higher than our higest z-index*/
$zindex-dropdown:						$space + 1000;
$zindex-sticky:							$space + 1020;
$zindex-fixed:							$space + 1030;
$zindex-modal-backdrop:					$space + 1040;
$zindex-modal:							$space + 1050;
$zindex-panel-fullscreen:				$space + 1055;
$zindex-popover:						$space + 1060;
$zindex-tooltip:						$space + 1070;

/*  CUSTOM ICON PREFIX 
========================================================================== */
$cust-icon-prefix:						ni;

/*  PRINT CSS (landscape or portrait)
========================================================================== */
$print-page-type: 						portrait; 												  /* landscape or portrait */
$print-page-size:						letter;													  /* auto, letter */
$print-page-margin:						1.0cm;

/*  Common Element Variables
========================================================================== */
$body-background-color:					$white !default;
$page-bg:								#f9f9fc !default; //#f9f9fc
// $page-bg:								desaturate(lighten($primary-500, 41.7%), 5%)  !default; //#f9f9fc

/* Z-index decleartion "birds eye view"
========================================================================== */
$depth:									999 !default;
$depth-header:							$depth + 1 !default;
$depth-nav:								$depth-header + 2 !default;

/*  Components
========================================================================== */
$frame-border-color:					#f7f9fa !default;

/*  PAGE HEADER STUFF
========================================================================== */

/* colors */
$header-bg:								$white !default;
$header-border-color:					#ccc !default;
$header-border-bottom-color:			rgba(darken($primary-700, 10%), calc(30/100)) !default;		
$header-link-color:						$primary-500 !default;
$header-link-hover-color:				darken($header-bg, 75%) !default;

/* height */
$header-height:							4.125rem !default;
$header-height-nav-top:					4.125rem !default;
$header-inner-padding-x:				2rem !default;
$header-inner-padding-y:				0 !default;

/* logo */
$header-logo-border-bottom:				rgba(darken($primary-700, 10%), calc(30/100)) !default;
$header-logo-width:						28px !default; 										  /* try not to go beywond the width of $main_nav_width value */
$header-logo-height:					28px !default; 										  /* you may need to change this depending on your logo design */
$header-logo-text-align:				center; 											  /* adjust this as you see fit : left, right, center */

/* icon font size (not button) */
$header-icon-size:						21px;

/* search input box */
$header-search-border-color:			transparent !default;								  /* suggestion: #ccced0*/
$header-search-bg:						transparent !default;
$header-search-width:					25rem !default;
$header-search-height:					$header-height - 1.5rem !default; 
$header-search-font-size:				$fs-base + 2;
$header-search-padding:					$spacer * 0.38;

/* btn */
$header-btn-active-bg:					$fusion-500 !default;
$header-btn-color:						darken($header-bg, 35%) !default;
$header-btn-hover-color:				$header-link-hover-color !default;
$header-btn-active-color:				$white !default;
$header-btn-height: 					calc($header-height/2 + 0.1875rem) !default;
$header-btn-width: 						3.25rem !default;
$header-btn-font-size:					21px !default; //works only for font icons
$header-btn-border-radius:				$border-radius !default;
$header-non-btn-width:					3.125rem !default;
$header-dropdown-arrow-color:			$primary-700 !default;

/* dropdown: app list */
$header-applist-link-block-height:		5.9375rem;
$header-applist-link-block-width:		6.25rem;
$header-applist-rows-width:				21.875rem;
$header-applist-rows-height:			22.5rem; 
$header-applist-box-padding-x:			$p-2;
$header-applist-box-padding-y:			$p-3;
$header-applist-icon-size:				3.125rem;

/* badge */
$header-badge-min-width:				1.25rem !default;
$header-badge-left:						1.5625rem !default;
$header-badge-top:						calc(($header-height / 2 - $header-badge-min-width) + 0.28125rem) !default; 

/* COMPONENTS & MODS */
$nav-tabs-clean-link-height:			45px !default;

/*  NAVIGATION STUFF

Guide:

aside.page-sidebar ($nav-width, $nav-background)
	.page-logo
	.primary-nav
		.info-card
		ul.nav-menu
			li
				a (parent level-0..., $nav-link-color, $nav-link-hover-color, $nav-link-hover-bg-color, $nav-link-hover-left-border-color)
					icon 
					span
					collapse-sign 
					
				ul.nav-menu-sub-one  
					li
						a ($nav-level-1... $nav-sub-link-height)
							span
							collapse-sign

						ul.nav-menu-sub-two
							li
								a ($nav-level-2... $nav-sub-link-height)
									span

		p.nav-title ($nav-title-*...)


========================================================================== */

/* main navigation */
/* left panel */
$nav-background:						#383c47; //desaturate($alt-500, 7%) !default;
$nav-background-shade:					rgba(desaturate($info-500, 15%), 0.18) !default;                  
$nav-base-color:						lighten($nav-background, 7%) !default;
$nav-width:								16.875rem !default; 

/* nav footer */
$nav-footer-link-color:					lighten($nav-background, 25%) !default;

/* nav parent level-0 */
$nav-link-color: 						lighten($nav-base-color, 32%) !default;
$nav-font-link-size: 					$fs-base + 1 !default;
$nav-collapse-sign-font-size:			inherit !default;	
$nav-padding-x:							2rem !default; 
$nav-padding-y:							0.8125rem !default;


/* nav link level-1 */
$nav-link-color-child: 					darken($nav-link-color, 5%);
$nav-link-color-child-hover:			$white;

/* nav level-1 bg */
$nav-ul-ul-bg:							rgba($black,0.1);
$nav-ul-padding-top:					10px;
$nav-ul-padding-bottom:					10px;

/* nav icon sizes */
$nav-font-icon-size:					1.125rem !default; //23px for Fontawesome & 20px for NextGen icons
$nav-font-icon-size-sub:				1.125rem !default;

$nav-icon-width:						1.75rem !default;
$nav-icon-margin-right:					0.25rem !default;

/* badge default */
$nav-badge-color: 						$white !default;
$nav-badge-bg-color: 					$danger-500 !default;

/* all child */
$nav-icon-color:						lighten(darken($nav-base-color, 15%),27%) !default;
$nav-icon-hover-color:					lighten(desaturate($color-primary, 30%), 10%) !default;

/* nav title */
$nav-title-color: 						lighten($nav-base-color, 10%) !default;
$nav-title-border-bottom-color: 		lighten($nav-base-color, 3%) !default;
$nav-title-font-size: 					$fs-base - 1.8px;

/* nav Minify */
$nav-minify-hover-bg:					darken($nav-base-color, 3%) !default;
$nav-minify-hover-text:					$white !default;
$nav-minify-width:						4.6875rem !default;
/* when the menu pops on hover */
$nav-minify-sub-width:					$nav-width - ($nav-minify-width - 1.5625rem) !default; 				

/* navigation Width */
/* partial visibility of the menu */
$nav-hidden-visiblity:					0.625rem !default; 											

/* top navigation */
$nav-top-height:						3.5rem !default;
$nav-top-drowndown-width:				13rem !default;
$nav-top-drowndown-background:			$nav-base-color;
$nav-top-drowndown-hover:				rgba($black, 0.1);;
$nav-top-drowndown-color:				$nav-link-color;
$nav-top-drowndown-hover-color:			$white;

/* nav Info Card (appears below the logo) */
$nav-infocard-height:					9.530rem !default;
$profile-image-width:					3.125rem !default; 
$profile-image-width-lg:				4.75rem !default;
$profile-image-width-md:				2rem !default;
$profile-image-width-sm:				1.5625rem !default;
$image-share-height:					2.8125rem !default; /* width is auto */

/* nav DL labels for all child */
$nav-dl-font-size:						0.625rem !default;
$nav-dl-width:							1.25rem !default;
$nav-dl-height:							1rem !default;
$nav-dl-margin-right:					0.9375rem !default;
$nav-dl-margin-left:					$nav-dl-width + $nav-dl-margin-right !default; 	/* will be pulled to left as a negative value */

/*   MISC Settings
========================================================================== */
/* List Table */
$list-table-padding-x:					11px !default;
$list-table-padding-y:					0 !default;

/*   PAGE SETTINGS
========================================================================== */
$settings-incompat-title:				var(--theme-warning-900) !default;
$settings-incompat-desc:				var(--theme-warning-900) !default;
$settings-incompat-bg:					var(--theme-warning-50) !default;
$settings-incompat-border:				var(--theme-warning-700) !default;

/*   PAGE BREADCRUMB 
========================================================================== */
$page-breadcrumb-maxwidth:				200px;

/*   PAGE COMPONENT PANELS 
========================================================================== */
$panel-spacer-y:						1rem;
$panel-spacer-x:						1rem;
$panel-hdr-font-size:					14px;
$panel-hdr-height:						3rem;
$panel-btn-size:						1rem;
$panel-btn-spacing:						0.3rem;
$panel-toolbar-icon:					1.5625rem;
$panel-hdr-background:					$white; //#fafafa;
$panel-edge-radius:						$border-radius;
$panel-placeholder-color:				lighten(desaturate($primary-50, 20%), 10%);

$panel-btn-icon-width:					2rem;
$panel-btn-icon-height:					2rem;
$panel-btn-icon-font-size:				1rem;

/*   PAGE COMPONENT PROGRESSBARS 
========================================================================== */
$progress-height:						.75rem;
$progress-font-size:					.625rem;
$progress-bg:							lighten($fusion-50, 40%);
$progress-border-radius:				10rem;

/*   PAGE COMPONENT MESSENGER 
========================================================================== */
$msgr-list-width:						14.563rem;
$msgr-list-width-collapsed:				3.125rem;
$msgr-get-background:					#f1f0f0;
$msgr-sent-background:					$success-500;
$msgr-animation-delay:					100ms;

/*   FOOTER
========================================================================== */
$footer-bg:								$white !default;
$footer-text-color:						darken($base-text-color, 10%);
$footer-height:							2.8125rem !default;
$footer-font-size:						$fs-base !default;
$footer-zindex:							$cloud - 20 !default;

/*   GLOBALS
========================================================================== */
$mod-main-boxed-width:					map-get($grid-breakpoints, xl);
$slider-width:							15rem;

/* ACCESSIBILITIES */
$enable-prefers-reduced-motion-media-query:   false;

/* SHORTCUT BUTTON (appears on bottom right of the page) */
$app-shortcut-btn-size: 49px;
$menu-item-size: 45px;
$menu-items:5;
$menu-grid-icon: 5px;
$menu-item-direction: 'top'; //top or left


/* GULP WARNINGS */
$ignore-warning: true;