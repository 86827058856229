.dropdown {
    position: relative;

    & .dropdown-menu {
        background: #fff;
        border-radius: $border-radius;
        box-shadow: 0 0.05rem 0.2rem rgba(48, 55, 66, .3);
        list-style: none;
        margin: 0;
        min-width: 180px;
        padding: $p-2;
        transform: translateY(.2rem);
        z-index: 300;
        animation: slide-down .15s ease 1;
        display: none;
        left: 0;
        max-height: 50vh;
        overflow-y: auto;
        position: absolute;
        top: 100%;

        & .dropdown-menu-item {
            margin-top: 0;
            padding: 0 $p-1;
            position: relative;
            text-decoration: none;
        }
    }

    & .dropdown-toggle {
        &:nth-last-child(2) {
            border-bottom-right-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        &:focus {
            &+.dropdown-menu {
                display: block;
            }
        }
    }

    &:hover,
    &.active {
        & .dropdown-menu {
            display: block;
        }
    }
}