@import '../../styles/_modules/variables.scss';

.confirm-modal {
    & .modal-body {
        & .bootbox-body {
            & .description {
                &.warning-success {
                    &>strong {
                        color: $color-success;
                    }
                }
                &.warning-warning {
                    &>strong {
                        color: $color-warning;
                    }
                }
                &.warning-info {
                    &>strong {
                        color: $color-info;
                    }
                }
                &.warning-danger {
                    &>strong {
                        color: $color-danger;
                    }
                }
                &.warning-primary {
                    &>strong {
                        color: $color-primary;
                    }
                }
                &.warning-flashing {
                    &>strong {
                        animation: flashing 1s linear infinite;

                        &.slow {
                            animation: flashing 1.5s linear infinite;
                        }

                        &.slower {
                            animation: flashing 2s linear infinite;
                        }

                        &.slowest {
                            animation: flashing 2.5s linear infinite;
                        }

                        &.fast {
                            animation: flashing .75s linear infinite;
                        }

                        &.faster {
                            animation: flashing .5s linear infinite;
                        }

                        &.fastest {
                            animation: flashing .25s linear infinite;
                        }

                        &.flash-2x {
                            animation-iteration-count: 2 !important;
                        }

                        &.flash-3x {
                            animation-iteration-count: 3 !important;
                        }

                        &.flash-4x {
                            animation-iteration-count: 4 !important;
                        }

                        &.flash-5x {
                            animation-iteration-count: 5 !important;
                        }
                    }
                }
            }
        }
    }
}