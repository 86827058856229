@import '~react-quill/dist/quill.snow.css';
@import '~react-quill/dist/quill.bubble.css';

.quill {
    width: 100%;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    transition: box-shadow ease-in-out .15s, border-color ease-in-out .15s;

    &.focus {
        transition: box-shadow ease-in-out .15s, border-color ease-in-out .15s;
        border-color: $input-focus-border-color;

        & .ql-container {
            border-color: transparent !important;
        }
    }

    &.ql-disabled {
        &.focus {
            transition: none;
            border-color: $input-border-color;    
        }
    }

    &.quill-no-toolbar {
        & .ql-toolbar {
            display: none;
        }

        & .ql-container {
            border: 1px solid transparent !important;
        }

        &.focus {
            & .ql-container {
                border-top-color: transparent !important;
            }
        }
    }

    &.quill-no-border {
        border: 1px solid transparent !important;

        & .ql-container {
            border: 1px solid transparent !important;
        }

        &.focus {
            & .ql-container {
                border-top-color: transparent !important;
            }
        }
    }

    & .ql-toolbar {
        &.ql-snow {
            border: none;
            border-bottom: 1px solid $input-border-color;
            background-color: $white;
        }
    }

    & .ql-container {
        border-radius: $border-radius;
        border: 1px solid transparent !important;

        &.ql-snow,
        &.ql-bubble {

            & .ql-editor {
                height: 85px;
                font-family: $page-font;
                font-size: $font-size-base;
                line-height: $line-height-base;
                background-color: $white;
                padding: $input-padding-y $input-padding-x;
                color: $gray-800;

                @include thin-scroll();

                &.ql-blank {
                    &::before {
                        font-style: normal;
                        color: $gray-500;
                        left: auto;
                        right: auto;
                    }
                }
            }
        }
    }

    &.quill-sm,
    &.quill-small {
        & .ql-container {
            &.ql-snow,
            &.ql-bubble {
                & .ql-editor {
                    height: auto;
                    min-height: calc(1.47em + 1rem + 2px);
                    max-height: calc((1.47em + 1rem + 2px) * 2);
                    font-size: $font-size-sm;
                }
            }
        }

        &.quill-mobile {
            & .ql-container {
                &.ql-snow {
                    & .ql-editor {
                        max-height: calc(1.47em + 1rem + 2px);
                    }
                }
            }
        }
    }

    &.quill-lg,
    &.quill-large {
        & .ql-container {
            &.ql-snow {
                & .ql-editor {
                    max-height: calc((1.47em + 1rem + 2px) * 3);
                }
            }
        }

        &.quill-mobile {
            & .ql-container {
                &.ql-snow {
                    & .ql-editor {
                        max-height: calc(1.47em + 1rem + 2px);
                    }
                }
            }
        }
    }

    &.input-sm {
        & .ql-container {
            &.ql-snow {
                & .ql-editor {
                    font-size: $font-size-sm;
                    padding: $input-padding-y-sm $input-padding-x-sm;
                }
            }
        }
    }
}

.has-error {
    & .quill {
        border-color: $color-danger;

        &.focus {
            
        }
    }
}

@media (max-width: map-get($grid-breakpoints, lg)) {
    .quill {
        & .ql-container {
            &.ql-snow {
                & .ql-editor {
                    font-size: $font-size-lg;
                }
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .quill {
        & .ql-container {
            &.ql-snow {
                & .ql-editor {
                    font-size: $font-size-base;
                }
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .quill {
        & .ql-container {
            &.ql-snow {
                & .ql-editor {
                    font-size: $font-size-sm;
                }
            }
        }
    }
}