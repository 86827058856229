@mixin btn-toggle ($name, $value) {
    &.btn-toggle-#{$name} {
        background-color: $white;
        border-color: $value;
        color: $value;

        & i,
        & [class*="fa-"] {
            color: $value;
        }

        &.active,
        &:active {
            background-color: $value;
            color: $white;

            & i,
            & [class*="fa-"] {
                color: $white;
            }
        }
    }
}

@mixin btn-variant ($name, $bg, $font: $white) {
    &.btn-#{$name} {
        color: $font;
        background-color: $bg;
        border-color: $bg;

        &:active,
        &:hover,
        &:focus,
        &.focus {
            background-color: darken($bg, 8%);
            border-color: darken($bg, 10%);
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 0.2rem rgba(darken($bg, 15%), 0.5);
        }

        &.disabled,
        &:disabled {
            background-color: $bg;
            border-color: $bg;
        }

        &:not(:disabled):not(.disabled) {

            &:active,
            &.active {
                background-color: lighten($bg, 15%);
                border-color: lighten($bg, 15%);

                &:focus,
                &.focus {
                    box-shadow: 0 0 0 0.2rem rgba(darken($bg, 15%), 0.5);
                }
            }
        }
    }
}

.btn {
    box-shadow: none !important;

    &.btn-link {
        font-size: $font-size-base;

        &:hover {
            text-decoration: none;
        }

        &:active {
            box-shadow: none !important;
        }
    }

    &.btn-icon {
        position: relative;

        &>.badge {
            &.badge-icon {
                position: absolute;
                right: 0;
                left: auto;
            }
        }

        & .icon-stack {
            display: block;
            width: 100%;

            & i {
                top: -3px;
                font-size: 1.2rem;

                &.icon-stack-2x {
                    font-size: .9rem;
                }

                &.icon-stack-1x {
                    font-size: .7rem;
                }
            }
        }
    }

    &.btn-facebook,
    &.btn-google,
    &.btn-instagram,
    &.btn-microsoft,
    &.btn-yahoo {
        position: relative;
        color: #fff;
        padding: $p-3;

        &:before {
            color: $white;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            padding: $p-2 $p-4;
            vertical-align: middle;
        }

        &>.icon-gap {
            margin-left: 30px;
        }
    }

    &.btn-facebook {
        background: $facebook-color;
        border: $border-width solid $facebook-color;
        padding: 3px 3px 3px 2px;

        &:before {
            content: '';
            background-image: url('/media/img/flogo_RGB_HEX-114.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-origin: content-box;
            padding: 4px;
            border-right: $border-width solid darken($facebook-color, 7%);
        }

        &:focus,
        &:hover {
            background: darken($facebook-color, 2%);
            border-color: darken($facebook-color, 5%);
            color: $white;
            text-decoration: none;
        }

        &:active,
        &.active {
            background: darken($facebook-color, 4%);
            border-color: darken($facebook-color, 7%);
            color: $white;
            text-decoration: none;

            &.loading {
                &::after {
                    border-bottom-color: $white;
                    border-left-color: $white;
                }
            }
        }
    }

    &.btn-google {
        background: $google-color;
        border: $border-width solid $google-color;

        &:before {
            content: '';
            background-image: url('/media/img/btn_google_dark_normal_ios.svg');
            background-size: 100% 100%;
            border-right: $border-width solid darken($google-color, 7%);
        }

        &:focus,
        &:hover {
            background: darken($google-color, 2%);
            border-color: darken($google-color, 5%);
            color: $white;
            text-decoration: none;
        }

        &:active,
        &.active {
            background: darken($google-color, 4%);
            border-color: darken($google-color, 7%);
            color: $white;
            text-decoration: none;

            &.loading {
                &::after {
                    border-bottom-color: $white;
                    border-left-color: $white;
                }
            }
        }
    }

    &.btn-instagram {
        background: $instagram-color;
        border: $border-width solid $instagram-color;
        padding: 3px 3px 3px 2px;

        &:before {
            content: '';
            background-image: url('/media/img/instagram-11.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-origin: content-box;
            padding: 4px;
            border-right: $border-width solid lighten($instagram-color, 27%);
        }

        &:focus,
        &:hover {
            background: lighten($instagram-color, 22%);
            border-color: lighten($instagram-color, 25%);
            color: $white;
            text-decoration: none;
        }

        &:active,
        &.active {
            background: lighten($instagram-color, 24%);
            border-color: lighten($instagram-color, 27%);
            color: $white;
            text-decoration: none;

            &.loading {
                &::after {
                    border-bottom-color: $white;
                    border-left-color: $white;
                }
            }
        }
    }

    &.btn-microsoft {
        background: $microsoft-color;
        border: $border-width solid $microsoft-color;

        &:before {
            content: '';
            background-image: url('/media/img/Microsoft_logo.svg');
            background-size: 100% 100%;
            border-right: $border-width solid darken($microsoft-color, 7%);
        }

        &:focus,
        &:hover {
            background: darken($microsoft-color, 2%);
            border-color: darken($microsoft-color, 5%);
            color: $white;
            text-decoration: none;
        }

        &:active,
        &.active {
            background: darken($microsoft-color, 4%);
            border-color: darken($microsoft-color, 7%);
            color: $white;
            text-decoration: none;

            &.loading {
                &::after {
                    border-bottom-color: $white;
                    border-left-color: $white;
                }
            }
        }
    }

    &.btn-yahoo {
        background: $yahoo-color;
        border: $border-width solid $yahoo-color;

        &:before {
            content: '';
            background-image: url('/media/img/Yahoo_Y.svg');
            background-size: 100% 100%;
            border-right: $border-width solid darken($yahoo-color, 7%);
        }

        &:focus,
        &:hover {
            background: darken($yahoo-color, 2%);
            border-color: darken($yahoo-color, 5%);
            color: $white;
            text-decoration: none;
        }

        &:active,
        &.active {
            background: darken($yahoo-color, 4%);
            border-color: darken($yahoo-color, 7%);
            color: $white;
            text-decoration: none;

            &.loading {
                &::after {
                    border-bottom-color: $white;
                    border-left-color: $white;
                }
            }
        }
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
    }


    &.dropdown-toggle:after,
    &.dropleft .dropdown-toggle:before {
        text-align: center;
        display: inline-block;
        border: 0 !important;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: "\f0dd" !important;
        vertical-align: top !important;
        position: relative;
        top: -2px;
    }

    @include btn-toggle('primary', $color-primary);
    @include btn-toggle('danger', $color-danger);
    @include btn-toggle('warning', $color-warning);
    @include btn-toggle('info', $color-info);
    @include btn-toggle('success', $color-success);
    @include btn-toggle('default', $gray-700);

    @include btn-toggle('customer', darken($color-customer, 25%));
    @include btn-toggle('appointment', darken($color-appointment, 25%));
    @include btn-toggle('exam', darken($color-exam, 25%));
    @include btn-toggle('purchase', darken($color-purchase, 25%));
    @include btn-toggle('work-order', darken($color-work-order, 25%));
    @include btn-toggle('product', darken($color-product, 25%));
    @include btn-toggle('report', darken($color-report, 25%));
    @include btn-toggle('note', darken($color-note, 25%));

    @include btn-variant('customer', darken($color-customer, 25%));
    @include btn-variant('appointment', darken($color-appointment, 25%));
    @include btn-variant('exam', darken($color-exam, 25%));
    @include btn-variant('purchase', darken($color-purchase, 25%));
    @include btn-variant('work-order', darken($color-work-order, 25%));
    @include btn-variant('product', darken($color-product, 25%));
    @include btn-variant('report', darken($color-report, 25%));
    @include btn-variant('note', darken($color-note, 25%));

    @include btn-variant('primary', $color-primary);
    @include btn-variant('success', $color-success);
    @include btn-variant('info', $color-info);
    @include btn-variant('warning', $color-warning);
    @include btn-variant('danger', $color-danger);
    @include btn-variant('alt', $color-alt);
}

.btn-group {
    &.dropup .dropdown-toggle:after {
        top: 3px;
        content: "\f0de" !important;
    }

    &.dropright .dropdown-toggle:after {
        top: 0px;
        right: 2px;
        @include rotate (270);
    }

    &.dropleft .dropdown-toggle:before {
        top: 0px;
        @include rotate (90);
    }
}