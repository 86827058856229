@import '../../styles/_modules/variables.scss';
@import '../../styles/_mixins/mixins';

.prescription-history-list {
    min-width: 150px;
    min-height: 93px;

    & .sticky-table {
        border-bottom: 1px solid $gray-300;
        border-radius: $border-radius;
        @include thin-scroll();

        & .sticky-table-table {
            min-width: 100%;

            & .sticky-table-row {
                & .sticky-table-cell {
                    border-bottom: 1px solid $gray-300;
                    min-width: 50px;
                    max-width: 200px;
                    padding: $p-2;
                    padding-right: $p-4;

                    &:first-child {
                        border-left: 1px solid $gray-300;
                    }

                    &:last-child {
                        border-right: 1px solid $gray-300;
                    }

                    &>div:not(.dropleft) {
                        white-space: pre-line;
                    }

                    &.sticky-table-action {
                        border-left: none;

                        & .dropleft {
                            & .dropdown-menu {
                                top: $p-1 * -3;
                            }
                        }
                    }
                    
                    & p {
                        margin-bottom: 0;
                        line-height: $line-height-base;
                    }
                }

                &:first-child {
                    & .sticky-table-cell {
                        background: $gray-100;
                        color: $gray-600;
                        font-weight: 500;
                        border-top: 1px solid $gray-300;
                    }
                }

                &:last-child {
                    & .sticky-table-cell {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    &.empty-table {

        & .empty-row {
            display: table-row;
            position: relative;
            width: 100%;

            & div {
                display: block;
                position: absolute;
                width: 100%;
                padding: $p-2;
                border: 1px solid $table-border-color;
                border-top: none;
                border-bottom: none;
            }
        }
    }
}

.prescription-list-wrapper {
    border: 1px solid $gray-300;

    & .prescription-history-list {
        margin: -1px;

        &.empty-table {
            & .sticky-table {
                border-bottom: none;
            }
        }
    }
}