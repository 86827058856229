@import '../../../../styles/_modules/variables';

.new-business-hours {
    & .business-hours {
        & ul {
            & li {
                font-size: $font-size-sm;
                &.time {
                    width: 115px;

                    & .rw-datetime-picker {
                        & .rw-widget-container {
                            & .rw-widget-input.rw-input {
                                text-transform: lowercase;
                                font-size: $font-size-sm;
                            }
                        }
                    }
                }
            }
        }
    }
}