// Toast variant mixin
@mixin custom-toast-variant($color: $dark-color) {
    background: rgba($color, .9);
    color: $white;
    border-color: $color;
}

@mixin custom-toast-border-variant($color: $dark-color) {
    background: $white !important;
    color: $color !important;
    border-color: $color !important;

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color !important;
    }
}

.Toastify__toast-container {
    &.toastify-wrapper {
        & .toastify-toast {
            border-radius: $border-radius;
            font-family: $page-font;
            font-size: .9rem;
            min-height: auto;
            max-height: 300px;
            padding: $p-3 $p-4;
            margin: 0 auto;
            margin-top: $p-2;

            &.Toastify__toast--info {
                @include custom-toast-variant($info-900);
            }

            &.Toastify__toast--success {
                @include custom-toast-variant($color-success);
            }

            &.Toastify__toast--warning {
                @include custom-toast-variant($color-warning);
            }

            &.Toastify__toast--error {
                @include custom-toast-variant($danger-900);
            }

            &.Toastify__toast--dark {
                @include custom-toast-variant(#000);
            }

            & .toastify-body {
                margin: 0;

                & strong {
                    font-weight: bold;
                }

                & p {
                    margin: 0;
                }
            }

            & .toastify-progress {
                height: 3px;
                background: rgba($white, .5);
            }

            & .Toastify__close-button {
                position: absolute;
                top: .9rem;
                right: $p-4;
            }
        }
    }
}