@import '../../../styles/_modules/variables';

.reactivate-customer {
    & .customer {
        & .profile-wrapper {
            display: flex;
            flex-direction: row;
            padding: $p-2;
            padding-left: 0;
            padding-top: 0;
            cursor: pointer;
            transition: background-color linear .2s;

            & .profile {
                margin-right: $p-2;
            }

            & .description {
                display: flex;
                flex-direction: column;
                justify-content: center;

                & .name {
                    font-size: $font-size-lg;
                    font-weight: normal;
                }

                & .info {
                    color: $gray-600;

                    & address {
                        margin-bottom: 0;

                        & span {
                            white-space: nowrap;

                            &:after {
                                content: ' ';
                            }

                            &.locality,
                            &.region {
                                &:after {
                                    content: ', ';
                                }
                            }

                            &.line1,
                            &.line2 {
                                display: block;
                            }

                            &.country {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}