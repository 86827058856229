@import '../../styles/_modules/variables';

.searchbar {
    flex: 1;

    &>i {
        position: absolute;
        top: 50%;
        margin-top: $font-size-base * -.5;
        opacity: 0;
        z-index: 1;
        transition: opacity linear .15s;

        &:last-child {
            z-index: 2;
        }

        &.active {
            cursor: pointer;
            opacity: 1;
            transition: opacity linear .15s;
            z-index: 3;
        }
    }

    & .dropdown {
        & .form-control {
            background: 0 0;
            border: 1px solid transparent;
            // max-width: $header-search-width - 3.125rem;
            max-width: 100%;
            height: $header-search-height;
            line-height: normal;
            border-radius: $header-btn-border-radius;
            background: $header-search-bg;
            border: 1px solid $header-search-border-color;
            box-shadow: none;
            font-size: rem($header-search-font-size);
            padding: $header-search-padding;
            margin-left: $p-4;
        }

        & .dropdown-menu {
            width: 100%;
            // max-width: $header-search-width - 3.125rem;
            max-width: $header-search-width;
            padding: 0;
            margin-top: .5rem;
            margin-left: $p-2 * -1;
            border-top: 1px solid $white;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background-color: transparent;
            box-shadow: none;

            &.active {
                padding-top: $p-2;
                margin-top: .59rem;
                background-color: $white;
                box-shadow: 0 0.05rem 0.2rem rgba(48, 55, 66, .3);
            }

            & .dropdown-menu-item {
                padding: $p-2;
                background-color: $white;
                transition: background-color linear .15s;

                & a {
                    display: block;

                    & .profile-wrapper {
                        padding: 0;

                        & .name {
                            color: $gray-700;
                            font-size: $font-size-base;
                        }

                        & .description {
                            font-size: $font-size-sm;
                        }
                    }
                }

                &.active,
                &:hover {
                    background-color: $gray-200;
                    transition: background-color linear .15s;
                }

                &:last-child {
                    margin-bottom: $p-2;
                }
            }
        }

        & .dropdown-hide {
            position: absolute;
            width: 100%;
            // max-width: $header-search-width - 3.125rem;
            max-width: $header-search-width;
            height: 0;
            z-index: 1000;
            top: 55px;
            background-color: $white;
            border-top: 1px solid $header-search-border-color;
            opacity: 0;
            transition: opacity linear .15s;

            &.active {
                height: 1px;
                opacity: 1;
                transition: opacity linear .15s;
            }
        }

        &:hover {
            & .dropdown-menu {
                &:not(.active) {
                    display: none;
                }
            }
        }
    }
}

.searchbar-overlay {
    position: absolute;
    top: -99999px;
    left: 0;
    right: 0;
    z-index: 30;
    opacity: 0;
    background: rgba($black, 0.5);
    transition: opacity linear .15s;

    &.active {
        top: 0;
        bottom: 0;
        opacity: 1;
        transition: opacity linear .15s;
    }
}

// @import '../../styles/variables';
// @import 'node_modules/spectre.css/src/mixins/shadow';

// .searchbar {
//     position: relative;
//     display: flex;
//     align-items: center;
//     width: 100%;
//     height: 100%;
//     border-right: $border-width solid $border-color;
//     transition: box-shadow linear .2s;

//     &.active {
//         @include shadow-variant($unit-2);
//         transition: box-shadow linear .2s;
//     }

//     &>i.material-icons {
//         position: absolute;
//         margin-left: $unit-4;
//         z-index: 3;
//         cursor: pointer;
//     }

//     & .dropdown {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         z-index: 2;

//         & .searchbar-input {
//             width: 100%;
//             height: 100%;
//             border: none;
//             padding-left: $unit-14;
//             padding-right: $unit-4;

//             &:focus,
//             &:active {
//                 outline: none;
//             }
//         }

//         & .menu {
//             width: 100%;
//             margin-top: -3px;

//             & .menu-item {
//                 transition: background-color linear .2s;

//                 &.active {
//                     background-color: rgba($primary-color, .1) !important;
//                     transition: background-color linear .2s;
//                 }

//                 & a {
//                     display: flex;
//                     flex-direction: row;
//                     padding: $unit-2;
//                     cursor: pointer;
//                     transition: background-color linear .2s;

//                     &:hover,
//                     &:active,
//                     &:focus {
//                         background-color: rgba($gray-color-lightest, .5) !important;
//                         transition: background-color linear .2s;
//                     }

//                     & .profile {
//                         margin-right: $unit-2;
//                     }

//                     & .description {
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: center;

//                         & .name,
//                         & .info {
//                             font-size: $font-size-sm;

//                             &.not-ready {
//                                 background-color: $gray-color-lightest;
//                                 height: 10px;
//                             }
//                         }

//                         & .name {
//                             color: $gray-color-darker;
//                             font-weight: normal;
//                         }

//                         & .info {
//                             color: $gray-color-lighter;

//                             &.not-ready {
//                                 width: 80px;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }