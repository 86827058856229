@mixin text-color-variant ($name, $value) {
    .#{$name} {
        color: $value !important;
        transition: color linear .15s;
    }
}

@mixin bg-color-variant ($name, $value) {
    .#{$name} {
        background-color: $value !important;
        transition: background-color linear .15s;
    }
}

@mixin bg-color-hover-variant ($name, $value) {
    .#{$name}:hover,
    .#{$name}:active {
        background-color: $value !important;
        transition: background-color linear .15s;
    }
}

@mixin border-color-variant ($name, $value) {
    .#{$name} {
        border-color: $value !important;
    }
}

@mixin text-color-hover-variant ($name, $value) {
    .#{$name}:hover,
    .#{$name}:active {
        color: $value !important;
        transition: color linear .15s;
    }
}

@each $extra-colors-text, $value in $extra-colors-extended {
    @include text-color-variant("text-#{$extra-colors-text}", $value);
    @include text-color-hover-variant("text-#{$extra-colors-text}-hover", $value);
    @include bg-color-variant("bg-#{$extra-colors-text}", $value);
    @include bg-color-hover-variant("bg-#{$extra-colors-text}-hover", $value);
    @include border-color-variant("border-#{$extra-colors-text}", $value);    
}

@each $system-colors-text, $value in $system-colors-extended {
    @include text-color-variant("text-#{$system-colors-text}", $value);
    @include text-color-hover-variant("text-#{$system-colors-text}-hover", $value);
    @include bg-color-variant("bg-#{$system-colors-text}", $value);
    @include bg-color-hover-variant("bg-#{$system-colors-text}-hover", $value);
    @include border-color-variant("border-#{$system-colors-text}", $value);
}

@each $theme-colors-text, $value in $theme-colors-extended {
    @include text-color-variant("text-#{$theme-colors-text}", $value);
    @include text-color-hover-variant("text-#{$theme-colors-text}-hover", $value);
    @include bg-color-variant("bg-#{$theme-colors-text}", $value);
    @include bg-color-hover-variant("bg-#{$theme-colors-text}-hover", $value);
    @include border-color-variant("border-#{$theme-colors-text}", $value);
}


@include text-color-variant("text-base", $base-text-color);

@include text-color-hover-variant("text-base-hover", $base-text-color);
@include text-color-hover-variant("text-white-hover", $white);
@include text-color-hover-variant("text-primary-hover", $color-primary);
@include text-color-hover-variant("text-warning-hover", $color-warning);
@include text-color-hover-variant("text-danger-hover", $color-danger);
@include text-color-hover-variant("text-info-hover", $color-info);
@include text-color-hover-variant("text-alt-hover", $color-alt);


@include text-color-variant("text-alt-50", $alt-50);
@include text-color-variant("text-alt-100", $alt-100);
@include text-color-variant("text-alt-200", $alt-200);
@include text-color-variant("text-alt-300", $alt-300);
@include text-color-variant("text-alt-400", $alt-400);
@include text-color-variant("text-alt-500", $alt-500);
@include text-color-variant("text-alt-600", $alt-600);
@include text-color-variant("text-alt-700", $alt-700);
@include text-color-variant("text-alt-800", $alt-800);
@include text-color-variant("text-alt-900", $alt-900);
@include bg-color-variant("bg-alt-50", $alt-50);
@include bg-color-variant("bg-alt-100", $alt-100);
@include bg-color-variant("bg-alt-200", $alt-200);
@include bg-color-variant("bg-alt-300", $alt-300);
@include bg-color-variant("bg-alt-400", $alt-400);
@include bg-color-variant("bg-alt-500", $alt-500);
@include bg-color-variant("bg-alt-600", $alt-600);
@include bg-color-variant("bg-alt-700", $alt-700);
@include bg-color-variant("bg-alt-800", $alt-800);
@include bg-color-variant("bg-alt-900", $alt-900);
@include border-color-variant("border-alt-50", $alt-50);
@include border-color-variant("border-alt-100", $alt-100);
@include border-color-variant("border-alt-200", $alt-200);
@include border-color-variant("border-alt-300", $alt-300);
@include border-color-variant("border-alt-400", $alt-400);
@include border-color-variant("border-alt-500", $alt-500);
@include border-color-variant("border-alt-600", $alt-600);
@include border-color-variant("border-alt-700", $alt-700);
@include border-color-variant("border-alt-800", $alt-800);
@include border-color-variant("border-alt-900", $alt-900);

@include text-color-variant("text-gray-50", $gray-50);
@include text-color-variant("text-gray-100", $gray-100);
@include text-color-variant("text-gray-200", $gray-200);
@include text-color-variant("text-gray-300", $gray-300);
@include text-color-variant("text-gray-400", $gray-400);
@include text-color-variant("text-gray-500", $gray-500);
@include text-color-variant("text-gray-600", $gray-600);
@include text-color-variant("text-gray-700", $gray-700);
@include text-color-variant("text-gray-800", $gray-800);
@include text-color-variant("text-gray-900", $gray-900);
@include bg-color-variant("bg-gray-50", $gray-50);
@include bg-color-variant("bg-gray-100", $gray-100);
@include bg-color-variant("bg-gray-200", $gray-200);
@include bg-color-variant("bg-gray-300", $gray-300);
@include bg-color-variant("bg-gray-400", $gray-400);
@include bg-color-variant("bg-gray-500", $gray-500);
@include bg-color-variant("bg-gray-600", $gray-600);
@include bg-color-variant("bg-gray-700", $gray-700);
@include bg-color-variant("bg-gray-800", $gray-800);
@include bg-color-variant("bg-gray-900", $gray-900);
@include border-color-variant("border-gray-50", $gray-50);
@include border-color-variant("border-gray-100", $gray-100);
@include border-color-variant("border-gray-200", $gray-200);
@include border-color-variant("border-gray-300", $gray-300);
@include border-color-variant("border-gray-400", $gray-400);
@include border-color-variant("border-gray-500", $gray-500);
@include border-color-variant("border-gray-600", $gray-600);
@include border-color-variant("border-gray-700", $gray-700);
@include border-color-variant("border-gray-800", $gray-800);
@include border-color-variant("border-gray-900", $gray-900);

@include bg-variant("bg-default", $color-default);
@include bg-variant("bg-blue", $color-blue);
@include bg-variant("bg-yellow", $color-yellow);
@include bg-variant("bg-red", $color-red);
@include bg-variant("bg-purple", $color-purple);
@include bg-variant("bg-green", $color-green);
@include bg-variant("bg-orange", $color-orange);
@include bg-variant("bg-teal", $color-teal);
@include bg-variant("bg-brown", $color-brown);
@include bg-variant("bg-pink", $color-pink);
@include bg-variant("bg-black", $color-black);

@include text-color-variant("text-default", $color-default);
@include text-color-variant("text-blue", $color-blue);
@include text-color-variant("text-yellow", $color-yellow);
@include text-color-variant("text-red", $color-red);
@include text-color-variant("text-purple", $color-purple);
@include text-color-variant("text-green", $color-green);
@include text-color-variant("text-orange", $color-orange);
@include text-color-variant("text-teal", $color-teal);
@include text-color-variant("text-brown", $color-brown);
@include text-color-variant("text-pink", $color-pink);
@include text-color-variant("text-black", $color-black);

@include bg-color-variant("bg-page", $page-bg);
@include bg-color-variant("bg-transparent", transparent);

@include badge-variant("primary", $color-primary);
@include badge-variant("secondary", $gray-600);
@include badge-variant("success", $color-success);
@include badge-variant("info", $color-info);
@include badge-variant("warning", $color-warning);
@include badge-variant("danger", $color-danger);
@include badge-variant("alt", $color-alt);
@include badge-variant("darker", $gray-800);

@include badge-variant("primary-dark", $primary-700);
@include badge-variant("secondary-dark", $gray-700);
@include badge-variant("success-dark", $success-700);
@include badge-variant("info-dark", $info-700);
@include badge-variant("warning-dark", $warning-700);
@include badge-variant("danger-dark", $danger-700);
@include badge-variant("alt-dark", $alt-700);
@include badge-variant("darkest", #000);

@include badge-variant("primary-darker", $primary-900);
@include badge-variant("secondary-darker", $gray-900);
@include badge-variant("success-darker", $success-900);
@include badge-variant("info-darker", $info-900);
@include badge-variant("warning-darker", $warning-900);
@include badge-variant("danger-darker", $danger-900);
@include badge-variant("alt-darker", $alt-900);

@include badge-variant("primary-light", $primary-300);
@include badge-variant("secondary-light", $gray-300);
@include badge-variant("success-light", $success-300);
@include badge-variant("info-light", $info-300);
@include badge-variant("warning-light", $warning-300);
@include badge-variant("danger-light", $danger-300);
@include badge-variant("alt-light", $alt-300);
@include badge-variant("dark", $gray-600);

@include badge-variant("primary-lighter", $primary-100);
@include badge-variant("secondary-lighter", $gray-100);
@include badge-variant("success-lighter", $success-100);
@include badge-variant("info-lighter", $info-100);
@include badge-variant("warning-lighter", $warning-100);
@include badge-variant("danger-lighter", $danger-100);
@include badge-variant("alt-lighter", $alt-100);

@include badge-solid-variant("primary", $color-primary);
@include badge-solid-variant("secondary", $gray-600);
@include badge-solid-variant("success", $color-success);
@include badge-solid-variant("info", $color-info);
@include badge-solid-variant("warning", $color-warning);
@include badge-solid-variant("danger", $color-danger);
@include badge-solid-variant("alt", $color-alt);
@include badge-solid-variant("darker", $gray-800);

@include badge-solid-variant("primary-dark", $primary-700);
@include badge-solid-variant("secondary-dark", $gray-700);
@include badge-solid-variant("success-dark", $success-700);
@include badge-solid-variant("info-dark", $info-700);
@include badge-solid-variant("warning-dark", $warning-700);
@include badge-solid-variant("danger-dark", $danger-700);
@include badge-solid-variant("alt-dark", $alt-700);
@include badge-solid-variant("darkest", #000);

@include badge-solid-variant("primary-light", $primary-300);
@include badge-solid-variant("secondary-light", $gray-300);
@include badge-solid-variant("success-light", $success-300);
@include badge-solid-variant("info-light", $info-300);
@include badge-solid-variant("warning-light", $warning-300);
@include badge-solid-variant("danger-light", $danger-300);
@include badge-solid-variant("alt-light", $alt-300);
@include badge-solid-variant("dark", $gray-600);