@import '../../../styles/_modules/variables';
@import '../../../styles/_mixins/mixins';

.link-orders {
    &>.body-content {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;
        align-items: stretch;

        &>.orders {
            flex: 1 1 auto;
            overflow-y: auto;
            padding: $p-4;
            padding-bottom: 0;
            @include thin-scroll();
        }

        &>.reason {
            padding: $p-4;
            padding-top: 0;
            margin-top: $p-2 !important;
        }
    }
}