/* bootstrap override table stripe */
.table {
	&.table-striped:not(.table-bordered) {
		th, 
		tbody th,
		tbody td {
			 border: 0;
		}
	}

	thead[class^="bg-"] tr > th,
	thead[class*=" bg-"] tr > th {
		border-top: 0;
		border-bottom: 0; 	
	}

	tr[class^="bg-"] > td,
	tr[class^="bg-"] > th,
	tr[class*=" bg-"] > td,
	tr[class*=" bg-"] > th {
		border-top:0 !important;
	}
}

.thead-themed {
	@include gradient-img(#f2f2f2,#fafafa);
}

.table-dark {
	.thead-themed {
		@include gradient-img($fusion-700,$fusion-800);
	}
}

.table-bordered {
	&[class*=" bg-"],
	&[class*=" bg-"] td,
	&[class*=" bg-"] th,
	&[class^="bg-"],
	&[class^="bg-"] td,
	&[class^="bg-"] th {
		border: 1px solid rgba($white, 0.1);
	}

	[class*=" bg-"] td,
	[class*=" bg-"] th,
	[class^="bg-"] td,
	[class^="bg-"] th, {
		border: 1px solid rgba($black, 0.1);
	}
}
/* table hover */
.table-hover {
	tbody {
		tr {
			&:hover {
				box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
				z-index: 1;
			}
		}
	}
}

/* reset table (global) */
th {
    font-weight: 500;
}

.table-clean {
	background: transparent;
	border: none;

	tr,
	td,
	th {
		border: none;
		background: none;
	}
}

.table-scale-border-top {
	border-top: 2px solid $fusion-500 !important;
}
.table-scale-border-bottom {
	border-bottom: 2px solid $fusion-500 !important;
}
