@import '../../styles/_modules/variables';

.lockscreen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    z-index: 1000;

    & .live-clock {
        position: fixed;
        display: inline-block;
        bottom: 0;
        right: 0;
        padding: $p-5 * 2;
        line-height: 1;
        color: $white;
        text-align: left;
        text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4), 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1);
        & .date,
        & .time,
        & .midday,
        & .time-midday {
            display: block;
            font-family: 'Roboto Condensed';
            text-align: right;
        }
        & .date {
            font-size: 2rem;
            font-weight: normal;
        }
        & .time {
            display: inline-block;
            font-size: 6rem;
            font-weight: 500;
            margin-bottom: $p-2;
        }
        & .midday {
            display: inline-block;
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: $p-2;
        }
    }
}