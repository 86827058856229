.pagination {
	& .page-item {
		&:not(:first-child) {
			margin-left: 0.4rem;
		}
		&.active {
			& .page-link {
				background: $alt-500;
				border-color: $alt-500;
			}
		}
		&:first-child:not(.active),
		&:last-child:not(.active),
		&.disabled {
			& .page-link {
				background: lighten($alt-50, 25%);
				color: $white;
				transition: background-color linear .15s;
			}
		}
	}

	& .page-link {
		border-radius: $border-radius;
		border-width: 0px;
		transition: background-color linear .15s;

		&:hover {
			background-color: $alt-500 !important;
			color: $white;
			transition: background-color linear .15s;
		}
	}

	&.pagination-xs {
		& .page-link {
			padding: 0.2rem 0.5rem;
			font-size: rem($fs-xs)
		}
	}
}