@import '../../styles/_modules/variables.scss';

.border-picker {
    display: flex;
    flex-direction: column;
    margin-top: -3px;

    & .bp-top,
    & .bp-bottom {
        width: 100%;
        height: 5px;
        background-color: transparent;
        cursor: pointer;
    }

    & .bp-container {
        flex: 1;
        display: flex;
        flex-direction: row;

        & .bp-left,
        & .bp-right {
            height: 100%;
            width: 5px;
            background-color: transparent;
            cursor: pointer;
        }

        & .bp-middle {
            flex: 1;
            background-color: $gray-200;
            border: 10px solid #fff;
        }
    }
}