@import '../../../styles/_modules/variables';
@import '../../../styles/_mixins/mixins';
@import '../../_shared/QuickDrawer';

.view-note-drawer {
    & .note-filters-wrapper {
        width: $quick-drawer-width;
        border-bottom: 1px solid $table-border-color;
        border-right: 1px solid $table-border-color;
        box-shadow: none;
    }

    & .view-note {
        opacity: 0;
        overflow: hidden;
        transition: opacity linear .15s;

        &.rendered {
            opacity: 1;
            overflow: auto;
            transition: opacity linear .15s;
        }
        
        & .note-overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($white, 0.75);
            z-index: 1002;
        }

        & .d-flex {
            &.d-none {
                display: none !important;
            }
        }

        & .profile-image {
            position: relative;

            & .badge {
                position: absolute;
                width: $p-2;
                height: $p-2;
                min-width: $p-2;
                min-height: $p-2;
                line-height: 1;
                right: 0;
                bottom: $p-o * -1;
            }
        }

        & .badges {
            position: absolute;
            top: -2px;
        }

        & hr {
            border-bottom-style: solid;

            &.new {
                position: relative;
                border-bottom: 1px solid $info-50;

                &:after {
                    position: absolute;
                    content: 'Recent';
                    font-size: $font-size-sm;
                    width: $p-2 * 8;
                    top: calc($font-size-sm / -1.5);
                    left: 50%;
                    margin-left: calc(($p-2 * 8) / -2);
                    background: $white;
                    text-align: center;
                    color: $info-200;
                }
            }
        }

        & .deleted {
            & .profile-image {
                filter: grayscale(100%);
                opacity: .5;
                transition: filter linear .2s, opacity linear .2s;
            }

            & .note-by {
                color: $gray-500;
            }

            & .html {
                color: $gray-500;
                text-decoration: line-through;
            }
        }
    }

    & .quick-drawer-action {
        padding: 0 !important;

        & .quill,
        & .dx-htmleditor {
            border: none;
            border-radius: 0;

            & .ql-toolbar {
                border-top: none;
                border-left: none;
                border-right: none;
            }

            & .ql-container {
                border: none !important;
                padding: $p-h $p-1;
            }
        }

        & .actions {
            padding: $p-3 ($p-3 * 2);
        }
    }
}