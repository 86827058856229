@import '../../styles/_modules/variables.scss';

.file-viewer {
    &.panel {
        background: transparent;

        & .panel-hdr {
            display: flex;
            flex-direction: row;
            color: $white;
            background-color: $alt-800;

            & .title {
                line-height: 1;
                margin: 0;
            }

            & .count {
                flex: 1;
                text-align: center;
            }

            & .btn {
                line-height: 1;

                & i {
                    color: $white;
                    transition: color linear .15s;
                }

                &:hover {
                    & i {
                        color: $gray-500;
                        transition: color linear .15s;
                    }
                }
            }
        }

        & .panel-container {
            background-color: rgba($alt-800, .9);

            & .panel-content {
                height: 100%;
                overflow: hidden;
                padding: 0;

                & .slick-slider {
                    &.slick-initialized {
                        height: 100%;

                        & .slick-list {
                            height: 100%;

                            & .slick-track {
                                height: 100%;

                                & .slick-slide {
                                    height: 100%;

                                    &>div {
                                        height: 100%;

                                        & .file-loading {
                                            & .spinner-border {
                                                border-top-color: $white;
                                                border-left-color: $white;
                                                border-bottom-color: $white;
                                                width: 5rem;
                                                height: 5rem;
                                            }
                                        }

                                        &>.file-image-wrapper {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                            padding: $p-3;

                                            & .file-image-actions {
                                                position: absolute;
                                                bottom: $p-4;
                                                right: $p-4;
                                                z-index: 100;

                                                & .btn-icon {
                                                    color: $white !important;
                                                }
                                            }

                                            & .react-transform-component {
                                                width: 100%;
                                                height: 100%;

                                                & .react-transform-element {
                                                    width: 100%;
                                                    height: 100%;
                                                    justify-content: center;

                                                    & img {
                                                        max-width: 100%;
                                                        max-height: 100%;
                                                        object-fit: contain;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}