@import '../../../styles/_modules/variables';

.new-product {

    & .type,
    & .brand {
        & .rw-dropdown-list {
            & .rw-list-option {
                & small {
                    color: $gray-600;
                }

                &.rw-state-selected {
                    & small {
                        color: $white;
                    }
                }
            }
        }
    }

    & .services {
        & .rw-widget {
            &.rw-multiselect {
                & .rw-widget-container {
                    &.rw-widget-input {
                        & div {
                            & .rw-multiselect-taglist {
                                & .rw-multiselect-tag {
                                    position: relative;
                                    padding: 0;
                                    border: none;

                                    &>* {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        padding: 6px;
                                        color: $white;
                                        height: 40px;
                                    }

                                    &>.tag {
                                        padding-right: 25px;
                                        border: 1px solid transparent;
                                    }

                                    &>div {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        z-index: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .serial-numbers {
        & .serial-number-input {
            position: relative;

            &:first-child {
                margin-top: 0;
            }

            & .form-control {                
                width: 280px;
            }

            & .button-group {
                position: absolute;
                top: 0;
                right: 0;
                border-color: transparent;
                background-color: transparent;
                padding: $p-2 $p-1;
                outline: none;
                box-shadow: none;

                & .btn {
                    &>i {
                        color: $gray-700;
                    }
                }

                &:hover,
                &:active {
                    &>i {
                        color: $gray-800;
                    }
                }
            }
        }
    }
}