@mixin custom-checkbox-variant ($name, $bg) {
    &.custom-checkbox-#{$name} {
        & .custom-control-input {
            &:not(:disabled):active {
                &~.custom-control-label {
                    &::before {
                        color: lighten($bg, 25%);
                        border-color: lighten($bg, 25%);
                        background-color: lighten($bg, 25%);
                    }
                }
            }
            &:focus:not(:checked) {
                &~.custom-control-label {
                    &::before {
                        border-color: lighten($bg, 25%);
                    }
                }
            }
            &:checked {
                &~.custom-control-label {
                    &::before {
                        border-color: $bg;
                        background-color: $bg;
                    }
                }
            }
        }
    }
}

.custom-checkbox {
    @include custom-checkbox-variant('customer', darken($color-customer, 25%));
    @include custom-checkbox-variant('appointment', darken($color-appointment, 25%));
    @include custom-checkbox-variant('exam', darken($color-exam, 25%));
    @include custom-checkbox-variant('purchase', darken($color-purchase, 25%));
    @include custom-checkbox-variant('order', darken($color-work-order, 25%));
    @include custom-checkbox-variant('product', darken($color-product, 25%));
    @include custom-checkbox-variant('report', darken($color-report, 25%));
    @include custom-checkbox-variant('note', darken($color-note, 25%));

    @include custom-checkbox-variant('primary', $color-primary);
    @include custom-checkbox-variant('success', $color-success);
    @include custom-checkbox-variant('info', $color-info);
    @include custom-checkbox-variant('warning', $color-warning);
    @include custom-checkbox-variant('danger', $color-danger);
    @include custom-checkbox-variant('alt', $color-alt);
}