@import '../../../styles/_modules/variables';

.add-external-doctor-container {
    & .clinic-container {
        display: flex;
        border: 1px solid $border-color;
        border-radius: $border-radius;

        & .clinic-detail {
            width: 300px;
            padding: $p-3;
        }

        & .clinic-action {
            width: 50px;
            padding: $p-2 $p-3;
            text-align: right;
        }
    }

    & .external-clinic-search {
        & .dropdown {
            &>.dropdown-menu {
                width: 100%;
                margin-top: -3px;
                padding: 0;

                & .dropdown-menu-item {
                    transition: background-color linear .15s;
                    padding: 0 !important;

                    &.active {
                        background-color: rgba($color-primary, .1) !important;
                        transition: background-color linear .15s;
                    }

                    & .external-clinic-profile {
                        padding: $p-2;
                        cursor: pointer;
                        transition: background-color linear .15s;

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: rgba($gray-300, .5) !important;
                            transition: background-color linear .15s;
                        }
                    }
                }
            }
        }
    }
}