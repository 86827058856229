@import '../../styles/_modules/variables.scss';

.disabled-overlay {
    display: flex;
    flex: 1 0;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($white, 0);
    z-index: 1002;
}