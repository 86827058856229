@import '../../styles/_modules/variables.scss';
@import '../../styles/_mixins/mixins';

.exam-diagnostic-codes-wrapper {
    border: 1px solid $input-border-color;
    height: 100%;

    & .rw-multiselect {
        &.rw-widget {

            & .rw-widget-container {
                border: none;
                background-color: transparent;

                & .rw-multiselect-taglist {
                    & .tag,
                    & .rw-multiselect-tag-btn {
                        color: $gray-700 !important;
                    }

                    & .tag {
                        background-color: $gray-300 !important;
                        border: 1px solid $gray-400 !important;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.control-sm {
        & .rw-multiselect {
            &.rw-widget {
    
                & .rw-widget-container {
                    padding: $p-h;
    
                    & .rw-multiselect-taglist {
                        & .tag,
                        & .rw-multiselect-tag-btn {
                            font-size: $font-size-sm;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}