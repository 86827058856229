@import '../../styles/_modules/variables.scss';
@import '../../styles/_mixins/mixins';

.prescription-summary {
    & .dx-accordion {
        & .dx-accordion-wrapper {
            & .dx-accordion-item {
                border: none;
                padding-bottom: $p-2;

                & .dx-accordion-item-title,
                & .dx-accordion-item-body {
                    padding: 0;
                }

                & .dx-accordion-item-title {
                    font-size: $fs-base;
                    color: $base-text-color;
                    margin-bottom: $p-2;
                }

                & .dx-accordion-item-body {
                    & .dx-datagrid {
                        border: 1px solid $table-border-color;
                        border-bottom: none;

                        & .dx-datagrid-table {
                            & td {
                                padding: $p-2;
                                color: $gray-700;
                            }

                            & .dx-header-row {
                                & td {
                                    background-color: $gray-50;
                                    color: $gray-600;
                                    font-weight: 500;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }

                            & .dx-freespace-row {
                                display: none;
                            }
                        }
                    }

                    & .can-dblclick {
                        & .dx-datagrid {
                            & .dx-data-row {
                                & td {
                                    user-select: none;

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.dx-accordion-title-hidden {
            & .dx-accordion-wrapper {
                & .dx-accordion-item {    
                    & .dx-accordion-item-title {
                        display: none;
                    }
                }
            }
        }
    }
}