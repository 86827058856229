@import '~react-contexify/dist/ReactContexify.min.css';

.react-contexify {
    z-index: 500;
    font-size: $font-size-sm;
    padding: $p-1 0;
    box-shadow: 0 $p-h $p-1 rgba($gray-700, .15), 0 0 0 $p-o $gray-300;
    margin-left: $p-o;
    & .react-contexify__item {
        &.title {
            & .react-contexify__item__content {
                display: block;
                max-width: 250px;
                cursor: default !important;
                color: $gray-700 !important;
                background-color: transparent !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:not(.react-contexify__item--disabled) {
            &:hover {
                &>.react-contexify__item__content {
                    color: $white;
                    background-color: $color-primary;
    
                    & a {
                        color: $white;
    
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }

        & .react-contexify__item__content {
            position: relative;
            color: $gray-700;
            padding: $p-2;
            & .chip {
                position: absolute;
                line-height: 1;
                border-radius: 2px;
                border: 1px solid transparent;
                background-color: $color-primary;
                color: $white;
                margin: 0;
                font-size: 9px;
                padding: $p-h $p-1 $p-o $p-1;
                top: .25rem;
                right: $p-1;
            }

            & .badge {
                position: absolute;
                right: $p-2;
                top: 50%;
            }

            & a {
                display: block;
                width: 100%;
                color: $gray-700;
                text-decoration: none;

                &:hover,
                &:active,
                &:visited,
                &:focus {
                    color: $gray-700;
                    text-decoration: none;
                }
            }

            & .react-contexify__submenu-arrow {
                top: 5px;
            }
        }

        &:hover {
            & > .react-contexify__item__content {
                color: $gray-700;
                background-color: $gray-300;
            }
        }
    }
}