/* List table */
.list-table {
	height: auto;
	display:table;
	margin: 0;
	padding:0;
	> li {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		padding:0;

		&.search {
			position: static;
		}

	}
}

/* mostly used for nav items */
.disabled:not(.btn),
.disabled:not(.btn) > * {	
	filter: grayscale(80%);
	opacity: 0.80;
	cursor: not-allowed;

	ul,
	.collapse-sign {
		display: none;
	}
}

ul.list-verticle {
	margin:0;
	padding:0;
	list-style: none; 
}

.show {
	& > .dropdown-menu-animated {
		@include transform( scale(1) !important);
		@include transform-origin( 29px -50px );
		opacity: 1;
		visibility: visible;
	}
}

hr {
	border: none;
	border-bottom: 1px solid #eee;

	&.hr-xl {
		margin: 3rem 0;
	}
}


.bg-trans-gradient {
	background: -webkit-linear-gradient(250deg, desaturate($info-500, 25%), desaturate($primary-500, 10%));
	background: linear-gradient(250deg, desaturate($info-500, 25%), desaturate($primary-500, 10%));
}

.bg-brand-gradient {
	@extend %nav-bg;
}

/* custom scroll */
.custom-scroll,
.custom-scrollbar {
	@extend %custom-scroll;
}

/* table no border */
.table-border-0 {
	th,
	td {
		border:0 !important;
	}
}

/* table calendar */
.table-calendar {

	table-layout:fixed;

	th {
		border: 0px !important;
		font-weight: 500;
	}

	tr {
		td:first-child {
			border-left:0;
		}
		td:last-child {
			border-right:0;
			padding-right:10px;
		}
	}

	td, th {
		text-align: right;
		vertical-align: top;
		padding: 5px 8px;
		position: relative;
	}
}

/* list spaced */
.list-spaced {
	li {
		margin-top: 7px;
		margin-bottom: 7px;

		&:first-child {
			margin-top:0;
		}
		&:last-child {
			margin-bottom:0;
		}
	}
}
.list-spaced {
	> li {
		padding: 0 0 $spacer * 0.2;
	}
}

/* profile images */
.profile-image {
	width: $profile-image-width;
	height: $profile-image-width;
}

.profile-image-xxl {
	width: $profile-image-width-lg * 2;
	height: $profile-image-width-lg * 2;
}

.profile-image-xl {
	width: $profile-image-width-lg * 1.5;
	height: $profile-image-width-lg * 1.5;
}

.profile-image-lg {
	width: $profile-image-width-lg;
	height: $profile-image-width-lg;
}

.profile-image-md {
	width: $profile-image-width-md;
	height: $profile-image-width-md;
}

.profile-image-sm {
	width: $profile-image-width-sm;
	height: $profile-image-width-sm;
}

/* image share */
.img-share {
	width: auto;
	height: $image-share-height;
}
span.img-share {
	width: $image-share-height + ($image-share-height * 0.4670);
	height: $image-share-height;
}

.notes {
	padding: 5px;
	background: #f9f4b5;
}


/*
.shadow-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.shadow-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.shadow-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.shadow-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}*/

/* disclaimer class */
.disclaimer {
	padding-left: 10px;
	font-size: rem($fs-base - 5);
	color: #a2a2a2;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-style: italic;
}

/* horizontal scrolling */
.scrolling-wrapper {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;

	-webkit-overflow-scrolling: touch;

  .card {
    flex: 0 0 auto;
  }
	&::-webkit-scrollbar {
		display: none;
	}
}


/* online status */
.status {
	position: relative;

	&:before {
		content: " ";
		position: absolute;
		width: 15px;
		height: 15px;
		display: block;
		top: -2px;
		right: -2px;
		background: $fusion-500;
		border-radius: 50%;
		border: 2px solid #fff;
	}

	&.status-sm:before {
		width: 10px;
		height: 10px;	
		border-width: 1px;
		top:0;
		right:0;
	}

	&.status-success:before {
		background: $success-500;
	}

	&.status-danger:before {
		background: $danger-500;
	}

	&.status-warning:before {
		background: $warning-500;
	}
}

/* containers */
.container {

	&.container-sm {
		max-width: map-get($grid-breakpoints, sm)
	}

	&.container-md {
		max-width: map-get($grid-breakpoints, md)
	}

	&.container-lg {
		max-width: map-get($grid-breakpoints, lg)
	}

}

/* responsive visibility */
/* https://getbootstrap.com/docs/3.4/css/#responsive-utilities */
@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
	@include media-breakpoint-up($bp) {
	  display: none !important;
	}
  }
  .hidden-#{$bp}-down {
	@include media-breakpoint-down($bp) {
	  display: none !important;
	}
  }
}

/* display frame */
.frame-heading {
	font-size: rem($fs-base);
	margin-bottom: 1rem;
	color: lighten($fusion-50, 7%);
	font-weight: 500;
	
	small {
		font-size: rem($fs-base);
		margin-bottom: 0.5rem;
	}
}
.frame-wrap {
	background: white;
	padding: 0;
	margin-bottom: 3rem;
}
* > .frame-wrap:last-child {
	margin-bottom:0 !important;
}

/* time stamp */
.time-stamp {
	font-size: rem($fs-sm);
	margin: $p-1 0 0 0;
	color: $fusion-200;
	font-weight: 300;
}

/* data-hasmore */
[data-hasmore] {
	position: relative;
	color: $white;
	&:before {
		content: attr(data-hasmore);
		border-radius: inherit;
		background: rgba($black, 0.4);
		height: inherit;
		width: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: inherit;
	}
}

/* code */
code {
	background: lighten(desaturate($primary-800, 60%), 56%);
	padding: 4px 7px;
	border-radius: 4px;
}


/* star checkbox */
.star {
    visibility:hidden;
    font-size:1.5em;
    cursor:pointer;
}
.star:before {
   content: "☆";
   position: absolute;
   visibility:visible;
}
.star:checked:before {
   content: "★";
   position: absolute;
}