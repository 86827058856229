@import '../../styles/_modules/variables.scss';
@import '../../styles/_mixins/mixins.scss';

.dx-maintenance-popup {
    & .dx-overlay-content {
        background-color: $white;
        box-shadow: 0px 0px 13px 0px rgba(darken($primary-800, 15%), calc(8/100));
        border-radius: $border-radius;
        border: 1px solid rgba(0, 0, 0, 0.09);
        border-bottom: 1px solid #e0e0e0;
        border-radius: $panel-edge-radius;

        & .dx-popup-title {
            height: 60px;
            cursor: move;
            padding: 0;
            border-bottom: none;
        }

        & .dx-popup-content {
            padding: 0;

            & .popup-title {
                border-bottom: 1px solid $gray-400;

                &>.left-actions,
                &>.right-actions {
                    display: flex;
                    align-items: center;
                    height: 60px;
                    margin-top: -60px;

                    & .btn {
                        position: relative;
                    }
                }

                &>.left-actions {
                    float: left;
                    padding-top: $p-1;
                    padding-left: $p-3;
                }

                &>.right-actions {
                    float: right;
                }

                &>.actions {
                    &>ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        &>li {
                            display: inline-block;
                            margin: 0;
                            margin-right: $p-2;
                            padding: 0;

                            & .dropdown-menu {
                                z-index: 5001;
                            }
                        }
                    }
                }
            }

            & .popup-body {
            }

            & .popup-footer {
            }
        }
    }
}